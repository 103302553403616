import { Component, OnInit } from '@angular/core';
import { UserAccessPermissions } from 'src/app/Models/AccessPermissions';
import { StorageService } from 'src/app/Services/storage-service.service';

@Component({
  selector: 'app-not-enough-rights',
  templateUrl: './not-enough-rights.component.html',
  styleUrls: ['./not-enough-rights.component.scss']
})
export class NotEnoughRightsComponent implements OnInit {

  userAccessPermission:UserAccessPermissions

  constructor(
    public _storageService: StorageService,
    ) { }

  ngOnInit(): void {
    this.userAccessPermission=new UserAccessPermissions();
    this.userAccessPermission=this._storageService.GetLoginUserPermissions()


  }

}
