
export class AccessPermission{
  accessPermissionID:number
  accessPermissionName:string
}

export class UserAccessPermissions{

  //! Item
  createItem:boolean
  readItem:boolean
  updateItem:boolean
  delteItem:boolean
  cloneItem:boolean
  checkInItem:boolean
  checkOutItem:boolean
  itemImport:boolean

  //! Report
  activityReportRead:boolean
  activeCheckoutReportRead:boolean

  //! Maintenance
  createLocation:boolean
  readLocation:boolean
  updateLocation:boolean
  deleteLocation:boolean
   // Categories
  crateCategorySubCategory:boolean
  updateCategorySubCategory:boolean
  deleteCategorySubCategory:boolean
  readCategorySubCategory:boolean
   // Summery email
  createSummeryEmail:boolean
  readSummeryEmail:boolean
  updateSummeryEmail:boolean
  deleteSummeryEmail:boolean
   // Access Group
  createAccessGroup:boolean
  readAccessGroup:boolean
  updateAccessGroup:boolean
  deleteAccessGroup:boolean

//! User
  createUser:boolean
  updateUser:boolean
  readUser:boolean




  constructor()
  {
    //Item
    this.createItem=false;
    this.readItem=false;
    this.updateItem=false;
    this.delteItem=false;
    this.cloneItem=false;
    this.checkInItem=false;
    this.checkOutItem=false;
    this.itemImport=false;
    // Report
    this.activityReportRead=false;
    this.activeCheckoutReportRead=false;
    // Maintenance
     // Cateogry
    this.crateCategorySubCategory=false
    this.updateCategorySubCategory=false
    this.deleteCategorySubCategory=false
    this.readCategorySubCategory=false
  // summery Email
    this.createSummeryEmail=false
    this.readSummeryEmail=false
    this.updateSummeryEmail=false
    this.deleteSummeryEmail=false
   // location
    this.createLocation=false
    this.readLocation=false
    this.updateLocation=false
    this.deleteLocation=false
   // AccessGroup
    this.createAccessGroup=false
    this.readAccessGroup=false
    this.updateAccessGroup=false
    this.deleteAccessGroup=false

    //User
    this.createUser=false
    this.updateUser=false
    this.readUser=false
  }
}
