<mat-progress-bar [ngStyle]="{'visibility':isLoading==true ? 'visible' : 'hidden'}" color="primary" mode="indeterminate"></mat-progress-bar>

<div class="col-lg-12 components-block">
  <div class="components-header">
    <h2>Users List</h2>
  </div>
  <div class="filterbar-block" style="margin-left: 10px;">
    <mat-form-field appearance="standard">
      <mat-label>Search</mat-label>
      <input matInput #searchValue (keyup)="applyFilters()" [(ngModel)]="filterValue" placeholder="Name, Email" #input>
      <div class="filtericon">
        <mat-icon aria-hidden="false" aria-label="Example home icon">search</mat-icon>
      </div>
    </mat-form-field>
    <div class="grid-filter-dropdown">
      <mat-form-field appearance="standard">
        <mat-label>Status</mat-label>
        <mat-select name="status" [(ngModel)]='userType' (selectionChange)="applyFilters()">
          <mat-option value="All">
            All
          </mat-option>
          <mat-option value="Active">
            Active
          </mat-option>
          <mat-option value="InActive">
            Inactive
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="grid-filter-dropdown">
      <mat-form-field appearance="standard">
        <mat-label>Role</mat-label>
        <mat-select name="userRole" [(ngModel)]='userRole' (selectionChange)="applyFilters()">
          <mat-option value="All">
            All
          </mat-option>
          <mat-option *ngFor="let role of allRoles" value="{{role.name}}">
            {{role.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="pulladdata-block">
      <button type="submit" (click)="pullAdData()" [class.spinner]="AdDataLoading" [disabled]="AdDataLoading"
      mat-stroked-button color="primary" class="pulldata-btn">Sync Data</button>
    </div>
  </div>

  <div class="mat-table-block">

    <table mat-table matSort [dataSource]="userData">

      <ng-container matColumnDef="First Name">
        <th width="10%" mat-header-cell *matHeaderCellDef>First Name</th>
        <td  mat-cell *matCellDef="let user">
          <a disabled="true" routerLink="/home/user/add/{{user.id}}" class="column-name-link"
          [class.disabled]="userAccessPermission.updateUser ? null : true">
            {{user.firstName}}
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="Last Name">
        <th width="10%" mat-header-cell *matHeaderCellDef> Last Name </th>
        <td mat-cell *matCellDef="let user">
          {{user.lastName}}
        </td>
      </ng-container>

      <ng-container matColumnDef="Email">
        <th width="20%" mat-header-cell *matHeaderCellDef> Email </th>
        <td mat-cell *matCellDef="let user"> {{user.email}} </td>
      </ng-container>

      <ng-container matColumnDef="EmployeeId">
        <th width="10%" mat-header-cell *matHeaderCellDef> Employee Id </th>
        <td mat-cell *matCellDef="let item"> {{item.employeeId}} </td>
      </ng-container>

      <ng-container matColumnDef="Phone">
        <th width="10%" mat-header-cell *matHeaderCellDef> Phone </th>
        <td mat-cell *matCellDef="let item"> {{item.phoneNumber}} </td>
      </ng-container>

      <ng-container matColumnDef="Address">
        <th width="15%" mat-header-cell *matHeaderCellDef> Address </th>
        <td mat-cell *matCellDef="let item"> {{item.address}} </td>
      </ng-container>

      <ng-container matColumnDef="Address2">
        <th width="15%" mat-header-cell *matHeaderCellDef> Address 2</th>
        <td mat-cell *matCellDef="let item"> {{item.address2}} </td>
      </ng-container>

      <ng-container  matColumnDef="Allow IMS">
        <th [hidden]="!userAccessPermission.updateUser"  width="15%" mat-header-cell *matHeaderCellDef>Allow</th>
        <td [hidden]="!userAccessPermission.updateUser"   mat-cell *matCellDef="let user">
            <mat-slide-toggle color="green" mat-raised-button  [(ngModel)]="user.allowIMS" (change)="Changetoggle($event,user)" [disabled]='notAllowIMS '
             [matTooltip]="!toogleToolTip ? 'Only SuperAdmin can change the access' : ''"
            >
          </mat-slide-toggle>

          <!-- <mat-slide-toggle color="green" mat-raised-button *ngIf="user.isEmployee" [(ngModel)]="user.allowIMS"  [disabled]='true'
             matTooltip="Employees are not allowed access to AMS"
            >
          </mat-slide-toggle>-->  
          <span class="mat-toggle-text">{{ user.allowIMS ? 'On' : 'Off' }}</span>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div class="record-count" *ngIf="!recordCount && searchStart">No Record Found</div>

    <mat-paginator *ngIf="recordCount" [pageSizeOptions]="[25, 50, 100]" #paginator [pageIndex]="currentPage" [pageSize]="pageSize"
      (page)="pageChanged($event)" showFirstLastButtons aria-label="Select page of items">
    </mat-paginator>
  </div>
</div>
