import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AccessPermission, UserAccessPermissions } from 'src/app/Models/AccessPermissions';
import { AccessGroupModel } from 'src/app/Models/ComponentModels/AccessGroupModel';
import { CategoryModel } from 'src/app/Models/ComponentModels/Category';
import { AccessGroupService } from 'src/app/Services/component-services/access-group-service';
import { StorageService } from 'src/app/Services/storage-service.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss']
})
export class AccessGroupListingComponent implements OnInit {
  isLoading = false;
  pageSize = 25;
  currentPage = 1;
  displayedColumns: string[] = ['name','categories','permissions','Actions'];
  gridData:MatTableDataSource<AccessGroupModel> = new MatTableDataSource();


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  userAccessPermission:UserAccessPermissions

  constructor(
    private _accessGroupService:AccessGroupService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    public _storageService: StorageService,

    ) {

      this.userAccessPermission=new UserAccessPermissions();

    }

  ngOnInit(): void {
    this.populateReportDataGrid();
    this.GetUserAccessPermission()
  }
  populateReportDataGrid(){
    this.GetAllAccessGroupPagination()

    // this.isLoading = true;
    // this._accessGroupService.getAllAccessGroups().subscribe((x) => {
    // this.gridData = new MatTableDataSource<AccessGroupModel>(x.data);
    //   setTimeout(() => {
    //     this.paginator.pageIndex = (this.currentPage - 1); // index starts with zero
    //     this.paginator.length = x.data.length;
    //   });

    //   this.gridData.paginator = this.paginator;
    //   this.gridData.sort = this.sort;
    //   this.isLoading = false;
    // },
    //   (response) => {
    //     this._snackBar.open(response.error.Message || "Could not load data. Try again.","Ok");
    //     this.isLoading = false;
    //   });
  }
  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex + 1;
    this.populateReportDataGrid();



  }
  SortChange(sortState: Sort){
    // this.sortColumn = sortState.active;
    // this.sortDirection = sortState.direction;

    // this.populateReportDataGrid();
  }
  confirmDialog(accessGroupObj: AccessGroupModel): void {
    const title = `Delete Access Group`;
    const message = `Are you sure you want to delete this Access Group?`;

     const dialogData = new ConfirmDialogModel(title, message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "550px",
      data: dialogData,
      panelClass: 'custom-dialog'
    });

    dialogRef.afterClosed().subscribe(dialogResult => {

      if (dialogResult) { // user clicked yes
        this._accessGroupService.deleteAccessGroup(accessGroupObj.id).subscribe((x) => {
          this._snackBar.open('Deleted successfully.', 'Ok');
          this.populateReportDataGrid();
        },(errorResponse)=>{
          this._snackBar.open('Some error occured. Please try again later.', 'Ok');
        });
      }
     });
  }

  formatCommaSeparatedNameList(data:CategoryModel[]){
    var returnstring = "";
    data.forEach(function(item){
      returnstring+=item.name + ", "
    });

    return returnstring
  }


  formatCommaSeparatedPermissionsList(permissionName:AccessPermission[]){
    var returnstring = "";
    permissionName.forEach(function(item){
      returnstring+=item.accessPermissionName + ", "
    });

    return returnstring
  }
  GetUserAccessPermission()
     {
      this.userAccessPermission=this._storageService.GetLoginUserPermissions()

     }

GetAllAccessGroupPagination()
{
  this.isLoading = true;
    this._accessGroupService.getAllAccessGroupsPagination(this.currentPage,this.pageSize).subscribe((x) => {
    this.gridData = new MatTableDataSource<AccessGroupModel>(x.data);
      setTimeout(() => {
        this.paginator.pageIndex = (this.currentPage - 1); // index starts with zero
        this.paginator.length = x.totalRecords==undefined?0:x.totalRecords;
      });

      this.gridData.paginator = this.paginator;
      this.gridData.sort = this.sort;
      this.isLoading = false;
    },
      (response) => {
        this._snackBar.open(response.error.Message || "Could not load data. Try again.","Ok");
        this.isLoading = false;
      });
}




}
