<form name="LocationForm" #LocationForm="ngForm" (submit)="saveLocation(LocationForm)" novalidate>
  <div class="col-lg-11 components-block">

    <div class="components-header">
      <h2>Add Location</h2>
      <button type="submit" mat-raised-button color="primary" mat-button class="save-btn">Save</button>
    </div>
    <div class="components-form">

      <div class="components-vertical-tab">
        <div class="components-vertical-link">
          <ul>
            <li><a>Location Information</a></li>
          </ul>
        </div>
        <div class="components-vertical-content">

          <div class="item-information-block">
            <h2>Location Information</h2>
            <div class="row item-row">
              <div class="col-lg-6 form-group">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Location Name</mat-label>
                  <input required name="LocationName" #ItemName="ngModel" matInput placeholder="Enter your Location Name"
                    [(ngModel)]="locationName">
                  <!-- <mat-error *ngIf="ItemName.hasError('required')">
                      Item Name is <strong>required</strong>
                    </mat-error> -->
                </mat-form-field>
              </div>
              <div class="col-lg-6 form-group">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Phone</mat-label>
                  <input name="Phone"  matInput placeholder="Enter your Location Phone"
                    [(ngModel)]="Phone">

                </mat-form-field>
              </div>
              <div class="col-lg-12 form-group">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Address</mat-label>
                  <textarea name="address"  type="text" matInput placeholder="Enter your address"
                  [formControl]="addressControl" [(ngModel)]="locationAddress"></textarea>

                  <mat-error *ngIf="addressControl.hasError('required')">
                    Address is <strong>required</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-lg-6 form-group">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Responsible</mat-label>
                  <input matInput placeholder="Search" name="responsibleUser"  [(ngModel)]="responsibleUserId"
                    aria-label="State" [matAutocomplete]="autoResponsibleUser" [formControl]="responsibleUserControl">
                  <mat-autocomplete #autoResponsibleUser="matAutocomplete" [displayWith]="displayFn(admins)">
                    <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                    <ng-container *ngIf="!isLoading">
                      <mat-option *ngFor="let user of adminsFilter | async" [value]="user.id">
                        <span>{{user.firstName}} {{user.lastName}}<b *ngIf="user.employeeId">({{user.employeeId}})</b></span>
                      </mat-option>
                    </ng-container>
                  </mat-autocomplete>
                </mat-form-field>
              </div>

            </div>

          </div>
        </div>
      </div>

    </div>

  </div>
</form>
