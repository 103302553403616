import { Component, OnInit ,ViewChild,ElementRef} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgForm } from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import { LocationModel } from 'src/app/Models/ComponentModels/LocationModel';
import { CategoryModel } from 'src/app/Models/ComponentModels/Category';
import { SubCategoryModel } from 'src/app/Models/ComponentModels/SubCategory';
import { UserModel } from 'src/app/Models/ComponentModels/UserModel';
import { LocationService } from 'src/app/Services/component-services/location-service';
import { CategoryService } from 'src/app/Services/component-services/category-Service';
import { SubCategoryService } from 'src/app/Services/component-services/subcategory-service';
import { UserService } from 'src/app/Services/component-services/user-service';
import { ItemService } from 'src/app/Services/component-services/item.service';
import { ItemModel } from 'src/app/Models/ComponentModels/ItemModel';
import { environment } from 'src/environments/environment';
import { NameValuePair } from 'src/app/Models/NameValuePair';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DataShareService } from 'src/app/Services/data-share.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { TagService } from 'src/app/Services/component-services/tag.service';
import { Tag } from 'src/app/Models/ComponentModels/Tag';
import { StorageService } from 'src/app/Services/storage-service.service';

@Component({
  selector: 'app-items-edit',
  templateUrl: './items-edit.component.html',
  styleUrls: ['./items-edit.component.css']
})
export class ItemsEditComponent implements OnInit {
  SpecificationNameControl = new FormControl();
  SpecificationValueControl= new FormControl();

  apiURL = environment.ApiUrl;
  oldData:ItemModel;

  @ViewChild('fileInput') fileInput: ElementRef;
  fileAttr = 'Choose File';
  Categories: CategoryModel[] = [];
  SubCategories: SubCategoryModel[] = [];
  Locations: LocationModel[]= [];
  itemSpecFormField: NameValuePair = new NameValuePair();

  ItemSpecsDB:string[] = new Array<string>();
  ItemValuesDB:string[] = new Array<string>();
  itemSpecs: NameValuePair[] = new Array<NameValuePair>();
  itemValues: NameValuePair[] = new Array<NameValuePair>();

  isLoading:boolean = false;
  responsibleUserControl:FormControl;
  subCategoryControl:FormControl;
  OldItemImagePath:string = '';
  OldItemVideoPath:string = '';
  specsFilter: Observable<string[]>;
  valuesFilter: Observable<string[]>;

  admins: UserModel[] = new Array<UserModel>();
  adminsFilter: Observable<UserModel[]>;
  today = new Date();

//! chips
separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl();
  filteredFruits: Observable<string[]>;
  tags: string[] = [];
  allFruits: string[] = ['Apple', 'Lemon', 'Lime', 'Orange', 'Strawberry'];

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;


  TagResponse:Tag[]=[]
  tagNameSerch:string='';
  IsManagerUser:boolean=false;
  locationDisabled:boolean=false;

  constructor(private _locationService: LocationService,
    private _categoryService: CategoryService,
    private _subcategoryService: SubCategoryService,
    private _userService: UserService,
    private _itemService:ItemService,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private _router:Router,
    public _dataShareService: DataShareService,
    private _tagService :TagService,
    private _storageService: StorageService,

  ){
    this.IsManagerUser= this._storageService.IsLoginUserManager()

    this.oldData = new ItemModel();
    this.responsibleUserControl = new FormControl();
    this.subCategoryControl = new FormControl();
  }

  ngOnInit(): void {
    this.populateCategories();
    this.populateLocations();
    this._userService.getallAdminsAndManagers().subscribe((x) => {
      this.admins = x.data;
      this.adminsFilter = this.responsibleUserControl.valueChanges.pipe(
        startWith(''),
        map(value => this.responsible_user_filter(value)
      ));
    });
    const itemId = this.route.snapshot.paramMap.get('id');

    if (itemId) {
      this._itemService.getItemById(+itemId).subscribe((x) => {
        if (x.data) {
          var rawData = x.data as any;
          this.oldData = x.data;
          if(this.oldData.currency==null||undefined)
          {
            this.oldData.currency='PKR'
          }

          if(this.oldData.itemImagePath){
            this.OldItemImagePath = this.oldData.itemImagePath;
          }
          if(this.oldData.itemVideoPath){
            this.OldItemVideoPath = this.oldData.itemVideoPath;
          }
          if(this.oldData.categoryId==1)
          {
           this.locationDisabled=true
          }


          var user = new UserModel();
          user.id = this.oldData.responsibleUserId;
          user.email = this.oldData.responsibleUser;
          user.name = this.oldData.responsibleUser;
          this.admins.push(user);
          this.responsibleUserControl.setValue(this.oldData.responsibleUserId);

          var specs = rawData.itemSpecs;
          specs.forEach((element: any) => {
            var instance = new NameValuePair();
            instance.name = element.specName;
            instance.value = element.specValue;
            this.itemSpecs.push(instance);
        });

          this.itemSpecs
          this.populateSubCategories(this.oldData.categoryId);
        }
        else {
          this._snackBar.open('item not found.');
          this._router.navigate(['/home/items']);
        }
      },
        (response) => { // error scenario
          this._snackBar.open(response.error.Message);
          this._router.navigate(['/home/items']);
        });
    }
    this._itemService.getAllSpecs().subscribe((x) => {

      this.ItemSpecsDB = x.data.specNames;//data.map(function(a: { name: any; }) {return a.name;}) || new Array();
      this.ItemValuesDB = x.data.valueNames;

      this.specsFilter = this.SpecificationNameControl.valueChanges.pipe(
        startWith(''),
        map(value => this.spec_filter(value)
      ));
      this.valuesFilter = this.SpecificationValueControl.valueChanges.pipe(
        startWith(''),
        map(value => this.spec_values_filter(value)
      ));
    });

if(itemId!=null)
{
  let tags="";
  this._tagService.GetAllTagsByItemId(itemId).subscribe(res=>{
res.map(x=>{
  this.tags.push(x.tagName)
})

  })

  if(this.IsManagerUser)
{
  this.responsibleUserControl.disable();

}
}

this.GetAllTags('')


  }


  private spec_filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.ItemSpecsDB.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
  private spec_values_filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.ItemValuesDB.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
  private responsible_user_filter(value: string): UserModel[] {
    const filterValue = value?.toLowerCase();
    return this.admins.filter(option => option.firstName.toLowerCase().startsWith(filterValue) || option.lastName.toLowerCase().startsWith(filterValue) );
  }
  private populateLocations() {
    this._locationService.getAllLocations().subscribe(x => this.Locations = x.data, (response) => {
      this._snackBar.open(response.error.Message);
    });
  }
  private populateCategories() {
    this._categoryService.getAllCategories().subscribe((x) =>{
      this.Categories = x.data;//== undefined ? new CategoryModel[]() : x.data;
    }
    , (response) => {
      this._snackBar.open(response.error.Message);
    });
  }


  private populateSubCategories(CategoryId:Number) {
    this._subcategoryService.getAllSubCategories(CategoryId).subscribe((x) => {
      this.SubCategories = x.data;
    }, (response) => {
      this._snackBar.open(response.error.Message);
    });
  }
  displayFn(options: UserModel[]): (id: string) => string {
    return (id: string) => {

      const correspondingOption = Array.isArray(this.admins) ? this.admins.find(option => option.id === id) : null;
      return correspondingOption ? correspondingOption.firstName + ' '  + correspondingOption.lastName || '': '';
    }
  }

  fileToUpload:any;
  sizeExceeded:boolean = false;
  videoFileSelected:boolean = false;
  uploadFileEvt(imgFile: any) {
    if (imgFile.target.files && imgFile.target.files[0]) {
      var size = imgFile.target.files[0].size;
      var fileType = imgFile.target.files[0].name.split('.').pop();

      this.videoFileSelected = !(fileType.toLowerCase() == 'jpg' || fileType.toLowerCase() == 'jpeg'|| fileType.toLowerCase() == 'png');
      size = size / (1024 * 1024);

      if (size > 4 && !this.videoFileSelected) {
         this.fileInput.nativeElement.value = "";
        this.fileAttr = 'Choose File';
        this.sizeExceeded = true;
        return;
      }
      else{
        this.sizeExceeded = false;
      }
      this.fileToUpload = imgFile.target.files[0];
      this.fileAttr = this.fileToUpload.name;

    } else {
      //this.fileAttr = 'Choose File';
    }
  }
  updateItem(form:NgForm){

    if(form.valid){
      var itemFormData = form.value;

      var data = new FormData();
      data.append('id', this.oldData.id.toString());
      data.append('Name', this.oldData.name);
      data.append('CategoryId', itemFormData.Category);
      data.append('Code', this.oldData.code || '');
      data.append('Model', this.oldData.model|| '');
      data.append('SerialNo', this.oldData.serialNo|| '');
      data.append('Description', itemFormData.Description|| '');

      if(this.oldData.categoryId==1)
      {
        data.append('LocationId', this.oldData.locationId.toString());

      }
      else{
        data.append('LocationId', itemFormData.Location);

      }

      if(!this.IsManagerUser)
      {
        data.append('ProcurmentURL', itemFormData.procurementURL|| '');

      }
      data.append('Status', this.oldData.status|| '');
       data.append('Quantity', itemFormData.Quantity);

        data.append('TotalQuantity', this.oldData.totalQuantity.toString());


      // data.append('Tags',this.tags.toString())


      if(itemFormData.PurchaseCost && !this.IsManagerUser)
      {
        data.append('PurchaseCost', itemFormData.PurchaseCost);
        data.append('Currency', itemFormData.Currency);

      }

      if (itemFormData.PurchaseDate != undefined && !this.IsManagerUser) {
        let date = new Date(itemFormData.PurchaseDate);
        data.append('PurchaseDate', date.toLocaleDateString());
      }

      data.append('ResponsibleUserId', this.responsibleUserControl.value || '');
      if(itemFormData.SubCategory){
        data.append('SubCategoryId', itemFormData.SubCategory);
      }
      data.append('OldItemImagePath', this.OldItemImagePath);
      data.append('OldItemVideoPath', this.OldItemVideoPath);

      if(this.fileToUpload){
        data.append('file', this.fileToUpload, this.fileToUpload.name);
      }else{
        data.delete('file');
      }
      var specNamesList = '';

      this.itemSpecs.forEach((item,i) => {
        specNamesList+=item.name + ':' + item.value + ',';
      });

      specNamesList = specNamesList.substring(0, specNamesList.length - 1);

      if(this.itemSpecs.length > 0){
        data.append('specNamesList', specNamesList);
      }
      this.oldData.responsibleUserId = this.responsibleUserControl.value;
      this.isLoading = true;
      this._itemService.updateItem(data).subscribe((x)=>{
        this.isLoading = false;

        if(this.videoFileSelected){
          this._snackBar.open('Success. File is processing at server and may take long time. Refresh this page after a while.');
        }else{
          this._snackBar.open('Item Updated Successfully','OK');
          this._tagService.CreateTagsWithItems(this.tags.toString(),this.oldData.id.toString());

        }

        this._router.navigate(['/home/items']);
      },(response) => {
        this._snackBar.open(response.error.Message || "Something went wrong. Try again.", 'Ok!');
      });
    }else{
      this._snackBar.open("From is invalid");
    }
  }
  AddSpecification() {
    if (this.itemSpecFormField.name && this.itemSpecFormField.value) {
      var data = new NameValuePair();
      data.name = this.itemSpecFormField.name;
      data.value = this.itemSpecFormField.value;
      this.itemSpecs.push(data);
    }
    this.itemSpecFormField.name = "";
    this.itemSpecFormField.value = "";
  }
  DeleteSpecification(specName: string, specValue: string) {
    if (specName && specValue) {
      this.itemSpecs.forEach((spec, index) => {
        if (spec.name == specName && spec.value == specValue) this.itemSpecs.splice(index, 1);
      });
    }
  }


//! chips

add(event: MatChipInputEvent): void {


  const value = (event.value || '').trim();
  // Add our fruit
  if (value) {
    if(this.tags.indexOf(value)==-1)
    {
      this.tags.push(value);
    }
  }
  // Clear the input value
  event.chipInput!.clear();
  this.tagCtrl.setValue(null);
}

remove(tag: string): void {
  const index = this.tags.indexOf(tag);
  if (index >= 0) {
    this.tags.splice(index, 1);
  }
}

selected(event: MatAutocompleteSelectedEvent): void {
  if(this.tags.indexOf(event.option.viewValue)==-1)
  {
    this.tags.push(event.option.viewValue);
  this.tagInput.nativeElement.value = '';
  this.tagCtrl.setValue(null);
  }
  this.tagInput.nativeElement.blur();

}

GetAllTags(tagName?:string)
{
  var data = new FormData();
if(tagName==undefined || tagName==null)
{
  tagName='';
}
data.append('ItemName', tagName);
  this._tagService.GetAllTags(data).subscribe(res=>{
    this.TagResponse=res;
  })
}

applyFilter()
{
  this.GetAllTags(this.tagNameSerch);
}

updateSubCategories(event:any){
  var selectedCategoryId = event.value;
  this.populateSubCategories(selectedCategoryId);
  this.IsDisabledLocaton()

}

IsDisabledLocaton()
{
  if(this.oldData.categoryId==1)
    {
      this.locationDisabled=true;
      this.oldData.locationId=8

    }
   else{
  this.locationDisabled=false;
    }
}

}
