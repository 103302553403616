import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';

import { CommonModule } from '@angular/common';
import { LoginComponent } from 'src/app/components/login/login.component';
import { HomeComponent } from '../components/home/home.component';
import { ItemsAddComponent } from '../components/Items/items-add/items-add.component';
import { ItemsListingComponent } from '../components/Items/items-listing/items-listing.component';
import { ItemsEditComponent } from '../components/Items/items-edit/items-edit.component';
import{AuthGuard} from './../Guards/auth.guard';
import { UsersAddComponent } from '../components/Users/add/users-add.component';
import { UsersListComponent } from '../components/Users/list/users-list/users-list.component';
import { NotfoundComponent } from '../components/notfound/notfound.component';
import { CheckinCheckoutReportComponent } from '../components/reports/checkin-checkout-report/checkin-checkout-report.component';
import { ItemsAddDuplicateComponent } from '../components/items/items-add-duplicate/items-add-duplicate.component';
import { LocationsAddComponent } from '../components/location/locations-add/locations-add.component';
import { LocationEditComponent } from '../components/location/location-edit/location-edit.component';
import { LocationsListComponent } from '../components/location/locations-list/locations-list.component';
import { CategoriesComponent } from '../components/categories/categories/categories.component';
import { ActiveCheckoutsComponent } from '../components/reports/active-checkouts/active-checkouts.component';
import { AccessGroupListingComponent } from '../components/AccessGroup/listing/listing.component';
import { AddAccessGroupComponent } from '../components/AccessGroup/add-access-group/add-access-group.component';
import { SuperAdminGuard } from '../Guards/superAdmin.guard';
import { ImportItemComponent } from '../components/Items/import-item/import-item.component';
import { SummaryAlertsComponent } from '../components/summary-alerts/summary-alerts.component';
import { ItemAddGuard } from '../Guards/ItemGuards/item.add.guard';
import { ItemUpdateGuard } from '../Guards/ItemGuards/item.update.guard';
import { ItemViewGuard } from '../Guards/ItemGuards/item.grid.guard';
import { ItemImportGuard } from '../Guards/ItemGuards/item.import.guard';
import { ActivityReportGuard } from '../Guards/ReportGuards/activity.report.guard';
import { ActiveCheckOutReportGuard } from '../Guards/ReportGuards/active.checkout.report.guard';
import { LocationReadGuard } from '../Guards/MaintenanceGuards/location.read.guard';
import { LocationCreateGuard } from '../Guards/MaintenanceGuards/location.create.guard';
import { LocationUpdateGuard } from '../Guards/MaintenanceGuards/location.update.guard';
import { CategorySubCategoryReadGuard } from '../Guards/MaintenanceGuards/category-subcategory.read.guard';
import { UserCreateGuard } from '../Guards/UsersGuard/user.create.guard';
import { UserReadGuard } from '../Guards/UsersGuard/user.read.guard';
import { UserUpdatGuard } from '../Guards/UsersGuard/user.update.guard';
import { SummeryEmailReadGuard } from '../Guards/MaintenanceGuards/summery.emails.read.guard';
import { NotEnoughRightsComponent } from '../components/not-enough-rights/not-enough-rights.component';
import { ItemCloneGuard } from '../Guards/ItemGuards/item.clone.guard';
import { AccessGroupGridGuard } from '../Guards/MaintenanceGuards/access-group-grid.guard';
import { AccessGroupAddGuard } from '../Guards/MaintenanceGuards/access-group-add.guard';
import { AccessGroupUpdateGuard } from '../Guards/MaintenanceGuards/access-group-update.guard';
//canActivate:[AuthGuard]
const routes: Routes = [
  { path: '', redirectTo: '/home/items', pathMatch: 'full',  },
  { path: 'home', component: HomeComponent, canActivate:[AuthGuard],
  data: {
    breadcrumb: {
      label: 'Home',
      info: { myData: { icon: 'home', iconType: 'material' } }
    }
  },
  children: [

    {
      path:'notEnoughRights',
      component:NotEnoughRightsComponent
    },

    {
       path: 'items',
       component: ItemsListingComponent,
       canActivate:[ItemViewGuard]

    },
    {
      path:'item/import',
      component:ImportItemComponent,
      canActivate:[ItemImportGuard]
    },
    {
      path: 'item/add',
       component: ItemsAddComponent,
       canActivate:[ItemAddGuard]
    },
    {
      path:'locations',
      component:LocationsListComponent,
      canActivate:[LocationReadGuard]
      },
    {
      path: 'location/add',
       component: LocationsAddComponent,
       canActivate:[LocationCreateGuard]

    },

    {
      path: 'location/edit/:id',
      component: LocationEditComponent,
      canActivate:[LocationUpdateGuard]
     },
     {
       path:'category',
       component:CategoriesComponent,
       canActivate:[CategorySubCategoryReadGuard]
     },
    {
      path: 'item/edit/:id',
      component: ItemsEditComponent,
      canActivate:[ItemUpdateGuard]
     },
     {
      path: 'item/clone/:id',
      component: ItemsAddDuplicateComponent,
      canActivate:[ItemCloneGuard]
     },
    {
      path: 'user/add',
      component: UsersAddComponent,
      canActivate:[UserCreateGuard]

     },
     {
      path: 'user/add/:id',
      component: UsersAddComponent,
      canActivate:[UserUpdatGuard]

     },
    {
      path: 'users',
      component: UsersListComponent,
      canActivate:[UserReadGuard]

     },
    {
      path: 'user-report',
      component: CheckinCheckoutReportComponent,
      canActivate:[ActivityReportGuard]
    },
    {
      path: 'active-checkouts-report',
      component: ActiveCheckoutsComponent,
      canActivate:[ActiveCheckOutReportGuard]
    },
    {
      path: 'access-groups',
      component: AccessGroupListingComponent,
      canActivate:[SuperAdminGuard,AccessGroupGridGuard]
    },
    {
      path: 'add-access-groups',
      component: AddAccessGroupComponent,
      canActivate:[SuperAdminGuard,AccessGroupAddGuard]
    },
    {
     path: 'add-access-groups/:id',
     component: AddAccessGroupComponent,
     canActivate:[SuperAdminGuard,AccessGroupUpdateGuard]
    },
    {
      path: 'add-alert',
      component: SummaryAlertsComponent,
      canActivate:[SuperAdminGuard,SummeryEmailReadGuard],

    }
   ]
  },

  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '**',
    component: NotfoundComponent
  },  // Wildcard route for a 404

];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
