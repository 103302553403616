<mat-dialog-content class="mat-typography">

<mat-progress-bar [ngStyle]="{'visibility':isLoading==true ? 'visible' : 'hidden'}" color="primary"
  mode="indeterminate"></mat-progress-bar>
  <div class="category-form">
<div class="dialog-header">
  <h2 mat-dialog-title>Add New Category</h2>
    <button (click)="CloseCategoryModalByButton()"><mat-icon>close</mat-icon></button>
</div>
<div class="row" >
 <input hidden type="number"  [(ngModel)]='categoryRequest.id' name="id" >
 <div class="col-lg-9">

  <mat-form-field class="full-width" appearance="outline">
    <mat-label>Category Name</mat-label>
      <input type="text" [(ngModel)]='categoryRequest.name' name="category_name"  placeholder="Type Category name"
      aria-label="Category Name" matInput required />
  </mat-form-field>
 </div>
 <div class="col-lg-3">
  <button  (click)="SetCategory()" type="button" mat-raised-button color="primary"
  mat-button class="save-btn">
  {{SetCategoryBtn}}
</button>
 </div>
</div>
<div class="category-table" style="overflow: auto; max-height: 500px;">
<table  class="table" style="overflow-x: scroll !important;">
  <thead>
    <tr>
      <th>ID</th>
      <th>Category</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let category of categoryResponse">
    <td>{{category.id}}</td>
    <td> {{category.name}}</td>
      <td>
        <button  matTooltip="Edit"
          matTooltipClass="matTooltip-Custom"
          matTooltipPosition="above"
          class="column-name-link"
          (click)="EditCategory(category)" ><mat-icon>edit</mat-icon></button>
  </tr>
  </tbody>
</table>
</div>

</div>


