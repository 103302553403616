import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { throwIfEmpty } from 'rxjs/operators';
import { UserAccessPermissions } from 'src/app/Models/AccessPermissions';
import { CategoryModel } from 'src/app/Models/ComponentModels/Category';
import { SubCategoryModel } from 'src/app/Models/ComponentModels/SubCategory';
import { CategoryService } from 'src/app/Services/component-services/category-Service';
import { SubCategoryService } from 'src/app/Services/component-services/subcategory-service';
import { DataShareService } from 'src/app/Services/data-share.service';
import { StorageService } from 'src/app/Services/storage-service.service';
@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  categoryRequest: CategoryModel;
  isLoading = false;
  categoryResponse: CategoryModel[];
  SetCategoryBtn: string = 'Add';
  SetSubCategoryBtn: string = 'Add';
  displayedColumns2: string[] = ['ID','Category','Actions'];



  displayedColumns: string[] = ['ID','SubCategory','Category','Actions'];
  Subcategory_name:string
  subCategoryModel:SubCategoryModel;
  Categories: CategoryModel[] = [];
  subCategoryResponse:SubCategoryModel[]=[];

  userAccessPermission:UserAccessPermissions

  constructor(
               private _categoryService: CategoryService,
               private _subCategoryService: SubCategoryService,
               private _snackBar: MatSnackBar,
               private _dataShareService:DataShareService,
               private _storageService:StorageService,

    )
  {
    this.subCategoryModel=new SubCategoryModel()
   // this.populateCategories();
    this.GetAllCategories()
    this.GetAllSubCategoriesList()
    this.userAccessPermission=new UserAccessPermissions();


  }
  ngOnInit(): void {

    // Categories
    this.categoryRequest = new CategoryModel();

    // SubCategories
    //this.populateCategories()
    this.GetAllCategories()
    this.GetAllSubCategoriesList()
    this.GetUserAccessPermission()

  }

//Catgories
GetAllCategories()
  {
    this.categoryResponse = [];
    this._categoryService.getAllCategoriesData().subscribe((response) => {
      this.categoryResponse = response.data;
    });

  }



  SetCategory(form: NgForm) {

    if (this.categoryRequest.name.length < 3) {
      this._snackBar.open(
        'Category Name Should be at least 3 character long.',
        'Ok!'
      );
      return;
    }
    if (!this.categoryRequest.id) {
      this.AddNewCategory();
       form.resetForm();
       this.categoryRequest.id=0

    } else {
      this.UpdateCategory();
       form.resetForm();
       this.categoryRequest.id=0
      this.SetCategoryBtn = 'Add';
    }


  }

  AddNewCategory() {
    this.categoryRequest.name.trim();

    this.isLoading = true;
    this._categoryService.addNewCategory(this.categoryRequest).subscribe(
      (response) => {
        //this.populateCategories();
        this.GetAllCategories();
        this._snackBar.open('Category added successfully.', 'OK!');
        this.isLoading = false;
        this.GetAllCategories()


      },
      (errorResponse) => {
        // this._snackBar.open(errorResponse.error.Message, "Ok!");
        this._snackBar.open('Unable to add the duplicate Category', 'OK!');
        this.isLoading = false;
      }
    );

  }
  UpdateCategory() {
    this.isLoading = true;
    this._categoryService.UpdateCategory(this.categoryRequest).subscribe(
      (response) => {
        this._snackBar.open('Category updated successfully.', 'Ok!');
        this.isLoading = false;
        // this.populateCategories();
        this.GetAllCategories()
      },
      (errorResponse) => {
        // this._snackBar.open(errorResponse.error.Message, "Ok!");
        this._snackBar.open('Unable to Update  Category', 'Ok!');
        this.isLoading = false;
        this.GetAllCategories()
      }
    );
  }
  EditCategory(category: any) {
    this.categoryRequest = category;
    this.SetCategoryBtn = 'Update';
  }


//****************************************************** ***************************************************/
// SubCategoires

  // private populateCategories() {
  //   this._categoryService.getAllCategoriesData().subscribe((x) => {
  //     this.Categories = x.data//== undefined ? new CategoryModel[]() : x.data;
  //   })
  // }

  GetAllSubCategoriesList()
  {
    this._subCategoryService.GetAllSubCategoriesList().subscribe(res=>{
      this.subCategoryResponse=JSON.parse(JSON.stringify(res));
    })
  }
  SetSubCategory(form: NgForm)
  {
    if (form.valid) {
      if(!this.subCategoryModel.categoryId){
        this._snackBar.open("Category is required.",'OK!');
        return;
      }
       if(!this.subCategoryModel.name){
        this._snackBar.open("Sub-category  is required.",'OK!');
        return;
      }
      if(this.subCategoryModel.name.length<3)
      {
        this._snackBar.open("Sub-category name length Should be greater than 3 Letters",'OK!');
        return;
      }
      if(!this.subCategoryModel.id)
      {
           this.AddNewSubCategory();
           //form.resetForm();
            this.subCategoryModel.name='';
          //  this.subCategoryModel.id=0;
      }
      else{
         this.UpdateSubCategory();
         this.SetSubCategoryBtn = 'Add';
         form.resetForm();

      }
    }

  }

  AddNewSubCategory()
  {

      this.isLoading=true
     this._subCategoryService.addNewSubCategory(this.subCategoryModel).subscribe(response=>{
      this._snackBar.open("Sub-category added successfully.", "Ok!");
      this.isLoading=false
      // this.GetAllSubCategoriesList()

        this.GetSubCategoriesById(this.subCategoryModel.categoryId);



    },(errorResponse)=>{
      //this._snackBar.open(errorResponse.error.Message, "Ok!");
      this._snackBar.open("Unable to add duplicate Sub-category", "Ok!");
      this.isLoading=false
      this.GetAllSubCategoriesList()

    })
  }

  UpdateSubCategory()
  {
    this.isLoading=true;
    this._subCategoryService.UpdateSubCategory(this.subCategoryModel).subscribe(response=>{
      this._snackBar.open("Sub-category updated successfully.", "Ok!");
      this.isLoading=false
      this.GetAllSubCategoriesList()
    },(errorResponse)=>{
      this._snackBar.open("Unable to update Sub-category", "Ok!");
      this.isLoading=false
      this.GetAllSubCategoriesList()

    });
  }


  EditSubCategory(SubCategory:any)
  {
    this.subCategoryModel=SubCategory;
    this.SetSubCategoryBtn = 'Update';
  }
   GetSubCategoriesById(CategoryId: Number) {
     if(CategoryId!=undefined)
     {
      this._subCategoryService.getAllSubCategories(CategoryId).subscribe(
        (x) => {
          this.subCategoryResponse = x.data;
        },
        (response) => {
          this._snackBar.open('Some Error Occure While Loading Sub-Categories', 'OK');
        }
      );
     }
     else{
      this.GetAllSubCategoriesList();
     }

  }

  GetUserAccessPermission()
{
 this.userAccessPermission=this._storageService.GetLoginUserPermissions()
}
}
