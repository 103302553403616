import { AccessGroupModel } from "./AccessGroupModel";
import { UserRole } from "./UserRole";

export class UserModel{

  id:string;
  userName:string;
  name:string;
  firstName:string;
  lastName:string;
  email:string;
  employeeId:string;
  address:string;
  address2:string;
  phoneNumber:string;
  status:string;
  roles:UserRole[];
  accessGroupIds:number[];
  allowIMS:boolean;
  isEmployee:boolean;

  constructor() {
    this.id = '';
    this.name = '';
    this.userName = '';
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.employeeId = '';
    this.address = '';
    this.address2 = '';
    this.status = '';
    this.allowIMS=false
    this.isEmployee=false;
  }
}
