import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import {AuthenticationService} from '../../Services/auth-service';
import { StorageService } from '../../Services/storage-service.service';
@Injectable({
  providedIn: 'root'
})
export class ItemViewGuard implements CanActivate {
  IsEmployeeUser:boolean=false;
  IsManagerUser:boolean=false;

  constructor(
    private router:Router,
    private authenticationService: AuthenticationService,
    private storageService:StorageService,
    public _storageService: StorageService,
    ){

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {


      const currentUser = this.storageService.GetLoggedInUser();
      const userAccessPermission=this.storageService.GetLoginUserPermissions()
      this.IsEmployeeUser= this._storageService.IsLoginUserEmployee()
      this.IsManagerUser= this._storageService.IsLoginUserManager()

      if (userAccessPermission.readItem) {

          return true;
      }
      else if(this.IsEmployeeUser)
      {
        return true;
      }
      else if(this.IsManagerUser)
      {
        return true;
      }

      if(!this.IsEmployeeUser && !this.IsManagerUser){
      this.router.navigate(['/home/notEnoughRights']);
    }
      return false;
  }

}
