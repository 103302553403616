<form name="SubCategoryForm" #SubCategoryForm="ngForm" (submit)="AddNewSubCategory(SubCategoryForm)" novalidate>
  <mat-progress-bar [ngStyle]="{'visibility':isLoading==true ? 'visible' : 'hidden'}" color="primary"
    mode="indeterminate"></mat-progress-bar>
    <div class="category-form">
<div class="dialog-header">
    <h2 mat-dialog-title>Add New SubCategory</h2>
      <button (click)="CloseSubCategoryModalByButton()"><mat-icon>close</mat-icon></button>
  </div>
  <div class="col-lg-12">
    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Select a Category</mat-label>
    <mat-select   name="categoryId" [(ngModel)]="subCategoryModel.categoryId" required>
      <mat-option>None</mat-option>
      <mat-option *ngFor="let category of Categories" [value]="category.id">
        {{category.name}}
      </mat-option>
    </mat-select>
    </mat-form-field>
  </div>

  <div class="col-lg-12">
  <mat-form-field class="full-width" appearance="outline">
    <input type="text" [(ngModel)]='subCategoryModel.name' name="name" placeholder="SubCategory name"
      aria-label="SubCategory Name" matInput required />
  </mat-form-field>
</div>
<div class="col-lg-12 text-center">
    <button type="submit" mat-raised-button color="primary" mat-button
      class="save-btn">Add</button>
</div>
</div>
</form>



