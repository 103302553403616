<!-- <mat-progress-bar [ngStyle]="{'visibility':isLoading==true ? 'visible' : 'hidden'}" color="primary"
  mode="indeterminate"></mat-progress-bar> -->

<div class="col-lg-12 components-block">
  <div class="components-header">
    <h2>Categories</h2>
  </div>
  <div class="row  custom-row">
    <div class="col-lg-5 category-block">
      <!-- Categories -->

      <mat-progress-bar [ngStyle]="{'visibility':isLoading==true ? 'visible' : 'hidden'}" color="primary"
      mode="indeterminate"></mat-progress-bar>
        <h3 [hidden]="!userAccessPermission.crateCategorySubCategory">Add New Category</h3>
        <form name="CategoryForm" #CategoryForm="ngForm" (submit)="SetCategory(CategoryForm)" [hidden]="!userAccessPermission.crateCategorySubCategory">

    <div class="row">
        <div class="col-lg-7 form-group">
          <input hidden type="number"  [(ngModel)]='categoryRequest.id' name="id" >

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Category Name</mat-label>
              <input type="text" [(ngModel)]='categoryRequest.name' name="category_name"  placeholder="Type Category name"
              aria-label="Category Name" matInput  />

            </mat-form-field>
          </div>
          <div class="col-lg-3 form-group">
            <button  type="submit" mat-raised-button color="primary" mat-button class="save-btn">
              {{SetCategoryBtn}}
            </button>
        </div>
    </div>
    </form>

    <div class="mat-table-block mat-table-scroll">
        <table mat-table [dataSource]="categoryResponse" class="mat-elevation-z8 ">

            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

            <!-- Position Column -->
            <ng-container matColumnDef="ID">
              <th mat-header-cell *matHeaderCellDef> ID </th>
              <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="Category">
              <th mat-header-cell *matHeaderCellDef> Category </th>
              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

             <!-- Actions Column -->
             <ng-container matColumnDef="Actions">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element">
                    <button  matTooltip="Edit"
                            matTooltipClass="matTooltip-Custom"
                            matTooltipPosition="above"
                            class="column-name-link"
                            (click)="EditCategory(element)"
                            [hidden]="!userAccessPermission.updateCategorySubCategory"
                             ><mat-icon>edit</mat-icon></button>

                </td>
              </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
          </table>
    </div>
    </div>


<!-- SubCategories -->

    <div class="col-lg-7 category-block">
      <h3 [hidden]="!userAccessPermission.crateCategorySubCategory">Add New Sub-category</h3>
      <form name="SubCategoryForm" #SubCategoryForm="ngForm" (submit)="SetSubCategory(SubCategoryForm)" novalidate
      [hidden]="!userAccessPermission.crateCategorySubCategory">

        <div class="row">
          <input hidden type="text" [(ngModel)]='subCategoryModel.id' name="id" placeholder="SubCategory Id"
            aria-label="SubCategory Id" matInput />


          <div class="col-lg-4 form-group">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Select a Category</mat-label>
              <mat-select name="categoryId" [(ngModel)]="subCategoryModel.categoryId" required (selectionChange)="GetSubCategoriesById(subCategoryModel.categoryId)">
                <mat-option >None</mat-option>
                <mat-option *ngFor="let category of categoryResponse" [value]="category.id">
                  {{category.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-lg-4 form-group">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Sub-category Name</mat-label>
              <input type="text" [(ngModel)]='subCategoryModel.name' name="name" placeholder="SubCategory name"
                aria-label="SubCategory Name" matInput  />

            </mat-form-field>
          </div>

          <div class="col-lg-2 form-group">
            <button type="submit" mat-raised-button color="primary" mat-button class="save-btn">{{SetSubCategoryBtn}}</button>
          </div>
        </div>
      </form>
      <div class="mat-table-block mat-table-scroll">
        <table mat-table [dataSource]="subCategoryResponse" class="mat-elevation-z8">


          <ng-container matColumnDef="ID">
            <th mat-header-cell *matHeaderCellDef> ID </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
          </ng-container>

          <ng-container matColumnDef="SubCategory">
            <th mat-header-cell *matHeaderCellDef> Sub-category </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>

          <ng-container matColumnDef="Category">
            <th mat-header-cell *matHeaderCellDef> Category </th>
            <td mat-cell *matCellDef="let element"> {{element.categoryName}} </td>
          </ng-container>

          <ng-container matColumnDef="Actions">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element">
              <button matTooltip="Edit" matTooltipClass="matTooltip-Custom" matTooltipPosition="above"
                class="column-name-link" (click)="EditSubCategory(element)"
                [hidden]="!userAccessPermission.updateCategorySubCategory">
                <mat-icon>edit</mat-icon>
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>

    </div>
  </div>


</div>
