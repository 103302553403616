<div *ngIf="isLoading" class="progress-spinner">
  <mat-spinner></mat-spinner>
</div>
<div class="components-details-section" [hidden]="!isSidebarRight">
  <div class="mat-overlay"></div>
  <div class="components-details-area">
    <div class="details-header">
      <h2>Item Details</h2>


      <a  matTooltip="Refresh"  (click)="refreshHistory(ItemDetails.id)"
 matTooltipClass="matTooltip-Custom" matTooltipPosition="above" class="column-name-link">
 <mat-icon style="position: relative;top: 7px;left: 11px; font-size: 21px;" >refresh</mat-icon>

</a>
      <span class="close-icon">
        <mat-icon (click)="close()">clear</mat-icon>
      </span>
    </div>

    <div class="details-content">
      <div class="inner-spinner">
        <mat-spinner></mat-spinner>
      </div>
      <mat-tab-group [(selectedIndex)]="selectedTabIndex">
        <mat-tab>
          <ng-template class="mat-tab-label-active" mat-tab-label >
            Details
          </ng-template>
             <div class="details-tabs-content-main">
          <div class="details-tabs-content">
             <div class="details-tabs-content-scroll">
              <div class="drow-main">
                <div *ngIf="ItemDetails.itemImagePath" class="lightgallery-list">
                  <lightgallery class="lightgallery-items" [settings]="settings" [onBeforeSlide]="onBeforeSlide" [onInit]="onInit">
                    <a href="{{apiURL}}/{{ItemDetails.itemImagePath}}">
                      <img class="img-responsive"
                      src="{{apiURL}}/{{ItemDetails.itemImagePath}}" width="48" height="48" />
                    </a>
                  </lightgallery>
                </div>
                <div class="lightgalleryvideo" *ngIf="ItemDetails.itemVideoPath">
                  <span><ng-image-slider [images]="[{video:apiURL + '/' + ItemDetails.itemVideoPath, posterImage: '../../../assets/images/black-poster.png'}]" #nav></ng-image-slider></span>
                </div>
              </div>
            <div class="drow-main">
              <div class="drow">
                <label>Name:</label>
                <span>{{ItemDetails.name}}</span>
              </div>
              <div class="drow" *ngIf="ItemDetails.code"  >
                <label>Code:</label>
                <span>{{ItemDetails.code}}</span>
              </div>
              <div class="drow" *ngIf="ItemDetails.serialNo">
                <label>Serial No:</label>
                <span>{{ItemDetails.serialNo}}</span>
              </div>
              <div class="drow">
                <label>Responsible:</label>
                <span>{{ItemDetails.responsibleUser}}</span>
              </div>
              <div class="drow item-status"  *ngIf="ItemDetails.status">
                <label>Item Status:</label>
                <span [ngClass]="getStatusClass(ItemDetails.status)">{{ItemDetails.status}}</span>
              </div>

              <div class="drow" *ngIf="!IsEmployeeUser">
                <label>Quantity:</label>
                <span>{{ItemDetails.quantity}}</span>
              </div>


              <div class="drow">
                <label>Category:</label>
                <span>{{ItemDetails.category}}</span>
              </div>
              <div class="drow">
                <label>Sub Category:</label>
                <span>{{ItemDetails.subCategory}}</span>
              </div>

                <div class="drow itemLocation">
                <label>Location:</label>
                <span>{{ItemDetails.location}}</span>
              </div>

              <div class="drow itemdescription" *ngIf="ItemDetails.description">
                <label>Description:</label>
                <span>{{ItemDetails.description}}</span>
              </div>

              <div class="drow">
                <label> Date Created:</label>
                <span>{{ItemDetails.created | date:'dd/MM/YYY'}}</span>
              </div>
              <div class="drow">
                <label>Created By:</label>
                <span>{{ItemDetails.createdBy}}</span>
              </div>
              <div class="drow procurmenturl" *ngIf="ItemDetails.procurmentURL && !IsEmployeeUser">
                <label>Procurement URL:</label>
                <a target="_blank" href="{{ItemDetails.procurmentURL}}">Click to open</a>
              </div>
              <div class="drow" *ngIf="ItemDetails.purchaseCost && !IsEmployeeUser">
                <label>Purchase Cost:</label>
                <span *ngIf="ItemDetails.purchaseCost>0 "> {{ItemDetails.currency}} {{ItemDetails.purchaseCost | currency:'':''}} </span>
              </div>
              <div class="drow"  *ngIf="ItemDetails.purchaseDate && !IsEmployeeUser">
                <label>Purchase Date:</label>
                <span>{{ItemDetails.purchaseDate | date:'dd/MM/YYY'}}</span>
              </div>
            </div>

          <div class="drow-main specs-main" *ngIf="showSpec" >
            <h2>Specs:</h2>
              <div class="drow"  *ngFor="let spec of ItemDetails.itemSpecs">
                <label> {{spec.specName}}</label>
                <span> {{spec.specValue}} </span>
              </div>
          </div>
<!--Tags-->
<!--Super Admin View-->
<div class="drow-main" *ngIf="ItemDetails.itemTagsView && !IsManagerUser" >
<div
[hidden]="!userAccessPermission.updateItem || IsEmployeeUser"
class="form-group form-tags-group">
  <mat-form-field class="example-chip-list" appearance="standard">
    <mat-label>Add Tags</mat-label>
    <mat-chip-list #chipList aria-label="Fruit selection">
      <mat-chip *ngFor="let tag of tags" (removed)="remove(tag)">
        {{tag}}
        <button matChipRemove>
          <mat-icon>close</mat-icon>
        </button>
      </mat-chip>
      <input (keyup)="applyFilter()" placeholder="New Tag" #tagInput [formControl]="tagCtrl" [matAutocomplete]="auto"
        [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)" [(ngModel)]="tagNameSerch">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let tag of TagResponse" [value]="tag.tagId">
        {{tag.tagName}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
</div>
<!--Manager View-->
<div class="drow-main" *ngIf="ItemDetails.itemTagsView && IsManagerUser" >
  <div
  [hidden]="!ItemDetails.isItemCreatedByManager"
  class="form-group form-tags-group">
    <mat-form-field class="example-chip-list" appearance="standard">
      <mat-label>Add Tags</mat-label>
      <mat-chip-list #chipList aria-label="Fruit selection">
        <mat-chip *ngFor="let tag of tags" (removed)="remove(tag)">
          {{tag}}
          <button matChipRemove>
            <mat-icon>close</mat-icon>
          </button>
        </mat-chip>
        <input (keyup)="applyFilter()" placeholder="New Tag" #tagInput [formControl]="tagCtrl" [matAutocomplete]="auto"
          [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add($event)" [(ngModel)]="tagNameSerch">
      </mat-chip-list>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let tag of TagResponse" [value]="tag.tagId">
          {{tag.tagName}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  </div>




        </div>
        </div>
      </div>
          <div class="details-footer" [hidden]="IsEmployeeUser" >

            <!--Super Admin-->
            <button
            *ngIf="!IsManagerUser"
            [disabled]="!userAccessPermission.updateItem"
              type="button" class="tabs-actions-button" routerLink="/home/item/edit/{{ItemDetails.id}}" mat-raised-button
              color="primary" mat-button (click)="edit()">Edit</button>

              <!--Manager-->
              <button
              *ngIf="IsManagerUser"
              [disabled]="!ItemDetails.isItemCreatedByManager"
                type="button" class="tabs-actions-button" routerLink="/home/item/edit/{{ItemDetails.id}}" mat-raised-button
                color="primary" mat-button (click)="edit()">Edit</button>



          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            History
          </ng-template>
          <div class="details-tabs-content-main">
          <div class="details-tabs-content">
          <div class="details-tabs-content-scroll">
          <app-item-details-history #itemHistory [ItemId]="ItemDetails.id" (refreshTab)="refreshHistory(ItemDetails.id)"></app-item-details-history>
        </div>
        </div>
        </div>
        </mat-tab>

<!-- Super Admin View -->
        <mat-tab *ngIf="!IsEmployeeUser && !IsManagerUser">
          <ng-template mat-tab-label >
            Status
          </ng-template>

          <div class="access-permission-message" *ngIf="!userAccessPermission.checkInItem && !userAccessPermission.checkOutItem">
            <p>You don't have enough permissions</p>
        </div>

          <form name="statusForm" [formGroup]="statusForm"  (ngSubmit)="save()" novalidate [hidden]="!userAccessPermission.checkInItem && !userAccessPermission.checkOutItem ">
            <div class="details-tabs-content-main">
            <div class="details-tabs-content">
              <div class="details-tabs-content-scroll">
            <div class="items-details-status">
             <div class="col-lg-12 form-group">
              <label class="checkout-label">{{ItemDetails.name}}: {{statusForm.controls.selectActionControl.value == "CheckIn" ? 'Check-in':'Check-out'}}</label>
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Action</mat-label>
                  <mat-select required formControlName="selectActionControl" (selectionChange)="updateValidationRules()" name="selectActionControl">
                    <mat-option value="CheckOut"
                      [disabled]="!userAccessPermission.checkOutItem"
                    >
                      Check-out
                    </mat-option>
                    <mat-option value="CheckIn"
                    [disabled]="!userAccessPermission.checkInItem"

                    >
                      Check-in
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div [hidden]="statusForm.controls.selectActionControl.value=='CheckIn'" class="col-lg-12 form-group">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label> Check-out To</mat-label>
                  <input required type="text" placeholder="Select User" matInput name="selectUserControl" formControlName="selectUserControl"
                    [matAutocomplete]="auto">
                    <mat-error *ngIf="statusForm.controls.selectUserControl.invalid">
                      Please select a user
                    </mat-error>
                  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn(employees)"
                    (optionSelected)="selectUser($event.option.value,employees)">
                    <mat-option *ngFor="let employee of filteredEmployees | async" [value]="employee.id">
                      {{employee.firstName}} {{employee.lastName}} <b *ngIf="employee.employeeId">({{employee.employeeId}})</b>
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>

              <div [hidden]="statusForm.controls.selectActionControl.value=='CheckOut'" class="col-lg-12 form-group">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Check-in From</mat-label>
                  <input required type="text" placeholder="Select User" matInput name="checkOutFromUserControl" formControlName="checkOutFromUserControl"
                    [matAutocomplete]="checkOutFromUserControlMatAutocomplete">
                    <mat-error *ngIf="statusForm.controls.checkOutFromUserControl.invalid">
                      Please select a user
                    </mat-error>
                  <mat-autocomplete autoActiveFirstOption #checkOutFromUserControlMatAutocomplete="matAutocomplete" [displayWith]="displayCheckOuts(itemCheckOuts)"
                    (optionSelected)="selectUser($event.option.value,employees)">
                    <mat-option *ngFor="let checkOutItem of filtereditemCheckOuts | async" [value]="checkOutItem.id">
                      {{checkOutItem.issuedToUser.firstName}} {{checkOutItem.issuedToUser.lastName}}
                       <span *ngIf="checkOutItem.issuedToUser.employeeId">({{checkOutItem.issuedToUser.employeeId}})</span>&nbsp;
                       <b>|</b>&nbsp;{{checkOutItem.actionDate | date:'dd/MM/YYY'}} <b>|</b>
                       Qty: {{checkOutItem.qtyChanged}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>

               <!-- <div [hidden]="statusForm.controls.selectActionControl.value=='CheckOut'" class="col-lg-12 form-group">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Check-in To</mat-label>
                  <input  type="text" placeholder="Select Responsible" matInput name="selectAdminControl" formControlName="selectAdminControl"
                    [matAutocomplete]="AutofilteredAdmins">
                    <mat-error *ngIf="statusForm.controls.selectAdminControl.invalid">
                      Please select a user
                    </mat-error>
                  <mat-autocomplete autoActiveFirstOption #AutofilteredAdmins="matAutocomplete" [displayWith]="displayFn(admins)"
                    (optionSelected)="selectUser($event.option.value,admins)">
                    <mat-option *ngFor="let admin of filteredAdmins | async" [value]="admin.id">
                      {{admin.firstName}} {{admin.lastName}} <b *ngIf="admin.employeeId">({{admin.employeeId}})</b>
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div> -->
              <!-- <div [hidden]="true">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Check-in To</mat-label>
                  <input  type="text" placeholder="Select Responsible" matInput name="selectAdminControl" formControlName="selectAdminControl"
                   >

                  <mat-autocomplete autoActiveFirstOption #AutofilteredAdmins="matAutocomplete" [displayWith]="displayFn(admins)"
                    (optionSelected)="selectUser($event.option.value,admins)">
                    <mat-option *ngFor="let admin of filteredAdmins | async" [value]="admin.id">
                      {{admin.firstName}} {{admin.lastName}} <b *ngIf="admin.employeeId">({{admin.employeeId}})</b>
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div> -->
              <div [hidden]="ItemDetails.totalQuantity == 1" class="col-lg-12 form-group">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Qty</mat-label>
                  <input type="number" required placeholder="Select Qty" matInput name="qtyControl" formControlName="qtyControl" min="1"
                  >
                  <mat-error *ngIf="statusForm.controls.qtyControl.value == 0 && statusForm.controls.qtyControl.touched">
                    Please provide valid quantity
                  </mat-error>
                </mat-form-field>
              </div>
              <div [hidden]="statusForm.controls.selectActionControl.value=='CheckIn'" class="col-lg-12 form-group">
                <mat-form-field class="example-form-field" appearance="outline">
                    <mat-label>Location</mat-label>
                    <mat-select  required formControlName="selectLocationControl" name="selectLocationControl">
                      <mat-option *ngFor="let employeeLocation of employeeLocations" [value]="employeeLocation">
                        {{employeeLocation}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="statusForm.controls.selectLocationControl.touched && statusForm.controls.selectLocationControl.invalid">
                      Please provide valid Employee Location. (if no location found, add it from user edit form)
                    </mat-error>
                </mat-form-field>
              </div>
              <div [hidden]="statusForm.controls.selectActionControl.value=='CheckOut'" class="col-lg-12 form-group">
                <mat-form-field class="example-form-field" appearance="outline">
                    <mat-label>Location</mat-label>
                    <mat-select  required formControlName="selectDplLocationControl" name="selectDplLocationControl">
                      <mat-option *ngFor="let dplLocation of dplLocations" [value]="dplLocation.name">
                        {{dplLocation.name}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="statusForm.controls.selectDplLocationControl.touched && statusForm.controls.selectDplLocationControl.invalid">
                      Please provide valid Location.
                    </mat-error>
                </mat-form-field>
              </div>

              <div class="col-lg-12 form-group">
                <mat-form-field appearance="outline" class="example-form-field">
                  <mat-label>Expiry</mat-label>
                  <input matInput [matDatepicker]="expiryDate" formControlName="expiryDateControl" name="expiryDateControl">
                  <mat-error *ngIf="statusForm.controls.expiryDateControl.invalid">
                    Please provide valid CheckOut Date
                  </mat-error>
                  <mat-datepicker-toggle matSuffix [for]="expiryDate"></mat-datepicker-toggle>
                  <mat-datepicker #expiryDate>
                    <mat-datepicker-actions>
                      <button mat-button matDatepickerCancel>Cancel</button>
                      <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                    </mat-datepicker-actions>
                  </mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-lg-12 form-group">
                <mat-form-field appearance="outline" class="example-form-field">
                  <mat-label>{{statusForm.controls.selectActionControl.value=='CheckIn' ? "Check-in":"Check-out"}} Date</mat-label>
                  <input matInput [matDatepicker]="actionDate" formControlName="actionDateControl" name="actionDateControl" [max]="today">
                  <mat-error *ngIf="statusForm.controls.actionDateControl.invalid">
                    Please provide valid Date
                  </mat-error>
                  <mat-datepicker-toggle matSuffix [for]="actionDate" ></mat-datepicker-toggle>
                  <mat-datepicker #actionDate>
                    <mat-datepicker-actions>
                      <button mat-button matDatepickerCancel>Cancel</button>
                      <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                    </mat-datepicker-actions>
                  </mat-datepicker>
                </mat-form-field>
              </div>







              <div class="col-lg-12 form-group upload-controls upload-controls-field">
                <mat-form-field class="example-form-field" >
                  <mat-label>Upload Image/Video/PDF
                  </mat-label>
                  <div>
                    <mat-toolbar>
                      <input matInput formControlName="uploadFileControlText" readonly name="uploadFileControlText" multiple value="Choose file" />

                      <button mat-flat-button class="browsebtn" color="primary">
                        Browse File
                      </button>
                    </mat-toolbar>
                    <input  type="file" #fileInput id="uploadFile" formControlName="uploadFileControl" (change)="uploadFileEvt($event)" name="uploadFileControl"
                      multiple="multiple" accept=".pdf, .jpg, .jpeg, .png, .gif, .bmp, .mp4, .mov, .avi, .wmv, .flv" />
                  </div>
                </mat-form-field>
                <mat-chip-list #chipList aria-label="Image selection">
                  <mat-chip *ngFor="let file of filesToUpload" (removed)="OnImageDeselect(file)">
                    {{file.name}}
                    <button matChipRemove>
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip>
                </mat-chip-list>
              </div>
              <div class="col-lg-12 form-group upload-controls" style="margin-top:5px">
                <mat-form-field class="example-form-field" appearance="outline">
                  <mat-label>Notes</mat-label>
                    <textarea class="notes-textarea" matInput formControlName="notesControl" rows="2" name="notesControl" placeholder="Notes"></textarea>

                </mat-form-field>
              </div>
            </div>
          </div>
          </div>
        </div>
          <div class="form-button-actions">
            <div class="col-lg-12 form-group" >
              <button  type="submit" [disabled]="isLoading" class="tabs-actions-button" mat-raised-button color="primary" mat-button>Save</button>
            </div>
        </div>
          </form>
        </mat-tab>

<!-- Manager View -->
        <mat-tab *ngIf="IsManagerUser" [disabled]="!ItemDetails.isItemCreatedByManager">
          <ng-template mat-tab-label >
            Status
          </ng-template>

          <form name="statusForm" [formGroup]="statusForm"  (ngSubmit)="save()" novalidate >
            <div class="details-tabs-content-main">
            <div class="details-tabs-content">
              <div class="details-tabs-content-scroll">
            <div class="items-details-status">
             <div class="col-lg-12 form-group">
              <label class="checkout-label">{{ItemDetails.name}}: {{statusForm.controls.selectActionControl.value == "CheckIn" ? 'Check-in':'Check-out'}}</label>
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Action</mat-label>
                  <mat-select required formControlName="selectActionControl" (selectionChange)="updateValidationRules()" name="selectActionControl">
                    <mat-option value="CheckOut"
                    >
                      Check-out
                    </mat-option>
                    <mat-option value="CheckIn"

                    >
                      Check-in
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div [hidden]="statusForm.controls.selectActionControl.value=='CheckIn'" class="col-lg-12 form-group">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label> Check-out To</mat-label>
                  <input required type="text" placeholder="Select User" matInput name="selectUserControl" formControlName="selectUserControl"
                    [matAutocomplete]="auto">
                    <mat-error *ngIf="statusForm.controls.selectUserControl.invalid">
                      Please select a user
                    </mat-error>
                  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn(employees)"
                    (optionSelected)="selectUser($event.option.value,employees)">
                    <mat-option *ngFor="let employee of filteredEmployees | async" [value]="employee.id">
                      {{employee.firstName}} {{employee.lastName}} <b *ngIf="employee.employeeId">({{employee.employeeId}})</b>
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>

              <div [hidden]="statusForm.controls.selectActionControl.value=='CheckOut'" class="col-lg-12 form-group">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Check-in From</mat-label>
                  <input required type="text" placeholder="Select User" matInput name="checkOutFromUserControl" formControlName="checkOutFromUserControl"
                    [matAutocomplete]="checkOutFromUserControlMatAutocomplete">
                    <mat-error *ngIf="statusForm.controls.checkOutFromUserControl.invalid">
                      Please select a user
                    </mat-error>
                  <mat-autocomplete autoActiveFirstOption #checkOutFromUserControlMatAutocomplete="matAutocomplete" [displayWith]="displayCheckOuts(itemCheckOuts)"
                    (optionSelected)="selectUser($event.option.value,employees)">
                    <mat-option *ngFor="let checkOutItem of filtereditemCheckOuts | async" [value]="checkOutItem.id">
                      {{checkOutItem.issuedToUser.firstName}} {{checkOutItem.issuedToUser.lastName}}
                       <span *ngIf="checkOutItem.issuedToUser.employeeId">({{checkOutItem.issuedToUser.employeeId}})</span>&nbsp;
                       <b>|</b>&nbsp;{{checkOutItem.actionDate | date:'dd/MM/YYY'}} <b>|</b>
                       Qty: {{checkOutItem.qtyChanged}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>

               <!-- <div [hidden]="statusForm.controls.selectActionControl.value=='CheckOut'" class="col-lg-12 form-group">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Check-in To</mat-label>
                  <input  type="text" placeholder="Select Responsible" matInput name="selectAdminControl" formControlName="selectAdminControl"
                    [matAutocomplete]="AutofilteredAdmins">
                    <mat-error *ngIf="statusForm.controls.selectAdminControl.invalid">
                      Please select a user
                    </mat-error>
                  <mat-autocomplete autoActiveFirstOption #AutofilteredAdmins="matAutocomplete" [displayWith]="displayFn(admins)"
                    (optionSelected)="selectUser($event.option.value,admins)">
                    <mat-option *ngFor="let admin of filteredAdmins | async" [value]="admin.id">
                      {{admin.firstName}} {{admin.lastName}} <b *ngIf="admin.employeeId">({{admin.employeeId}})</b>
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div> -->
              <!-- <div [hidden]="true">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Check-in To</mat-label>
                  <input  type="text" placeholder="Select Responsible" matInput name="selectAdminControl" formControlName="selectAdminControl"
                   >

                  <mat-autocomplete autoActiveFirstOption #AutofilteredAdmins="matAutocomplete" [displayWith]="displayFn(admins)"
                    (optionSelected)="selectUser($event.option.value,admins)">
                    <mat-option *ngFor="let admin of filteredAdmins | async" [value]="admin.id">
                      {{admin.firstName}} {{admin.lastName}} <b *ngIf="admin.employeeId">({{admin.employeeId}})</b>
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div> -->
              <div [hidden]="ItemDetails.totalQuantity == 1" class="col-lg-12 form-group">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Qty</mat-label>
                  <input type="number" required placeholder="Select Qty" matInput name="qtyControl" formControlName="qtyControl" min="1"
                  >
                  <mat-error *ngIf="statusForm.controls.qtyControl.value == 0 && statusForm.controls.qtyControl.touched">
                    Please provide valid quantity
                  </mat-error>
                </mat-form-field>
              </div>
              <div [hidden]="statusForm.controls.selectActionControl.value=='CheckIn'" class="col-lg-12 form-group">
                <mat-form-field class="example-form-field" appearance="outline">
                    <mat-label>Location</mat-label>
                    <mat-select  required formControlName="selectLocationControl" name="selectLocationControl">
                      <mat-option *ngFor="let employeeLocation of employeeLocations" [value]="employeeLocation">
                        {{employeeLocation}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="statusForm.controls.selectLocationControl.touched && statusForm.controls.selectLocationControl.invalid">
                      Please provide valid Employee Location. (if no location found, add it from user edit form)
                    </mat-error>
                </mat-form-field>
              </div>
              <div [hidden]="statusForm.controls.selectActionControl.value=='CheckOut'" class="col-lg-12 form-group">
                <mat-form-field class="example-form-field" appearance="outline">
                    <mat-label>Location</mat-label>
                    <mat-select  required formControlName="selectDplLocationControl" name="selectDplLocationControl">
                      <mat-option *ngFor="let dplLocation of dplLocations" [value]="dplLocation.name">
                        {{dplLocation.name}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="statusForm.controls.selectDplLocationControl.touched && statusForm.controls.selectDplLocationControl.invalid">
                      Please provide valid Location.
                    </mat-error>
                </mat-form-field>
              </div>

              <div class="col-lg-12 form-group">
                <mat-form-field appearance="outline" class="example-form-field">
                  <mat-label>Expiry</mat-label>
                  <input matInput [matDatepicker]="expiryDate" formControlName="expiryDateControl" name="expiryDateControl">
                  <mat-error *ngIf="statusForm.controls.expiryDateControl.invalid">
                    Please provide valid CheckOut Date
                  </mat-error>
                  <mat-datepicker-toggle matSuffix [for]="expiryDate"></mat-datepicker-toggle>
                  <mat-datepicker #expiryDate>
                    <mat-datepicker-actions>
                      <button mat-button matDatepickerCancel>Cancel</button>
                      <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                    </mat-datepicker-actions>
                  </mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-lg-12 form-group">
                <mat-form-field appearance="outline" class="example-form-field">
                  <mat-label>{{statusForm.controls.selectActionControl.value=='CheckIn' ? "Check-in":"Check-out"}} Date</mat-label>
                  <input matInput [matDatepicker]="actionDate" formControlName="actionDateControl" name="actionDateControl" [max]="today">
                  <mat-error *ngIf="statusForm.controls.actionDateControl.invalid">
                    Please provide valid Date
                  </mat-error>
                  <mat-datepicker-toggle matSuffix [for]="actionDate" ></mat-datepicker-toggle>
                  <mat-datepicker #actionDate>
                    <mat-datepicker-actions>
                      <button mat-button matDatepickerCancel>Cancel</button>
                      <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                    </mat-datepicker-actions>
                  </mat-datepicker>
                </mat-form-field>
              </div>







              <div class="col-lg-12 form-group upload-controls upload-controls-field">
                <mat-form-field class="example-form-field" >
                  <mat-label>Image</mat-label>
                  <div>
                    <mat-toolbar>
                      <input matInput formControlName="uploadFileControlText" readonly name="uploadFileControlText" multiple value="Choose file" />

                      <button mat-flat-button class="browsebtn" color="primary">
                        Browse File
                      </button>
                    </mat-toolbar>
                    <input  type="file" #fileInput id="uploadFile" formControlName="uploadFileControl" (change)="uploadFileEvt($event)" name="uploadFileControl"
                      multiple="multiple" accept="image/*" />
                  </div>
                </mat-form-field>
                <mat-chip-list #chipList aria-label="Image selection">
                  <mat-chip *ngFor="let file of filesToUpload" (removed)="OnImageDeselect(file)">
                    {{file.name}}
                    <button matChipRemove>
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip>
                </mat-chip-list>
              </div>
              <div class="col-lg-12 form-group upload-controls">
                <mat-form-field class="example-form-field" appearance="outline">
                  <mat-label>Notes</mat-label>
                    <textarea class="notes-textarea" matInput formControlName="notesControl" rows="2" name="notesControl" placeholder="Notes"></textarea>

                </mat-form-field>
              </div>
            </div>
          </div>
          </div>
        </div>
          <div class="form-button-actions">
            <div class="col-lg-12 form-group" >
              <button  type="submit" [disabled]="isLoading" class="tabs-actions-button" mat-raised-button color="primary" mat-button>Save</button>
            </div>
        </div>
          </form>
        </mat-tab>


      </mat-tab-group>
    </div>
  </div>
</div>
