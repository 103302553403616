import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable(
//   {
//   providedIn: 'root'
// }
)
export class DataShareService {


  public Currencies:string[]=['PKR','USD','GBP','CNY','EUR','JPY','AUD','CAD','INR','AED','SAR','MYR']

  private messageSource = new BehaviorSubject(0);
  currentMessage = this.messageSource.asObservable();

  private SubCategoryIDRes = new BehaviorSubject(0);
  SubCategoryID= this.SubCategoryIDRes.asObservable();

  private ItemDeleteRes = new BehaviorSubject(0);
  ItemDelete= this.ItemDeleteRes.asObservable();

  constructor(    private http: HttpClient,
    ) { }

  changeCategoryId(categoryId: number) {
    this.messageSource.next(categoryId)
  }
  changeSubCategoryId(SubcategoryId: number) {
    this.SubCategoryIDRes.next(SubcategoryId)
  }

  ChangeDeleteItem(id: number) {
    this.ItemDeleteRes.next(id)
  }


}
