import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { UserAccessPermissions } from 'src/app/Models/AccessPermissions';
import { AlertModel } from 'src/app/Models/ComponentModels/AlertModel';
import { UserModel } from 'src/app/Models/ComponentModels/UserModel';
import { AlertService } from 'src/app/Services/alert.service';
import { ItemService } from 'src/app/Services/component-services/item.service';
import { UserService } from 'src/app/Services/component-services/user-service';
import { StorageService } from 'src/app/Services/storage-service.service';

@Component({
  selector: 'app-summary-alerts',
  templateUrl: './summary-alerts.component.html',
  styleUrls: ['./summary-alerts.component.scss']
})
export class SummaryAlertsComponent implements OnInit {
  isLoading = false;
  dateRange = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  usersCtrl = new FormControl();
  filteredUsers: Observable<UserModel[]>;
  selectedUsers: UserModel[]= new Array<UserModel>();

   tableData: MatTableDataSource<UserModel> = new MatTableDataSource();

  Frequency = '';
  AllUsers: UserModel[] = new Array<UserModel>();
  existingAlertData: AlertModel;

  displayedColumns2: string[] = ['User','Email','Actions'];
  userAccessPermission:UserAccessPermissions

  constructor(
    private _userService:UserService,
    private _itemService:ItemService,
    private _alertService:AlertService,
    private _snackBar:MatSnackBar,
    private _storageService:StorageService,

    ) {
      this.userAccessPermission=new UserAccessPermissions();

     }


  ngOnInit(): void {


    let getAlertCall = this._alertService.getAlert();
    let getallAdminsCall = this._userService.getallAdmins();

    forkJoin([getAlertCall, getallAdminsCall]).subscribe(results  => {
      // users processing

      this.AllUsers = results[1].data;

      this.AllUsers.forEach(function(item){
        item.name = item.firstName + " " + item.lastName;
      });

      this.filteredUsers = this.usersCtrl.valueChanges.pipe(
        startWith(null),
        map((user => this._filter(user))),
      );

      // alerts processing

      if(results[0].data){

        this.existingAlertData = results[0].data;
        this.Frequency = results[0].data.frequency;

        results[0].data.commaSeparatedEmails.split(',').forEach((emailAddress)=>{
          var user = this.AllUsers.find(u => u.email == emailAddress);
          if(user){
            this.selectedUsers.push(user);
          }

        });

        this.tableData.data = this.selectedUsers
      }
    });
    this.GetUserAccessPermission()
  }

   add(event: MatChipInputEvent): void {
    const value:any = event.value;

    // Add our user
    if (value) {
      this.selectedUsers.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.usersCtrl.setValue(null);
  }

  remove(user: UserModel): void {
    const index = this.selectedUsers.indexOf(user);

    if (index >= 0) {
      this.selectedUsers.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    var selectedUser = event.option.value;
    this.selectedUsers.push(selectedUser);
    this.userInput.nativeElement.value = '';

    this.usersCtrl.setValue(null);

    this.userInput.nativeElement.blur();

  }

  private _filter(value: string): UserModel[] {
    if(value && typeof(value)=='string' ){

      var users= this.AllUsers.filter(user => user.name.toLowerCase().includes(value.toLowerCase())
      );
       return users;
    }else{

      return this.AllUsers;
    }
  }
  deleteUser(user:UserModel){
    if(user){
      this._alertService.deleteUserFromAlert(user.email).subscribe(x => {
        // remove from table data and selected auto complete

      this.selectedUsers = this.selectedUsers.filter(function(el) { return el.email != user.email; });
      this.tableData.data = this.tableData.data.filter(function(el) { return el.email != user.email; });

        this._snackBar.open('User removed from alerts successfully.');

      }, (response) => {
        this._snackBar.open(response.Message || response.error || "Something went wrong. Please try again");
        this.isLoading = false;
      });
    }
  }
  saveAlert(form: NgForm){

    if (form.valid && this.Frequency && this.selectedUsers) {
      this.isLoading = true;

      var formatedStartDate = '';
      var formatedEndDate = '';

      formatedStartDate = this.dateRange.controls["start"].value;
      formatedEndDate = this.dateRange.controls["end"].value;

      var dataToSend = {
        Frequency: this.Frequency,
        userEmails: this.selectedUsers.map(function (user) { return user.email }),
        startDate: new Date(formatedStartDate).toLocaleDateString("en-us"),
        endDate: new Date(formatedEndDate).toLocaleDateString("en-us")
      }


      this._alertService
        .CreateAlert(
          this.Frequency,
          dataToSend.userEmails,
          dataToSend.startDate,
          dataToSend.endDate
        )
        .subscribe(
          (x) => {
            this._snackBar.open('Alert created successfully!');
            this.tableData.data = this.selectedUsers;
            this.isLoading = false;

            // this.selectedUsers = new Array<UserModel>();
          },
          (response) => {
            this._snackBar.open(
              response.Message ||
                response.error ||
                'Something went wrong. Please try again'
            );
            this.isLoading = false;
          }
        );

    }else{
      this._snackBar.open("Frequency and User field is required.");
    }
  }

GetUserAccessPermission()
{
 this.userAccessPermission=this._storageService.GetLoginUserPermissions()
}
}
