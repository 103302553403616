import { Component, OnInit } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { map, startWith } from 'rxjs/operators';
import { UserModel } from 'src/app/Models/ComponentModels/UserModel';
import { LocationService } from 'src/app/Services/component-services/location-service';
import { UserService } from 'src/app/Services/component-services/user-service';

@Component({
  selector: 'app-locations-add',
  templateUrl: './locations-add.component.html',
  styleUrls: ['./locations-add.component.scss']
})
export class LocationsAddComponent implements OnInit {

  isLoading:boolean = false;
  locationName = "";
  locationAddress = "";
  Phone = "";
  responsibleUserId = "";

  admins: UserModel[] = new Array<UserModel>();
  adminsFilter: Observable<UserModel[]>;

  responsibleUserControl = new FormControl();
  addressControl = new FormControl();

  constructor(
    private _userService: UserService,
    private _locationService: LocationService,
    private _snackBar: MatSnackBar,
    private _router: Router
    ) { }

  ngOnInit(): void {
    this._userService.getallAdmins().subscribe((x) => {
      this.admins = x.data;
      this.adminsFilter = this.responsibleUserControl.valueChanges.pipe(
        startWith(''),
        map(value => this.responsible_user_filter(value)
      ));
    });
  }

  displayFn(options: UserModel[]): (id: string) => string {
    return (id: string) => {

      const correspondingOption = Array.isArray(this.admins) ? this.admins.find(option => option.id === id) : null;
      return correspondingOption ? correspondingOption.firstName + ' '  + correspondingOption.lastName || '': '';
    }
  }
  private responsible_user_filter(value: string): UserModel[] {
    const filterValue = value.toLowerCase();
    return this.admins.filter(option => option.firstName.toLowerCase().startsWith(filterValue) || option.lastName.toLowerCase().startsWith(filterValue) );
  }

  saveLocation(form: NgForm){
    if (form.valid) {
      var data = new FormData();
      data.append('Name', this.locationName);
      data.append('Address', this.locationAddress);
      data.append('Phone', this.Phone);
      data.append('ResponsibleUserId', this.responsibleUserId);

      this._locationService.saveLocation(data).subscribe((x)=>{

        this._snackBar.open('Location added successfully!');
        this._router.navigate(['/home/locations']);
      }, (response) => {
        this._snackBar.open(response.error.Message);
      });
    }
  }
}
