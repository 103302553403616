import { Component, OnInit } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { StorageService } from 'src/app/Services/storage-service.service';
import { UserAccessPermissions } from 'src/app/Models/AccessPermissions';
import { ExampleFlatNode, SideBarNode } from './Interface/SideBarNode';




var TREE_DATA_Employee: SideBarNode[] = [
  {
    name: 'Items',
    sidebaricon: 'home',
    link: 'home/items',
    children: [
      { name: 'Items List',  link: '/home/items', sidebaricon: 'home' },

    ]
  }
];
var TREE_DATA_Manager: SideBarNode[] = [
  {
    name: 'Items',
    sidebaricon: 'home',
    link: 'home/items',
    children: [
      { name: 'Items List',  link: '/home/items', sidebaricon: 'home' },
      { name: 'Add Item', link: '/home/item/add', sidebaricon: 'home' }

    ]
  }
];

var TREE_DATA_Permissions: SideBarNode[]=[
  {
    name: 'Items',
    sidebaricon: 'home',
    link: 'home/items',
    children: [
      { name: 'Items List',  link: '/home/items', sidebaricon: 'home' },
      { name: 'Add Item', link: '/home/item/add', sidebaricon: 'home' },
      { name: 'Import Items', link: '/home/item/import', sidebaricon: 'home' }
    ]
  },

  {
    name: 'Reports',
    sidebaricon: 'pie_chart',
    link: '/user-report',
    children: [
      { name: 'Activity Report', link: 'user-report', sidebaricon: 'home' },
      { name: 'Active Check-outs', link: 'active-checkouts-report', sidebaricon: 'home' }
    ]
  },

  {
    name: 'Maintenance',
    sidebaricon: 'settings', link: 'maintenance/locations',
    children: [
      { name: 'Locations List', link: '/home/locations', sidebaricon: 'home' },
      { name: 'Add Location', link: '/home/location/add', sidebaricon: 'home' },
      { name: 'Categories', link: '/home/category', sidebaricon: 'home' },
      { name: 'Access Groups', link: '/home/access-groups', sidebaricon: 'home' },
      { name: 'Add Access Group', link: '/home/add-access-groups', sidebaricon: 'home' },
      { name: 'Summary Email', link: '/home/add-alert', sidebaricon: 'home' },

    ]
  },
  {
    name: 'User Management',
    sidebaricon: 'person',
    link: '/home/users',
    children: [
      { name: 'Users List', link: '/home/users', sidebaricon: 'home' },
      { name: 'Add User', link: '/home/user/add', sidebaricon: 'home' }
    ]
  }

];


@Component({
  selector: 'app-sidebarnav',
  templateUrl: './sidebarnav.component.html',
  styleUrls: ['./sidebarnav.component.sass']
})
export class SidebarnavComponent implements OnInit {

  IsEmployeeUser:boolean=false;
  IsManagerUser:boolean=false;
  userAccessPermission:UserAccessPermissions

  constructor(
             private storageService: StorageService,
             public _storageService: StorageService,
             ) {
    /**
     * Checking if user is super admin, then show him all links otherwise
     * hide some links
     *
     * basically access group feature is only meant for super admin
     */
    const currentUser = this.storageService.GetLoggedInUser();

    this.IsEmployeeUser= this._storageService.IsLoginUserEmployee();
    this.IsManagerUser=this._storageService.IsLoginUserManager();

    this.userAccessPermission=new UserAccessPermissions();
    this.userAccessPermission=this._storageService.GetLoginUserPermissions()


// ! If Login User is Employee Then Only Show List of Items
    if(this.IsEmployeeUser){
      this.dataSource.data=TREE_DATA_Employee

    }
    else if(this.IsManagerUser)
    {
      this.dataSource.data=TREE_DATA_Manager

    }
    else{
      this.AddOrRemoveFeatures()
      this.dataSource.data = TREE_DATA_Permissions;
    }



  }

  ngOnInit(): void {


  }

  // checkLoggedInUser(node: SideBarNode) {
  //   if (node.superAdminOnly == null) {
  //     return true;
  //   } else {
  //     const currentUser = this.storageService.GetLoggedInUser();
  //     if (!currentUser.roles.find(x => x == "SuperAdmin")) {
  //       return true;
  //     } else {
  //       return true;
  //     }
  //   }
  // }
  private _transformer = (node: SideBarNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      link: node.link,
      sidebaricon: node.sidebaricon
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;



  AddOrRemoveFeatures()
  {


    // ! Items
       if(!this.userAccessPermission.readItem)
       {
        TREE_DATA_Permissions=this.RemoveMainFeature(TREE_DATA_Permissions,'Items')
       }
       else{
        TREE_DATA_Permissions.filter(x=>{
          if(x.name=='Items'){
            if(!this.userAccessPermission.createItem){
              x.children=x.children?.filter(c=>c.name!='Add Item')
            }
            if(!this.userAccessPermission.itemImport){
              x.children=x.children?.filter(c=>c.name!='Import Items')
            }

          }
        })
       }

        // ! Reports
        if(!this.userAccessPermission.activeCheckoutReportRead && !this.userAccessPermission.activityReportRead)
        {
         TREE_DATA_Permissions=this.RemoveMainFeature(TREE_DATA_Permissions,'Reports')
        }
        else{
         TREE_DATA_Permissions.filter(x=>{
           if(x.name=='Reports'){
             if(!this.userAccessPermission.activeCheckoutReportRead){
               x.children=x.children?.filter(c=>c.name!='Active Check-outs')
             }
             if(!this.userAccessPermission.activityReportRead){
               x.children=x.children?.filter(c=>c.name!='Activity Report')
             }

           }
         })
        }

         // ! Maintenance
         if(
           !this.userAccessPermission.readLocation &&
           !this.userAccessPermission.createLocation &&
           !this.userAccessPermission.readCategorySubCategory &&
           !this.userAccessPermission.readAccessGroup &&
           !this.userAccessPermission.createAccessGroup &&
           !this.userAccessPermission.readSummeryEmail

          )
         {
          TREE_DATA_Permissions=this.RemoveMainFeature(TREE_DATA_Permissions,'Maintenance')
         }
         else{

          TREE_DATA_Permissions.filter(x=>{
            if(x.name=='Maintenance'){
              if(!this.userAccessPermission.readLocation){
                x.children=x.children?.filter(c=>c.name!='Locations List')
              }
              if(!this.userAccessPermission.createLocation){
                x.children=x.children?.filter(c=>c.name!='Add Location')
              }
              if(!this.userAccessPermission.readCategorySubCategory){
                x.children=x.children?.filter(c=>c.name!='Categories')
              }
              if(!this.userAccessPermission.readAccessGroup){
                x.children=x.children?.filter(c=>c.name!='Access Groups')
              }
              if(!this.userAccessPermission.createAccessGroup){
                x.children=x.children?.filter(c=>c.name!='Add Access Group')
              }
              if(!this.userAccessPermission.readSummeryEmail){
                x.children=x.children?.filter(c=>c.name!='Summary Email')
              }


            }
          })
         }

          // ! User Management
       if(!this.userAccessPermission.readUser && !this.userAccessPermission.createUser)
       {
        TREE_DATA_Permissions=this.RemoveMainFeature(TREE_DATA_Permissions,'User Management')
       }
       else{
        TREE_DATA_Permissions.filter(x=>{
          if(x.name=='User Management'){
            if(!this.userAccessPermission.readUser){
              x.children=x.children?.filter(c=>c.name!='Users List')
            }
            if(!this.userAccessPermission.createUser){
              x.children=x.children?.filter(c=>c.name!='Add User')
            }

          }
        })
       }


  }


  RemoveMainFeature(FeatureArray: SideBarNode[],featureName:string)
  {
    FeatureArray.forEach((element,index)=>{
      if(element.name==featureName) delete FeatureArray[index];
   });
   return FeatureArray;
  }


}








