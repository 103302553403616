import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { AppConstants, AppURLs } from '../AppConstants';
import { APIResponseNonPagedSingle } from '../Models/API-Response-NonPagedSingle';
import { ApiSingleResponse } from '../Models/API-Response-Single';
import { AlertModel } from '../Models/ComponentModels/AlertModel';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  baseURL = environment.ApiUrl;
  URLs: AppURLs;
  AlertServiceURL: string;

  constructor(private _http: HttpClient) {
    this.URLs = new AppURLs(AppConstants.APIVersion);
    this.AlertServiceURL = environment.apiBaseUrl + this.URLs.Alert;
  }

  public CreateAlert(frequency:string, emailAddresses:string[], startDate:string, endDate:string):Observable<any>{
    var data = {
      frequency:frequency,
      emailAddresses:emailAddresses,
      startTime:startDate,
      endTime:endDate
    };
    return this._http.post<any>(this.AlertServiceURL,data);
  }
  getAlert():Observable<APIResponseNonPagedSingle<AlertModel>>{
    return this._http.get<APIResponseNonPagedSingle<AlertModel>>(this.AlertServiceURL);
  }
  deleteUserFromAlert(userEmail:string){
    return this._http.delete<APIResponseNonPagedSingle<boolean>>(this.AlertServiceURL + "/DeleteUserFromAlert?EmailAddress=" + userEmail);
  }
}
