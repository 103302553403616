<mat-progress-bar [ngStyle]="{'visibility':isLoading ==true ? 'visible' : 'hidden'}" color="primary"
  mode="indeterminate">
</mat-progress-bar>
<form name="ItemForm" #ItemForm="ngForm" (submit)="saveAlert(ItemForm)" novalidate>
  <div class="col-lg-11 components-block">

    <div class="components-header" [hidden]="!userAccessPermission.createSummeryEmail">
      <h2>Create Alert</h2>
      <button type="submit" mat-raised-button color="primary" [disabled]="isLoading" mat-button
        class="save-btn" >Save</button>
    </div>
    <div class="components-form">

      <div class="components-vertical-tab">
        <div class="components-vertical-link">
          <ul>
            <li><a>Create Alert</a></li>
          </ul>
        </div>
        <div class="components-vertical-content">

          <div class="item-information-block">
            <div class="row">
              <div class="item-row">
                <div class="col-lg-12 form-group form-frequency-group">
                  <mat-label>Select Frequency:</mat-label>
                  <br />
                  <mat-radio-group [required]="true" name="Frequency" aria-label="Select an option"
                    [(ngModel)]="Frequency">
                    <mat-radio-button value="Weekly">Weekly</mat-radio-button>
                    <mat-radio-button style="margin-left: 15px;" value="Monthly">Monthly</mat-radio-button>
                  </mat-radio-group>
                </div>

                <div class="col-lg-12 form-group form-select-user-group"  [hidden]="!userAccessPermission.createSummeryEmail">
                  <mat-form-field appearance="standard">
                    <mat-label>Select users</mat-label>
                    <mat-chip-list #chipList aria-label="users selection">
                      <mat-chip *ngFor="let user of selectedUsers" (removed)="remove(user)">
                        {{user.email}}
                        <button matChipRemove>
                          <mat-icon>cancel</mat-icon>
                        </button>
                      </mat-chip>
                      <input [required]="true" placeholder="Select user ..." #userInput [formControl]="usersCtrl"
                        [matAutocomplete]="auto" [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)">
                    </mat-chip-list>
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                      <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
                        {{user.firstName}} {{user.lastName}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
              </div>
            </div>

          </div>


          <div class="mat-table-block mat-table-scroll">
            <table mat-table [dataSource]="tableData" class="mat-elevation-z8 ">

              <ng-container matColumnDef="User">
                <th mat-header-cell *matHeaderCellDef> User </th>
                <td mat-cell *matCellDef="let element"> {{element.firstName}} {{element.lastName}}</td>
              </ng-container>

              <ng-container matColumnDef="Email">
                <th mat-header-cell *matHeaderCellDef> Email </th>
                <td mat-cell *matCellDef="let element"> {{element.email}}</td>
              </ng-container>

              <!-- Actions Column -->
              <ng-container matColumnDef="Actions">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let user">
                  <a matTooltip="Delete" (click)="deleteUser(user)" matTooltipClass="matTooltip-Custom" matTooltipPosition="above"
                    class="column-name-link"
                    [hidden]="!userAccessPermission.deleteSummeryEmail">
                    <mat-icon>delete</mat-icon>
                </a>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
