import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import { of } from 'rxjs';
import {UserModel} from '../../Models/ComponentModels/UserModel';
import { AppURLs } from 'src/app/AppConstants';
import { AppConstants } from 'src/app/AppConstants';
import { environment } from 'src/environments/environment';
import { ApiSingleResponse } from 'src/app/Models/API-Response-Single';
import { UserRole } from 'src/app/Models/ComponentModels/UserRole';
import { ApiPagedResponse } from 'src/app/Models/Api-Response-Paged';

import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { APIResponseNonPagedSingle } from 'src/app/Models/API-Response-NonPagedSingle';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  URLs:AppURLs;
  userServiceURL:string;

  constructor(private _http:HttpClient) {
    this.URLs = new AppURLs(AppConstants.APIVersion);

    this.userServiceURL = environment.apiBaseUrl + this.URLs.UsersURL;
   }
   public saveOrUpdateUser(user:UserModel):Observable<any> {
    return this._http.post<any>(this.userServiceURL +`/SaveOrUpdate`, user);
  }
  public getUsers(pageNo:Number, pageSize:Number, NameOrCodeFilter:string, userType:string, userRole:string):Observable<ApiPagedResponse<UserModel>> {
    return this._http.get<ApiPagedResponse<UserModel>>(this.userServiceURL + `?PageNumber=${pageNo}&PageSize=${pageSize}&NameOrCodeFilter=${NameOrCodeFilter}&userType=${userType}&userRole=${userRole}`);
  }
  public getUserById(Id:string):Observable<APIResponseNonPagedSingle<UserModel>> {
    return this._http.get<any>(this.userServiceURL + `/${Id}`);
  }
  getUserRoles():Observable<ApiSingleResponse<UserRole>>{
    return this._http.get<ApiSingleResponse<UserRole>>(this.userServiceURL+ `/GetRoles`);
  }
  searchUsers(filterString:string):Observable<ApiSingleResponse<UserModel>>{
    return this._http.get<ApiSingleResponse<UserModel>>(this.userServiceURL+ `/Search?FilterString=${filterString}`);
  }
  searchallUsers(filterString:string):Observable<ApiSingleResponse<UserModel>>{ // this will nclude data from AD as well.
    return this._http.get<ApiSingleResponse<UserModel>>(this.userServiceURL+ `/SearchAllUsers?FilterString=${filterString}`);
  }
  getallEmployees(getInactiveUsers?: boolean):Observable<ApiSingleResponse<UserModel>>{
    if(getInactiveUsers){
      var requestURL = this.userServiceURL + `/GetEmployees?getInactiveUsers=` + getInactiveUsers;
    }else{
      var requestURL = this.userServiceURL + `/GetEmployees?getInactiveUsers=` + false;
    }

    return this._http.get<ApiSingleResponse<UserModel>>(requestURL);
  }

  getallAdmins():Observable<ApiSingleResponse<UserModel>>{
    return this._http.get<ApiSingleResponse<UserModel>>(this.userServiceURL + `/GetAdmins`);
  }

  getallAdminsAndManagers():Observable<ApiSingleResponse<UserModel>>{
    return this._http.get<ApiSingleResponse<UserModel>>(this.userServiceURL + `/GetAdminsAndManagers`);
  }

  public pullADData():Observable<any> {
    return this._http.post<any>(this.userServiceURL +`/UpdateUsersData`,null);
  }

  public UpdateIMSAccess(user:UserModel):Observable<any> {
    return this._http.post<any>(this.userServiceURL +`/UpdateIMSAccess`, user);
  }

  public getLoginUserRoles(email:string):Observable<any> {
    return this._http.get<any>(this.userServiceURL +`/GetUserRoles/${email}`);
  }

}
