<mat-progress-bar [ngStyle]="{'visibility':isLoading==true ? 'visible' : 'hidden'}" color="primary"
  mode="indeterminate"></mat-progress-bar>

<div class="col-lg-12 components-block ">
  <div class="components-header ">
    <h2>Items List</h2>
    <div class="pulladdata-block">
      <button type="button" (click)="ExportToExcelListOfItems()" mat-stroked-button color="primary"
        class="pulldata-btn download-btn">Download Excel</button>
    </div>
  </div>
  <div class="filterbar-block">
    <div class="grid-filter-dropdown allFilter">
      <mat-form-field appearance="standard">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" [(ngModel)]="filterValue" placeholder="Name, Code" #input>
        <div class="filtericon">
          <mat-icon aria-hidden="false" aria-label="Example home icon">search</mat-icon>
        </div>
      </mat-form-field>
    </div>
    <div class="grid-tags-dropdown">
      <div class="grid-filter-dropdown form-tags-group">
        <!-- <mat-form-field appearance="standard">
        <mat-label>Tag</mat-label>
        <mat-select name="Tag" (selectionChange)="applyFilters()" [(ngModel)]="tagId">
          <mat-option value=0 >All</mat-option>
          <mat-option *ngFor="let tag of TagResponse" [value]="tag.tagId">
            {{tag.tagName}}
          </mat-option>
        </mat-select>
      </mat-form-field> -->

        <!-- <mat-form-field appearance="standard">
      <mat-label>Tag</mat-label>
      <mat-select name="Tag" >
        <mat-option value=0>
          <input type="checkbox" (change)="OnAllTagSelectToogle($event)" [(ngModel)]="allTagSelectToogle" >  All</mat-option>
        <mat-option  *ngFor="let tag of TagResponse" [value]="tag.tagId">
          <input type="checkbox" [value]="tag.tagId" (change)="OnTagChange()" [(ngModel)]="tag.isSelected" >
          {{tag.tagName}}
        </mat-option>
      </mat-select>
    </mat-form-field> -->
        <mat-form-field *ngIf="!IsEmployeeUser" class="example-chip-list" appearance="standard">
          <mat-label>Filter Tags</mat-label>
          <mat-chip-list #chipList aria-label="Fruit selection">
            <mat-chip *ngFor="let tag of tags" (removed)="remove(tag)">
              {{tag}}
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
            <input (keyup)="applyFilterOnTags()" placeholder="New Tag" #fruitInput [formControl]="tagCtrl"
              [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [(ngModel)]="tagNameSerch">
          </mat-chip-list>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option *ngFor="let tag of TagResponse" [value]="tag.tagId">
              {{tag.tagName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <!-- <mat-form-field appearance="standard">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input  [rangePicker]="picker"  >
        <input [(ngModel)]="startDateRangeValue" matStartDate placeholder="Start date">
        <input [(ngModel)]="endDateRangeValue" (dateChange)="populateItemsDataGrid()" matEndDate placeholder="End date">
      </mat-date-range-input>
      <mat-datepicker-toggle  matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker  #picker ></mat-date-range-picker>
    </mat-form-field> -->

      </div>

    </div>




    <!-- <div class="pulladdata-block">
      <button type="submit" (click)="pullAdData()" [class.spinner]="AdDataLoading" [disabled]="AdDataLoading"
        mat-raised-button color="primary" mat-button class="pulldata-btn">Pull AD Data</button>
    </div> -->

    <!-- <div class="pulladdata-block" >
      <button type="submit" (click)="applyFilters()" style="margin-top: 1px;margin-bottom: 15px;"
        mat-raised-button color="primary" mat-button class="pulldata-btn">Apply Filters</button>
    </div> -->
    <div class="filtersdata-block">
      <button *ngIf="filtersCount" type="button" (click)="clearall()" mat-stroked-button color="primary"
        class="clear-btn">
        Clear All
      </button>
      <button type="button" (click)="openFiltersSidePanel()" mat-stroked-button color="primary" class="more-filters">
        More Filters
        <span *ngIf="filtersCount> 0">{{filtersCount}} Selected</span>
      </button>

    </div>
  </div>
  <!-- Material Table -->

  <div class="mat-table-block">
    <table mat-table matSort (matSortChange)="SortChange($event)" [dataSource]="itemsData">

      <!--**** Name ****-->
      <!-- <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef style="width:150px; text-align: left;" mat-sort-header
          sortActionDescription="Name">Name </th>
        <td mat-cell *matCellDef="let item" style="width:150px; text-align: left;"><button
            (click)="openRightDrawer(item,0)" class="column-name-link" style="text-align: left;">{{item.name}}</button>
        </td>
      </ng-container> -->
      <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef style="width:120px; text-align: left;" mat-sort-header
          sortActionDescription="Name">Name </th>
        <td mat-cell *matCellDef="let item" style="width:120px; text-align: left;"><button
            (click)="openRightDrawer(item,0)" class="column-name-link" style="text-align: left;">{{item.name}}</button>
        </td>
      </ng-container>

      <!--**** Code ****-->
      <ng-container matColumnDef="Code">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;" mat-sort-header sortActionDescription="Code">
          Code </th>
        <td mat-cell *matCellDef="let item" style="text-align: center;"> {{item.code}} </td>
      </ng-container>

      <!-- <ng-container matColumnDef="ResponsibleUser">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="ResponsibleUser"> Responsible </th>
        <td mat-cell *matCellDef="let item"> {{item.responsibleUser}} </td>
      </ng-container> -->

      <!--**** Quantity ****-->
      <ng-container matColumnDef="Quantity">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;" mat-sort-header
          sortActionDescription="Quantity"> Quantity </th>
        <td mat-cell *matCellDef="let item" style="text-align: center;"> {{item.quantity}} / {{item.totalQuantity}}</td>
      </ng-container>

      <!--**** Location ****-->
      <ng-container matColumnDef="Location">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Location"> Location </th>
        <td mat-cell *matCellDef="let item"> {{item.location}}</td>
      </ng-container>

      <!--**** Category ****-->
      <ng-container matColumnDef="Category">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;" mat-sort-header
          sortActionDescription="Category"> Category </th>
        <td mat-cell *matCellDef="let item" style="text-align: center;"> {{item.category}} </td>
      </ng-container>

      <!--**** Sub-category ****-->
      <ng-container matColumnDef="SubCategory">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;" mat-sort-header
          sortActionDescription="SubCategory"> Sub-category </th>
        <td mat-cell *matCellDef="let item" style="text-align: center;"> {{item.subCategory}} </td>
      </ng-container>
      <!--**** Tags ****-->
      <!-- <ng-container matColumnDef="Tags">
  <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Tags">Tags</th>
  <td mat-cell *matCellDef="let item">
    <li *ngFor="let t of item.itemTagsView ">#{{t.tagName}}</li>
  </td>
</ng-container> -->
      <!--**** Date Created ****-->
      <ng-container matColumnDef="Created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Created">Date Created</th>
        <td mat-cell *matCellDef="let item"> {{item.created | date:'dd/MM/YYY' }} </td>
      </ng-container>
      <!--**** Status ****-->
      <ng-container matColumnDef="Status" )>
        <th mat-header-cell *matHeaderCellDef style="text-align: center; " mat-sort-header
          sortActionDescription="Status"> Status </th>
        <td class="statusfilter-col" mat-cell *matCellDef="let item" style="text-align: center;">
          <span [ngClass]="getStatusClass(item.status)">{{item.status}}</span>

          <mat-select *ngIf="!IsEmployeeUser && !IsManagerUser" class="statusfilterselect" name="statusFilter"
            [(ngModel)]='statusSelect' (selectionChange)="OnChangeStatus(item)">
            <mat-option [value]="status" *ngFor="let status of GetItemStatusDropDown(item.status)">
              {{status}}
            </mat-option>
          </mat-select>

          <mat-select *ngIf="IsManagerUser && item.isItemCreatedByManager " class="statusfilterselect" name="statusFilter"
            [(ngModel)]='statusSelect' (selectionChange)="OnChangeStatus(item)">
            <mat-option [value]="status" *ngFor="let status of GetItemStatusDropDown(item.status)">
              {{status}}
            </mat-option>
          </mat-select>

        </td>
      </ng-container>



      <!-- Action Column -->
      <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let itemObj" class="action-link">
          <span class="actions-box" *ngIf="!IsEmployeeUser &&  !IsManagerUser">
            <button [disabled]="!userAccessPermission.cloneItem"
              [matTooltip]=" userAccessPermission.cloneItem ? 'Clone' : '' " matTooltipClass="matTooltip-Custom"
              matTooltipPosition="above" routerLink="/home/item/clone/{{itemObj.id}}" target="_blank"
              class="column-name-link action-icon">
              <mat-icon [matTooltip]="!userAccessPermission.cloneItem ? _storageService.notEnogugRights : '' "
                matTooltipClass="matTooltip-Custom" matTooltipPosition="above">file_copy</mat-icon>
            </button>

            <button [disabled]="!userAccessPermission.checkInItem"
              [matTooltip]=" userAccessPermission.checkInItem ? 'Check In' : '' " matTooltipClass="matTooltip-Custom"
              matTooltipPosition="above" class="column-name-link checkin action-icon"
              (click)="openItemActionSidePanel(itemObj,2,'CheckIn')">
              <mat-icon [matTooltip]="!userAccessPermission.checkInItem ? _storageService.notEnogugRights : '' "
                matTooltipClass="matTooltip-Custom" matTooltipPosition="above">exit_to_app</mat-icon>
            </button>
            <button [disabled]="!userAccessPermission.checkOutItem"
              [matTooltip]=" userAccessPermission.checkOutItem ? 'Check Out' : '' " matTooltipClass="matTooltip-Custom"
              matTooltipPosition="above" class="column-name-link"
              (click)="openItemActionSidePanel(itemObj,2,'CheckOut')">
              <mat-icon [matTooltip]="!userAccessPermission.checkOutItem ? _storageService.notEnogugRights : '' "
                matTooltipClass="matTooltip-Custom" matTooltipPosition="above">exit_to_app</mat-icon>
            </button>
            <button
            matTooltip="History"
            matTooltipClass="matTooltip-Custom"
            matTooltipPosition="above" class="column-name-link"
            (click)="openItemActionSidePanel(itemObj,1,'History')">
            <mat-icon
              matTooltipClass="matTooltip-Custom" matTooltipPosition="above">history</mat-icon>
          </button>
            <button [disabled]="!userAccessPermission.updateItem"
              [matTooltip]="userAccessPermission.updateItem ? 'Edit' :''" matTooltipClass="matTooltip-Custom"
              matTooltipPosition="above" class="column-name-link action-icon"
              routerLink="/home/item/edit/{{itemObj.id}}">
              <mat-icon [matTooltip]="!userAccessPermission.updateItem ? _storageService.notEnogugRights : '' "
                matTooltipClass="matTooltip-Custom" matTooltipPosition="above">edit</mat-icon>
            </button>

            <button [disabled]="!userAccessPermission.delteItem"
              [matTooltip]="userAccessPermission.delteItem ? 'Delete' :''" matTooltipClass="matTooltip-Custom"
              matTooltipPosition="above" class="column-name-link action-icon" (click)="confirmDialog(itemObj)">
              <mat-icon [matTooltip]="!userAccessPermission.delteItem ? _storageService.notEnogugRights : '' "
                matTooltipClass="matTooltip-Custom" matTooltipPosition="above">delete</mat-icon>
            </button>



          </span>
          <span class="actions-box" *ngIf="IsManagerUser">
            <button [disabled]="!itemObj.isItemCreatedByManager"
              [matTooltip]=" itemObj.isItemCreatedByManager ? 'Clone' : '' " matTooltipClass="matTooltip-Custom"
              matTooltipPosition="above" routerLink="/home/item/clone/{{itemObj.id}}" target="_blank"
              class="column-name-link action-icon">
              <mat-icon [matTooltip]="!itemObj.isItemCreatedByManager ? NotEnoguhRightsOnItemForManager : '' "
                matTooltipClass="matTooltip-Custom" matTooltipPosition="above">file_copy</mat-icon>
            </button>

            <button [disabled]="!itemObj.isItemCreatedByManager"
              [matTooltip]=" itemObj.isItemCreatedByManager ? 'Check In' : '' " matTooltipClass="matTooltip-Custom"
              matTooltipPosition="above" class="column-name-link checkin action-icon"
              (click)="openItemActionSidePanel(itemObj,2,'CheckIn')">
              <mat-icon [matTooltip]="!itemObj.isItemCreatedByManager ? NotEnoguhRightsOnItemForManager : '' "
                matTooltipClass="matTooltip-Custom" matTooltipPosition="above">exit_to_app</mat-icon>
            </button>
            <button [disabled]="!itemObj.isItemCreatedByManager"
              [matTooltip]="itemObj.isItemCreatedByManager ? 'Check Out' : '' " matTooltipClass="matTooltip-Custom"
              matTooltipPosition="above" class="column-name-link"
              (click)="openItemActionSidePanel(itemObj,2,'CheckOut')">
              <mat-icon [matTooltip]="!itemObj.isItemCreatedByManager ? NotEnoguhRightsOnItemForManager : '' "
                matTooltipClass="matTooltip-Custom" matTooltipPosition="above">exit_to_app</mat-icon>
            </button>
            <button [disabled]="!itemObj.isItemCreatedByManager"
              [matTooltip]="itemObj.isItemCreatedByManager ? 'Edit' :''" matTooltipClass="matTooltip-Custom"
              matTooltipPosition="above" class="column-name-link action-icon"
              routerLink="/home/item/edit/{{itemObj.id}}">
              <mat-icon [matTooltip]="!itemObj.isItemCreatedByManager ? NotEnoguhRightsOnItemForManager : '' "
                matTooltipClass="matTooltip-Custom" matTooltipPosition="above">edit</mat-icon>
            </button>

            <button [disabled]="!itemObj.isItemCreatedByManager"
              [matTooltip]="itemObj.isItemCreatedByManager ? 'Delete' :''" matTooltipClass="matTooltip-Custom"
              matTooltipPosition="above" class="column-name-link action-icon" (click)="confirmDialog(itemObj)">
              <mat-icon [matTooltip]="!itemObj.isItemCreatedByManager ? NotEnoguhRightsOnItemForManager : '' "
                matTooltipClass="matTooltip-Custom" matTooltipPosition="above">delete</mat-icon>
            </button>
          </span>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>



    </table>
    <div class="record-count" *ngIf="!recordCount && searchStart">No Record Found</div>
    <mat-paginator *ngIf="recordCount" [pageSizeOptions]="[25, 50, 100]" #paginator [pageIndex]="currentPage"
      [pageSize]="pageSize" (page)="pageChanged($event)" showFirstLastButtons aria-label="Select page of items">
    </mat-paginator>
    <app-item-details-tab #itemDetail (gridRefreshEvent)="populateItemsDataGrid()"></app-item-details-tab>

  </div>

</div>
<ng-template #callAPIDialog>
  <h2 matDialogTitle>Hello dialog!</h2>
  <mat-dialog-actions align="end">
    <button mat-button matDialogClose="no">No</button>
    <button mat-button matDialogClose="yes">Yes</button>
  </mat-dialog-actions>
</ng-template>
<div class="components-details-section filters-components" [hidden]="!isSidebarRight">
  <div class="mat-overlay"></div>
  <div class="components-details-area">
    <div class="details-header">
      <h2>Filters</h2>
      <span class="close-icon">
        <mat-icon (click)="close()">clear</mat-icon>
      </span>
    </div>
    <div class="details-content">


      <div class="grid-filter-dropdown">
        <mat-form-field appearance="standard">
          <mat-label>Category</mat-label>
          <mat-select name="Category" #Category="ngModel" [(ngModel)]="categoryFilter"
            (selectionChange)="updateSubCategories($event)">
            <mat-option value="">All</mat-option>
            <mat-option *ngFor="let category of Categories" [value]="category.id">
              {{category.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="grid-filter-dropdown">
        <mat-form-field appearance="standard">
          <mat-label>Sub-category</mat-label>
          <mat-select name="SubCategory" (selectionChange)="OnChangeFilterValues()" [(ngModel)]="subCategoryFilter">
            <mat-option value="">All</mat-option>
            <mat-option *ngFor="let subCategory of SubCategories" [value]="subCategory.name">
              {{subCategory.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="grid-filter-dropdown">
        <mat-form-field appearance="standard">
          <mat-label>Status</mat-label>
          <mat-select name="statusFilter" [(ngModel)]='statusFilter' (selectionChange)="OnChangeFilterValues()">
            <mat-option value="">All</mat-option>
            <mat-option value="In Stock">In Stock</mat-option>
            <mat-option value="Checked-out">Checked-out</mat-option>
            <mat-option value="Lost/Stolen">Lost/Stolen</mat-option>
            <mat-option value="Out for Repair">Out for Repair</mat-option>
            <mat-option value="Broken – Not Fixable">Broken – Not Fixable</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="grid-filter-dropdown" >
        <mat-form-field appearance="standard">
          <mat-label>Search User</mat-label>
          <input id="search-box #searchBox"  matInput placeholder="Search" name="searchUser" ControlName="searchUserControl" value="{{searcheduser.firstName}} {{searcheduser.lastName}}"
            aria-label="State" [matAutocomplete]="autoUser" (keyup)="SearchUsers($event)" [(ngModel)]="selectedUser.id">
          <mat-autocomplete #autoUser="matAutocomplete" [displayWith]="displayFn(Users)" (optionSelected)="selectUser($event.option.value,Users)">
            <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
            <ng-container *ngIf="!isLoading">
              <mat-option *ngFor="let user of Users"  [value]="user.id">
                <span>{{user.firstName}} {{user.lastName}} <b *ngIf="user.employeeId">({{user.employeeId}})</b></span>
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </mat-form-field>
      </div>




      <div class="pulladdata-block">
        <button type="button" (click)="clearall()" style="margin-top: 1px;margin-bottom: 15px;" mat-stroked-button
          color="primary" class="clear-filter-btn">Clear</button>

        <button type="submit" (click)="applyFilters()" style="margin-top: 0px; margin-bottom: 0px; margin-right: 0px;"
          mat-raised-button color="primary" mat-button class="pulldata-btn">Apply Filters</button>
      </div>
    </div>
  </div>
</div>
