import {
  AfterViewInit,
  OnInit,
  Component,
  ViewChild,
  ChangeDetectorRef,
  ElementRef,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ItemModel, itemTagsView } from 'src/app/Models/ComponentModels/ItemModel';
import { ItemService } from 'src/app/Services/component-services/item.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogConfig,
} from '@angular/material/dialog';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AddNotesDialogComponent } from '../add-notes-dialog/add-notes-dialog.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CategoryService } from 'src/app/Services/component-services/category-Service';
import { SubCategoryService } from 'src/app/Services/component-services/subcategory-service';
import { CategoryModel } from 'src/app/Models/ComponentModels/Category';
import { SubCategoryModel } from 'src/app/Models/ComponentModels/SubCategory';
import { ItemLogs } from 'src/app/Models/ComponentModels/ItemLogs';
import { StorageService } from 'src/app/Services/storage-service.service';
import { LoginRespopnseModel } from '../../login/LoginRespopnseModel';
import * as saveAs from 'file-saver';
import { TagService } from 'src/app/Services/component-services/tag.service';
import { Tag } from 'src/app/Models/ComponentModels/Tag';
import { FormControl } from '@angular/forms';

import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { AccessPermission, UserAccessPermissions } from 'src/app/Models/AccessPermissions';
import { UserService } from 'src/app/Services/component-services/user-service';
import { UserModel } from 'src/app/Models/ComponentModels/UserModel';


@Component({
  selector: 'app-items-listing',
  templateUrl: './items-listing.component.html',
  styleUrls: ['./items-listing.component.scss'],
})
export class ItemsListingComponent implements AfterViewInit {
  statusFilter: string = '';
  categoryFilter: string = '';
  subCategoryFilter: string = '';
  isLoading = false;
  isDetails = false;
  pageSize = 25;
  currentPage = 1;
  filterValue = '';
  sortColumn = '';
  sortDirection = '';
  startDateRangeValue: Date;
  endDateRangeValue: Date;
  tagId: Number=0;

  Categories: CategoryModel[] = [];
  SubCategories: SubCategoryModel[] = [];
  recordCount: any;
  itemLogsRequest: ItemLogs;
  statusSelect: any;
  searchStart:boolean=false;
  public isSidebarRight: boolean = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  filtersCount:number=0;
  displayedColumns: string[] = [
    'Name',
    'Code',
    'Quantity',
    'Location',
    'Category',
    'SubCategory',
    // 'ResponsibleUser',
    'Created',
    'Status',
    'Actions',
  ];

  itemsData: MatTableDataSource<ItemModel> = new MatTableDataSource();
  loggedInUser: LoginRespopnseModel;

//allTagSelectToogle:boolean=false;

TagResponse:Tag[]=[]
TagResponseOrignal:Tag[]=[]
  tagIdRequestString:string=''

//! chips
separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl();
  filteredFruits: Observable<string[]>;
  tags: string[] = [];
  tagsIds: Number[] = [];
  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;

tagNameSerch:string='';
itemStatusDropDown:string[]=['In Stock','Checked-out','Lost/Stolen','Out for Repair','Broken – Not Fixable']
userAccessPermission:UserAccessPermissions
test:string='teststring'
IsEmployeeUser:boolean=false;
IsManagerUser:boolean=false;
NotEnoguhRightsOnItemForManager:string="You don't have  rights"
Users: UserModel[];
searchUserControl = new FormControl('');
searcheduser: UserModel = new UserModel();
selectedUser: UserModel = new UserModel();

  constructor(
    private _itemService: ItemService,
    private _categoryService: CategoryService,
    private _subcategoryService: SubCategoryService,
    private _snackBar: MatSnackBar,
    private changeDetectorRefs: ChangeDetectorRef,
    private _snackbar: MatSnackBar,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public _storageService: StorageService,
    private _tagService :TagService,
    private _userService:UserService
  ) {
    this.itemsData = new MatTableDataSource<ItemModel>();
    this.itemLogsRequest = new ItemLogs();
    this.userAccessPermission=new UserAccessPermissions();
  }

  @ViewChild('itemDetail') itemDetailChildComponent: any;

  ngOnInit() {
    this.GetUserAccessPermission()


    // ! Check if Login user is Employee
    this.IsEmployeeUser= this._storageService.IsLoginUserEmployee()
    this.IsManagerUser= this._storageService.IsLoginUserManager()
    this.GetAllEmployees()
     // Remove quantity column for Employee from gird
     this.RemoveSpecificColumnFromGrid('Quantity')

    var openItemDetailsFromQueryString = (
      data: Array<ItemModel> | undefined
    ) => {

      var itemId = '';

      this.route.queryParams.subscribe((params) => {
        itemId = params['itemId'];
      });

      if (itemId) {
        var item = data?.find((x) => x.id.toString() == itemId);

        if (item) {
          this.openRightDrawer(item, 0);
        } else {
          this._itemService.getItemById(Number(itemId)).subscribe((x) => {
            if (x.data) {
              this.openRightDrawer(x.data, 0);
            }
          });
        }
      }
      this.loggedInUser = this._storageService.GetLoggedInUser();
    };
    this.populateItemsDataGrid(openItemDetailsFromQueryString);

    this._categoryService.getAllCategories().subscribe(
      (x) => {
        this.Categories = x.data;
      },
      (response) => {
        this._snackBar.open(response.error.Message);
      }
    );

    // this._tagService.GetAllTags('').subscribe(res=>{
    //   this.TagResponse=res;

    // })

    this.GetAllTags('')

  }



  ngAfterViewInit() {
    this.itemsData.paginator = this.paginator;
  }
  applyFilters() {
    this.populateItemsDataGrid();
    this.isSidebarRight = false;
  }

  populateItemsDataGrid(callback = (data: Array<ItemModel> | undefined) => {}) {

    var startDateRange = '';
    var endDateRange = '';
    var showDataFor = '';

    this.route.queryParams.subscribe((params) => {
      startDateRange = params['startDate'];
      endDateRange = params['endDate'];
      showDataFor = params['showdatafor'];
    });

    if(startDateRange && endDateRange){
      this.startDateRangeValue = new Date(startDateRange);
      this.endDateRangeValue = new Date(endDateRange);
    }
    this.isLoading = true;
    this._itemService
      .getItems(
        this.currentPage,
        this.pageSize,
        this.sortColumn,
        this.sortDirection,
        this.filterValue,
        this.statusFilter,
        this.categoryFilter,
        this.subCategoryFilter,
        this.tagIdRequestString,
        this.startDateRangeValue != null ? this.startDateRangeValue.toLocaleDateString() :'',
        this.endDateRangeValue == null ? '' : this.endDateRangeValue.toLocaleDateString(),
        showDataFor,
        this.IsEmployeeUser,
        this.IsManagerUser,
        this.selectedUser.id
      )
      .subscribe(
        (x) => {
          this.itemsData = new MatTableDataSource<ItemModel>(x.data);

          this.recordCount = x.data?.length;
          this.searchStart=true;
          setTimeout(() => {
            this.paginator.pageIndex = this.currentPage - 1; // index starts with zero
            this.paginator.length =
              x.totalRecords == undefined ? 0 : x.totalRecords;
          });

          this.itemsData.paginator = this.paginator;
          this.itemsData.sort = this.sort;
          this.isLoading = false;

          if (callback) {
            callback(x.data);
          }
        },
        (response) => {
          this.isLoading = false;
        }
      );
  }
  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex + 1;
    this.populateItemsDataGrid();
  }

  applyFilter(event: Event) {
    //const filterValue = (event.target as HTMLInputElement).value;
    this.itemsData.filter = this.filterValue.trim().toLowerCase();
    this.populateItemsDataGrid();
    if (this.itemsData.paginator) {
      this.itemsData.paginator.firstPage();
    }
  }
  private populateSubCategories(CategoryId: Number) {
    if (CategoryId) {
      this._subcategoryService.getAllSubCategories(CategoryId).subscribe(
        (x) => {
          this.SubCategories = x.data;
        },
        (response) => {
          this._snackBar.open(response.error.Message);
        }
      );
    } else {
      this.subCategoryFilter = '';
      this.SubCategories = [];
    }
  }
  updateSubCategories(event: any) {
    var selectedCategoryId = event.value;
    this.subCategoryFilter = '';
    this.populateSubCategories(selectedCategoryId);

    this.OnChangeFilterValues()

  }
  openRightDrawer(itemData: ItemModel, tab: number) {
    this.isDetails = true;
    if(itemData.itemSpecs==null || itemData.itemSpecs==undefined  )
    {
      itemData.itemSpecs=[];

    }
    if(itemData.itemTagsView==null || itemData.itemTagsView==undefined  )
    {
      itemData.itemTagsView=[];
    }

    this.itemDetailChildComponent.initializeSideTab(itemData, tab);
  }
  openItemActionSidePanel(itemData: ItemModel, tab: number, action: string) {
    this.isDetails = true;
    this.itemDetailChildComponent.setActionValue(action);
    this.itemDetailChildComponent.initializeSideTab(itemData, tab);
  }
  confirmDialogResult: string = '';
  confirmDialog(itemObj: ItemModel): void {
    const title = `Delete Item`;
    const message = `Are you sure you want to delete this item?`;

    const dialogData = new ConfirmDialogModel(title, message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '550px',
      data: dialogData,
      panelClass: 'custom-dialog',
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        // user clicked yes
        this._itemService.deleteItem(itemObj.id).subscribe((x) => {
          this._snackbar.open('Deleted successfully.', 'Ok');
          this.populateItemsDataGrid();

        },(error)=>{
          this._snackbar.open('Some Error Occure While Deleting Item ', 'Ok');

        });
      }
    });
  }
  getStatusClass(itemStatus: string) {
    var finalClasses = 'status-label ';

    switch (itemStatus) {
      case 'Lost/Stolen': {
        finalClasses += 'stolen';
        break;
      }
      case 'In Stock': {
        finalClasses += 'instock';
        break;
      }
      case 'Out for Repair': {
        finalClasses += 'outforrepair';
        break;
      }
      case 'Checked-out': {
        finalClasses += 'checkout';
        break;
      }
      case 'Broken – Not Fixable': {
        finalClasses += 'notfixable';
        break;
      }
      default: {
        break;
      }
    }
    return finalClasses;
  }
  SortChange(sortState: Sort) {

    this.sortColumn = sortState.active;
    this.sortDirection = sortState.direction;

    this.populateItemsDataGrid();
  }



  OnChangeStatus(itemData: ItemModel) {
    let currentStatus=itemData.status

    if(currentStatus==this.statusSelect)
    {
      return;
    }

    //! *********** In Stock ***************

    if(currentStatus=='In Stock')
    {
      if(this.statusSelect=='Checked-out')
      {
        this.openItemActionSidePanel(itemData,2,'CheckOut');
      }
      if(this.statusSelect=='Lost/Stolen')
      {
        this.addNotes(itemData,this.statusSelect)
      }
      if(this.statusSelect=='Out for Repair')
      {
        this.addNotes(itemData,this.statusSelect)
      }
      if(this.statusSelect=='Broken – Not Fixable')
      {
        this.addNotes(itemData,this.statusSelect)
      }
    }

     //! *********** Checked-out ***************
     if(currentStatus=='Checked-out')
     {
      if(this.statusSelect=='In Stock')
      {
        this.openItemActionSidePanel(itemData,2,'CheckIn');
      }
      if(this.statusSelect=='Lost/Stolen')
      {
        this.addNotes(itemData,this.statusSelect)
      }
     }

      //! *********** Lost/Stolen ***************
      if(currentStatus=='Lost/Stolen')
      {
       if(this.statusSelect=='In Stock')
       {
         // * Change Status Directly
         this.LogEntryOnDirectStatusChange(itemData)
       }

      }

        //! *********** Out for Repair ***************
        if(currentStatus=='Out for Repair')
        {
         if(this.statusSelect=='In Stock')
         {
           // * Change Status Directly
           this.LogEntryOnDirectStatusChange(itemData)
         }
         if(this.statusSelect=='Lost/Stolen')
         {
           this.addNotes(itemData,this.statusSelect)
          }
         if(this.statusSelect=='Broken – Not Fixable')
         {
             this.addNotes(itemData,this.statusSelect)
            }
        }

        //! *********** Broken – Not Fixable ***************
        if(currentStatus=='Broken – Not Fixable')
        {
          if(this.statusSelect=='In Stock'){
         // * Change Status Directly
         this.LogEntryOnDirectStatusChange(itemData)
        }
        }

     this.statusSelect=''
  }

LogEntryOnDirectStatusChange(itemData: ItemModel)
{
    this.itemLogsRequest.itemId=itemData.id;
    this.itemLogsRequest.itemNewStatus=this.statusSelect;
    this.itemLogsRequest.itemPreviousStatus=itemData.status;

    this.itemLogsRequest.notes=this.loggedInUser.fullName+ ' changed the status from '+itemData.status +' to  '+this.statusSelect;
    this._itemService.CreateItemLogs(this.itemLogsRequest).subscribe((res) => {
      this.populateItemsDataGrid();
      this._snackBar.open('Status Updated ','OK!');
      this.applyFilters();
    },(error=>{
      this._snackBar.open('Some Error Occures While Updating Status','OK!');
    }));
}


  addNotes(itemData:ItemModel,statusSelect:string){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "600px";
    dialogConfig.data = itemData.id;
    var dialogRef = this.dialog.open(AddNotesDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.actionName == 'Notes'){
        this.UpdateItemStatus(itemData,statusSelect)
        this.statusSelect=statusSelect;
        this.LogEntryOnDirectStatusChange(itemData)

      }
    });
  }

  UpdateItemStatus(itemData: ItemModel,newItemStatus:string)
  {
  var data = new FormData();
  data.append('id', itemData.id.toString());
  data.append('Status', newItemStatus);
  data.append('LastModifiedBy',  this.loggedInUser.email);
  data.append('DirectStatusChange',  'true');
  this._itemService.updateItem(data).subscribe(res=>{
    this._snackBar.open('Status Updated ','OK!');
    this.applyFilters();
  })

}

  ExportToExcelListOfItems() {

if(this.IsManagerUser)
{
  this.isLoading = true;
  this._itemService
    .ExportToExcelListOfItemsForManagers(
      this.currentPage,
      this.pageSize,
      this.sortColumn,
      this.sortDirection,
      this.filterValue,
      this.statusFilter,
      this.categoryFilter,
      this.subCategoryFilter
    )
    .subscribe(blob => {
      saveAs(blob, 'ListOfItems.xlsx');
      this.isLoading = false;
    },(error)=>{
    });

}
else{
  this.isLoading = true;
  this._itemService
    .ExportToExcelListOfItems(
      this.currentPage,
      this.pageSize,
      this.sortColumn,
      this.sortDirection,
      this.filterValue,
      this.statusFilter,
      this.categoryFilter,
      this.subCategoryFilter,
      this.IsEmployeeUser
    )
    .subscribe(blob => {
      saveAs(blob, 'ListOfItems.xlsx');
      this.isLoading = false;
    },(error)=>{
    });
}

    }


  formatCommaSeparatedNameList(data:itemTagsView[]){
    var returnstring = "";
    data.forEach(function(item){
      returnstring+='#'+item.tagName + " "
    });

    return returnstring
  }

//   OnTagChange()
//   {
//     this.tagIdRequestString=''
//     this.allTagSelectToogle=false

//     this.TagResponse.filter(x=>{
//       if(x.isSelected==true)
//       {
//           this.tagIdRequestString+=(x.tagId.toString())+",";
//       }
// }
//       )

//       this.applyFilters()

//   }

//   OnAllTagSelectToogle(e:any)
//   {
//     this.tagIdRequestString=''
// if(this.allTagSelectToogle==true)
// {
//   this.TagResponse.map(x=>{
//     x.isSelected=true
//   })
// }
// else{
//   this.TagResponse.map(x=>{
//     x.isSelected=false
//   })
// }
// this.applyFilters()
// }

GetAllTags(tagName?:string)
{

  var data = new FormData();
if(tagName==undefined || tagName==null)
{
  tagName='';
}
data.append('ItemName', tagName);
  this._tagService.GetAllTags(data).subscribe(res=>{
    this.TagResponse=res;
  })

  this._tagService.GetAllTags('').subscribe(res=>{
    this.TagResponseOrignal=res;
  })

}

applyFilterOnTags()
{
  this.GetAllTags(this.tagNameSerch);
}


//! chips

add(event: MatChipInputEvent): void {
  const value = (event.value || '').trim();
  // Add our fruit
  if (value) {
    if(this.tags.indexOf(value)==-1)
    {
      this.tags.push(value);
    }
  }
  // Clear the input value
  event.chipInput!.clear();
  this.tagCtrl.setValue(null);
}

remove(fruit: string): void {
  const index = this.tags.indexOf(fruit);
  if (index >= 0) {
    this.tags.splice(index, 1);
  }

//! remove the tagIds from array

  var x = this.TagResponseOrignal.filter(x=>{
   if(x.tagName == fruit){
    const indexOfId = this.tagsIds.indexOf(x.tagId);
    if (indexOfId >= 0) {
        this.tagsIds.splice(indexOfId,1)
    }
  }
    })
    this.ConvertTagArrayToString();
    this.applyFilters()
}

selected(event: MatAutocompleteSelectedEvent): void {
  if(this.tags.indexOf(event.option.viewValue)==-1)
  {
    this.tags.push(event.option.viewValue);
  this.fruitInput.nativeElement.value = '';
  this.tagCtrl.setValue(null);
  this.tagsIds.push(event.option.value)

  this.fruitInput.nativeElement.blur();

  }
this.ConvertTagArrayToString();
  this.applyFilters()
}

ConvertTagArrayToString()
{
  this.tagIdRequestString='';
  this.tagsIds.map(x=>{
    this.tagIdRequestString+=x+','
  })
}


GetItemStatusDropDown(itemCurrentStatus:string)
{

 let filterResult:string[]=[];

 var finalClasses = 'status-label ';



  if(itemCurrentStatus=='In Stock')
  {
    if(this.userAccessPermission.checkOutItem)
    {
      if(this.userAccessPermission.updateItem)
      {
        filterResult=['Checked-out','Lost/Stolen','Out for Repair','Broken – Not Fixable'];
         return filterResult;
      }
      else{
        filterResult=['Checked-out'];
        return filterResult;

      }

    }
     if(this.userAccessPermission.updateItem){
      filterResult=['Lost/Stolen','Out for Repair','Broken – Not Fixable'];
      return filterResult;

    }




  }

  if(itemCurrentStatus=='Checked-out' && this.userAccessPermission.updateItem)
  {
    filterResult=['In Stock','Lost/Stolen'];
  }
  if(itemCurrentStatus=='Lost/Stolen' && this.userAccessPermission.updateItem)
  {
    filterResult=['In Stock'];
  }
  if(itemCurrentStatus=='Out for Repair' && this.userAccessPermission.updateItem)
  {
     filterResult =['In Stock','Lost/Stolen','Broken – Not Fixable'];
  }
  if(itemCurrentStatus=='Broken – Not Fixable' && this.userAccessPermission.updateItem)
  {
     filterResult =['In Stock'];
  }


    // switch (itemCurrentStatus) {

    //   case 'In Stock': {
    //     filterResult=['Checked-out','Lost/Stolen','Out for Repair','Broken – Not Fixable'];
    //     break;
    //   }
    //   case 'Checked-out': {
    //     filterResult=['In Stock','Lost/Stolen'];
    //     break;
    //   }
    //   case 'Lost/Stolen': {
    //    filterResult=['In Stock'];
    //     break;
    //   }

    //   case 'Out for Repair': {
    //     filterResult =['In Stock','Lost/Stolen','Broken – Not Fixable'];
    //     break;
    //   }

    //   case 'Broken – Not Fixable': {
    //     filterResult =['In Stock'];
    //     break;
    //   }
    //   default: {
    //     filterResult =this.itemStatusDropDown;
    //     break;
    //   }
    // }
    return filterResult;
  }

  openFiltersSidePanel() {
    this.isSidebarRight = true;
  }
  close(){
    // close side bar and show grid
    this.isSidebarRight = false;
    document.body.style.overflow = 'visible';
  }

  clearall(){
    this.statusFilter='';
    this.categoryFilter='';
    this.subCategoryFilter='';

   this.filtersCount=0;
   this.applyFilters()
}
OnChangeFilterValues(){

       this.filtersCount=0;
       if( this.statusFilter!='')
       {
         this.filtersCount=this.filtersCount+1;
       }
       if(this.categoryFilter!='')
       {
         this.filtersCount=this.filtersCount+1;
       }
       if(this.subCategoryFilter!='')
       {
         this.filtersCount=this.filtersCount+1;
       }

     // this.applyFilters()
     }


     GetUserAccessPermission()
     {
      this.userAccessPermission=this._storageService.GetLoginUserPermissions()

     }



     SearchUsers(event: any) {
      let name = event.target.value;
      var searchString = event.target.value.replace(/ /g, '');
      // if (searchString.length < 3) {
      //   return;
      // }
      if (searchString.length < 1) {
        var getInActiveUsers: boolean = true;
        this._userService.getallEmployees(getInActiveUsers).subscribe((x) => {
          var data: any = x;
          this.Users = JSON.parse(JSON.stringify(<UserModel>data.result.data));
          this.searchUserControl.setValue(this.searcheduser);
        });
      //  this.applyFilters()
      }

      this._userService.searchallUsers(searchString).subscribe(
        (response) => {
          if (response.data.length > 0) {
            this.Users = response.data;
          }
        },
        (errResponse) => {
          this._snackBar.open(errResponse.error.Message);
        }
      );
      console.log(this.Users)
    }

    displayFn(options: UserModel[]): (id: string) => string {

      return (id: string) => {
        const correspondingOption = Array.isArray(options)
          ? options.find((option) => option.id === id)
          : null;

        if (correspondingOption) {
          return (
            correspondingOption.firstName +
            ' ' +
            (correspondingOption.lastName || '')
          );
        }
        return '';
      };
    }

    selectUser(selectedId: any, users: UserModel[]) {
      debugger
      var user = users.find((x) => x.id == selectedId);
      if (user) {
        this.searcheduser = user;
       // this.profileForm.enable();

        // this.profileForm.patchValue({
        //   selectedRoles: user.roles?.map((x) => x.id),
        // });
      }
      console.log('Sellectre user id'+ this.selectedUser.id)
     // this.applyFilters()
    }

    // Get All the Emplyoes List
    GetAllEmployees()
    {
      var getInActiveUsers: boolean = true;
      this._userService.getallEmployees(getInActiveUsers).subscribe((x) => {
        var data: any = x;
        this.Users = JSON.parse(JSON.stringify(<UserModel>data.result.data));
      });
    }
    RemoveSpecificColumnFromGrid(columnName:string)
    {
      if(this.IsEmployeeUser)
      {
      let index= this.displayedColumns.indexOf(columnName)
      this.displayedColumns.splice(index,1)
      }
    }

}

