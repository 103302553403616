import { Component, OnInit, ViewChild, ElementRef, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map, startWith } from 'rxjs/operators';
import { UserService } from 'src/app/Services/component-services/user-service';
import { UserModel } from 'src/app/Models/ComponentModels/UserModel';
import { ItemModel } from 'src/app/Models/ComponentModels/ItemModel';
import { environment } from 'src/environments/environment';
import { ItemService } from 'src/app/Services/component-services/item.service';
import { LocationModel } from 'src/app/Models/ComponentModels/LocationModel';
import { LocationService } from 'src/app/Services/component-services/location-service';
import lgZoom from 'lightgallery/plugins/zoom';
import { BeforeSlideDetail } from 'lightgallery/lg-events';
import { LightGallery } from 'lightgallery/lightgallery';
import { InitDetail } from 'lightgallery/lg-events';
import { StorageService } from 'src/app/Services/storage-service.service';
import { ItemCheckOutHistoryModel } from 'src/app/Models/ComponentModels/ItemCheckOutHistoryModel';
import { DataShareService } from 'src/app/Services/data-share.service';
import { CommonTasksService } from 'src/app/Services/common-tasks.service';
import { Tag } from 'src/app/Models/ComponentModels/Tag';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { TagService } from 'src/app/Services/component-services/tag.service';
import { UserAccessPermissions } from 'src/app/Models/AccessPermissions';
@Component({
  selector: 'app-item-details-tab',
  templateUrl: './item-details-tab.component.html',
  styleUrls: ['./item-details-tab.component.scss']
})
export class ItemDetailsTabComponent {
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('itemHistory') itemDetailHistoryChildComponent: any;
  @Input() ItemDetails: ItemModel;
  @Output() gridRefreshEvent = new EventEmitter<string>();
  @Input() active: boolean;

  employees: UserModel[] = new Array();
  filteredEmployees: Observable<UserModel[]> = new Observable<UserModel[]>();

  admins: UserModel[] = new Array();
  filteredAdmins: Observable<UserModel[]> = new Observable<UserModel[]>();

  itemCheckOuts: ItemCheckOutHistoryModel[]= new Array();
  filtereditemCheckOuts: Observable<ItemCheckOutHistoryModel[]> = new Observable<ItemCheckOutHistoryModel[]>();

  isLoading: boolean = false;
  public isSidebarRight: boolean = false;

  employeeLocations: string[] = new Array();
  dplLocations: LocationModel[] = new Array();
  apiURL = environment.ApiUrl;

  statusForm = new FormGroup({
    selectActionControl: new FormControl('CheckIn', Validators.required),
    selectUserControl: new FormControl(''),
    selectAdminControl: new FormControl(''),
    checkOutFromUserControl: new FormControl(''),
    selectDplLocationControl: new FormControl('', Validators.required),
    selectLocationControl: new FormControl('', Validators.required),
    qtyControl: new FormControl('',Validators.required),
    notesControl: new FormControl(''),
   // checkOutDateControl: new FormControl(''),
    expiryDateControl: new FormControl(''),
    actionDateControl: new FormControl(new Date(),Validators.required),
    uploadFileControl: new FormControl(''),
    uploadFileControlText: new FormControl('')
  });
  today = new Date();
  loginUser:string;

  filesToUpload: File[];
  showSpec:boolean=false

    //! chips
separatorKeysCodes: number[] = [ENTER, COMMA];
tagCtrl = new FormControl();
filteredFruits: Observable<string[]>;
tags: string[] = [];

@ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;


TagResponse:Tag[]=[]
tagNameSerch:string='';
userAccessPermission:UserAccessPermissions
IsEmployeeUser:boolean=false;
IsManagerUser:boolean=false;

  constructor(
    private _userService: UserService,
    private _itemService: ItemService,
    private _locationService: LocationService,
    private _snackbar: MatSnackBar,
    private _snackBar: MatSnackBar,
    private _commonTasksService: CommonTasksService,
    private _storageService:StorageService,
    private _dataShareService: DataShareService,
    private _tagService: TagService

  ) {
    this.ItemDetails = new ItemModel();
    this.loginUser = this._storageService.GetLoggedInUser().id;
    this.statusForm.controls['qtyControl'].setValue(1);
// this.statusForm.controls['selectAdminControl'].setValue(this.loginUser);

    //this.dateAdapter.setLocale('es-US');
    this.userAccessPermission=new UserAccessPermissions();
    this.IsEmployeeUser= this._storageService.IsLoginUserEmployee()
    this.IsManagerUser= this._storageService.IsLoginUserManager()

  }

  onInit = (detail: InitDetail): void => {
    this.lightGallery = detail.instance;


};
  ngOnInit(): void {

    //if(this.ItemDetails && this.ItemDetails)

    this._userService.getallEmployees().subscribe((x) => {
      var data: any = x;
      this.employees = <UserModel[]>data.result.data;
      this.employees.map(function (x) {
        x.name = x.firstName?.trim() + " " + x.lastName?.trim();
      });
      this.filteredEmployees = this.statusForm.controls.selectUserControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterUsers(value)),
      );
    }, (error) => {
      this._snackbar.open(error.error.Message || 'Something went wrong.', 'Ok');
    });

    this._userService.getallAdmins().subscribe((x) => {
      this.admins = x.data;
      this.admins.map(function (x) {
        x.name = x.firstName?.trim() + " " + x.lastName?.trim();
      });
      this.filteredAdmins = this.statusForm.controls.selectAdminControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterAdmins(value)),
      );
    }, (error) => {
      this._snackbar.open(error.error.Message || 'Something went wrong.', 'Ok');
    });
    this._itemService.getItemCheckouts(this.ItemDetails.id).subscribe((x) => {
      this.itemCheckOuts = x.data;
      this.itemCheckOuts.map(function (x) {
        x.issuedToUser.name = x.issuedToUser.firstName?.trim() + " " + x.issuedToUser.lastName?.trim();
      });
      this.filtereditemCheckOuts = this.statusForm.controls.checkOutFromUserControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filteritemCheckOuts(value)),
      );
    }, (error) => {
      this._snackbar.open(error.error.Message || 'Something went wrong.', 'Ok');
    });
    this._locationService.getAllLocations().subscribe((x) => {
      this.dplLocations = x.data
    });
    this.GetUserAccessPermission()

    this.SetCheckInCheckoutBaseOnPermissions()

  }
  selectedTabIndex:number;
  public changeTab(tabNumber:number) {

    this.selectedTabIndex = tabNumber;
 }
  public initializeSideTab(data:ItemModel, tabNumber:number){
    // this method will be called from list component everytime an item side details are fetched


    this.ItemDetails = data;

    if(this.ItemDetails.itemSpecs.length>0)
    {
      this.showSpec=true;
    }
    else{
      this.showSpec=false;

    }
// ! Get All the tags list
    this.GetAllTags('');
// ! Get Tags assign to this item
    this.tags=[];
    if(this.ItemDetails.itemTagsView!=null)
    {
      this.ItemDetails.itemTagsView.forEach(x=>{
        if(this.tags.indexOf(x.tagName)==-1)
        {
          this.tags.push(x.tagName)
        }

      })
    }


    this.isSidebarRight = true;
    this.lightGalleryNeedRefresh = true;
    this.selectedTabIndex = tabNumber;
    document.body.style.overflow = 'hidden';
    this.refreshHistory(this.ItemDetails.id);
    this.updateValidationRules();

    if(this.ItemDetails.quantity == 1){
      // if qty is 1 then qty field is hidden and by default 1 qty is sent to server
      // so marking it as not required in form
      this.statusForm.controls['qtyControl'].clearValidators();
      this.statusForm.controls['qtyControl'].updateValueAndValidity();

    }else{
      this.statusForm.controls['qtyControl'].setValidators(Validators.required);
      this.statusForm.controls['qtyControl'].updateValueAndValidity();
    }





  }
  public setActionValue(action:string){
    if(action){
      this.statusForm.controls['selectActionControl'].setValue(action);
    }
  }
  private lightGallery!: LightGallery;
  public lightGalleryNeedRefresh = false;

  ngAfterViewChecked(): void {
      if (this.lightGalleryNeedRefresh) {
          this.lightGallery?.refresh();
          this.lightGalleryNeedRefresh = false;
      }
  }

  settings = {
    counter: false,
    plugins: [lgZoom]
  };
  onBeforeSlide = (detail: BeforeSlideDetail): void => {
    const { index, prevIndex } = detail;
  };
  close(){
    // close side bar and show grid
    this.isSidebarRight = false;
    document.body.style.overflow = 'visible';
    // when type in serch history then closing on the drawer the input filed will be set to empty
    this.itemDetailHistoryChildComponent.filterValue='';

    this._dataShareService.ItemDelete.subscribe(ItemDeleted => {
if(ItemDeleted>0)
{
  this.gridRefreshEvent.emit('refreshing');
  this._dataShareService.ChangeDeleteItem(0);

}
    });


  }
  public refreshHistory(itemId: number) {
    this.itemDetailHistoryChildComponent.fetchData(itemId);

    this._itemService.getItemCheckouts(itemId).subscribe((x) => {
      this.itemCheckOuts = x.data;
      this.itemCheckOuts.map( (x) => {
        x.issuedToUser.name = x.issuedToUser.firstName?.trim() + " " + x.issuedToUser.lastName?.trim();
      });
      this.filtereditemCheckOuts = this.statusForm.controls.checkOutFromUserControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filteritemCheckOuts(value)),
      );
      // if single item is checked out, auto populate value in check in from

      if(this.itemCheckOuts.length == 1){
        setTimeout(() =>{
          this.statusForm.controls.checkOutFromUserControl.setValue(this.itemCheckOuts[0].id);
        }, 200);
      }else{
        this.statusForm.controls.checkOutFromUserControl.setValue(null);
      }
    }, (error) => {
      this._snackbar.open(error.error.Message || 'Something went wrong.', 'Ok');
    });
  }
  public updateValidationRules() {
    // some stuff is required in checkout but not in check in. so setting validators right

    var actionName = this.statusForm.controls['selectActionControl'].value;
    if (actionName == "CheckOut") {
      this.statusForm.controls['selectDplLocationControl'].clearValidators();
      this.statusForm.controls['selectLocationControl'].setValidators(Validators.required);

      // this.statusForm.controls['selectAdminControl'].clearValidators();
      this.statusForm.controls['checkOutFromUserControl'].clearValidators();
      this.statusForm.controls['selectUserControl'].setValidators(Validators.required);

      if(this.ItemDetails.category == "Licenses"){
        this.employeeLocations.push(this.ItemDetails.location);
        this.statusForm.controls['selectLocationControl'].setValue(this.ItemDetails.location);
        this.statusForm.controls['selectLocationControl'].disable();
      }else{
        this.statusForm.controls['selectLocationControl'].enable();
      }

    }

    else {
      this.statusForm.controls['selectLocationControl'].clearValidators();
      this.statusForm.controls['selectDplLocationControl'].setValidators(Validators.required);

      this.statusForm.controls['selectUserControl'].clearValidators();
      // this.statusForm.controls['selectAdminControl'].setValidators(Validators.required);

      this.statusForm.controls['selectLocationControl'].enable();


           // ! Checin + cateory =Licance
      if(this.ItemDetails.category == "Licenses"){
        this.statusForm.controls['selectDplLocationControl'].setValue('Cloud');
        this.statusForm.controls['selectDplLocationControl'].disable();
      }else{
        this.statusForm.controls['selectDplLocationControl'].enable();
      }

    }

    this.statusForm.controls['selectLocationControl'].updateValueAndValidity();
    this.statusForm.controls['selectDplLocationControl'].updateValueAndValidity();
    this.statusForm.controls['selectUserControl'].updateValueAndValidity();
    this.statusForm.controls['checkOutFromUserControl'].updateValueAndValidity();
    //  this.statusForm.controls['selectAdminControl'].updateValueAndValidity();
    this.statusForm.controls['selectLocationControl'].updateValueAndValidity();
  }
  private _filterUsers(value: any): UserModel[] {
    // auto complete filteration of data
    if (!value) {
      return this.employees;
    }
    const filterValue = value.toLowerCase();

    if (this.employees) {
      return this.employees.filter((option) => option?.firstName?.toLowerCase().includes(filterValue)
        || option?.lastName?.toLowerCase().includes(filterValue) || (option?.name?.toLowerCase().includes(filterValue)));
    } else {
      return [];
    }
  }
  private _filteritemCheckOuts(value: string): ItemCheckOutHistoryModel[] {
    // auto complete filteration of data
    if (!value) {
      return this.itemCheckOuts;
    }

    if (this.itemCheckOuts) {
      return this.itemCheckOuts.filter((option) => option?.issuedToUser.firstName?.toLowerCase().includes(value)
        || option?.issuedToUser.lastName?.toLowerCase().includes(value) || (option.issuedToUser?.name?.toLowerCase().includes(value)));
    } else {
      return [];
    }
  }
  private _filterAdmins(value: string): UserModel[] {
    // auto complete filteration of data
    if (!value) {
      return this.admins;
    }
    const filterValue = value.toLowerCase();

    if (this.employees) {
      return this.admins.filter((option) => option?.firstName?.toLowerCase().includes(filterValue) ||
      option?.lastName?.toLowerCase().includes(filterValue) || option?.name?.toLowerCase().includes(filterValue) );
    } else {
      return [];
    }
  }
  displayFn(employees: UserModel[]): (id: string) => string {
    // auto complete display function to show in results
    return (id: string) => {
      const correspondingOption = Array.isArray(employees) ? employees.find(option => option.id === id) : null;

      if (correspondingOption) {

        return correspondingOption.firstName + ' ' + (correspondingOption.lastName || '');
      }
      return '';
    }
  }
  displayCheckOuts(employees: ItemCheckOutHistoryModel[]): (id: number) => string {
    // auto complete display function to show in results
    return (id: number) => {
      const correspondingOption = Array.isArray(employees) ? employees.find(option => option.id === id) : null;

      if (correspondingOption) {

        return correspondingOption.issuedToUser.firstName + ' ' + (correspondingOption.issuedToUser.lastName || '');
      }
      return '';
    }
  }
  validLocation: boolean = true;
  selectUser(selectedId: any, users: UserModel[]) {
    // when user selected from auto complete, fill address dropdown with addresses of user selected.
    var user = users.find(x => x.id == selectedId);
    if (user) {
      this.employeeLocations = [];
      if (user.address) {
        this.employeeLocations.push(user.address);
      }
      if (user.address2) {
        this.employeeLocations.push(user.address2);
      }
    }
    if (this.employeeLocations.length == 0) {
      this.validLocation = false;
    }
  }
  sizeExceeded: boolean = false;
  fileAttr = 'Choose File';
  videoFileSelected:boolean = false;
  uploadFileEvt(imgFile: any) {
    this.filesToUpload = new Array();

    if (imgFile.target.files) {
      var fileNames = '';

      for(var i =0; i<imgFile.target.files.length; i++){
        var size = imgFile.target.files[i].size;
        var fileType = imgFile.target.files[i].name.split('.').pop();

        this.videoFileSelected = !(fileType.toLowerCase() == 'jpg' || fileType.toLowerCase() == 'jpeg'|| fileType.toLowerCase() == 'png');
      size = size / (1024 * 1024);

      if (size > 4 && !this.videoFileSelected) {
        this.fileInput.nativeElement.value = "";
          this.statusForm.controls['uploadFileControlText'].setValue('Choose File');
          this.sizeExceeded = true;
          return;
        }
        else {
          this.sizeExceeded = false;
        }
        if(fileNames != ''){
          fileNames = fileNames + ',';
        }
        fileNames = fileNames + imgFile.target.files[i].name;
        this.filesToUpload.push(imgFile.target.files[i]);
      }

      this.statusForm.controls['uploadFileControlText'].setValue(fileNames);

    } else {
      //this.fileAttr = 'Choose File';
    }
  }

  save() {
    var action = this.statusForm.controls['selectActionControl'].value;

//     if(!this.userAccessPermission.checkOutItem)
//     {
//       this._snackBar.open("You don't have check out rights");
// return;
//     }
//     if(!this.userAccessPermission.checkOutItem)
//     {
//       this._snackBar.open("You don't have check in rights");
// return;
//     }


    this.updateValidationRules();
    if (this.statusForm.valid) {
      if (this.ItemDetails.quantity != 1 && this.statusForm.controls['qtyControl'].value == 0) {
        // if item has 1 quantity remaining in stock, qty field is hidden and qty auto selected as 1.
        this._snackBar.open("Please select Quantity > 0");
        return;
      }
      if (action == "CheckOut" && this.statusForm.controls['qtyControl'].value > this.ItemDetails.quantity) {
        this._snackBar.open('Selected Quantity is more than the available quantity!', 'Ok');
        return;
      }
      var data = new FormData();
      data.append('ItemId', this.ItemDetails.id.toString());
      data.append('Action', action);

      if (action != null && action == "CheckOut") {
        data.append('IssuedToUserId', this.statusForm.controls['selectUserControl'].value);
        //data.append('IssuedByUserId', this.statusForm.controls['selectAdminControl'].value);
        data.append('IssuedLocation', this.statusForm.controls['selectLocationControl'].value);
      } else {
        // data.append('IssuedToUserId', this.statusForm.controls['selectAdminControl'].value);
        data.append('IssuedToUserId', this.loginUser);
        data.append('IssuedByUserId', this.itemCheckOuts.find(x => x.id === this.statusForm.controls['checkOutFromUserControl'].value)?.issuedToUserId || '');
        data.append('ParentCheckOutId', this.statusForm.controls['checkOutFromUserControl'].value);
        data.append('IssuedLocation', this.statusForm.controls['selectDplLocationControl'].value);
      }

      if (this.ItemDetails.totalQuantity == 1) {
        data.append('QtyChanged', '1');
      } else {
        data.append('QtyChanged', this.statusForm.controls['qtyControl'].value);
      }


      if (this.statusForm.controls['expiryDateControl'].value) {
        data.append('Expiry', this._commonTasksService.getMySQLformatDate(this.statusForm.controls['expiryDateControl'].value.toString()));

      }
      data.append('ActionName', this.statusForm.controls['selectActionControl'].value);

      data.append('ActionDate', this._commonTasksService.getMySQLformatDate(this.statusForm.controls['actionDateControl'].value.toString()));

      if (this.statusForm.controls['notesControl'].value) {
        data.append('Notes', this.statusForm.controls['notesControl'].value);
      }

      if (this.filesToUpload && this.filesToUpload.length > 0) {
        this.filesToUpload.forEach(function(item){
          data.append('file', item, item.name);
        });

      } else {
        data.delete('file');
      }
      this.isLoading = true;
      this._itemService.saveRecord(data).subscribe((x) => {


        this.statusForm.reset();
        this.statusForm.controls['qtyControl'].setErrors(null);
        this.statusForm.controls['selectUserControl'].setErrors(null);
        this.statusForm.controls['selectLocationControl'].setErrors(null);
      //  this.statusForm.controls['checkOutDateControl'].setErrors(null);
        this.statusForm.controls['expiryDateControl'].setErrors(null);
        ///this.statusForm.controls['actionDateControl'].setErrors(null);
        this.statusForm.controls['selectActionControl'].setErrors(null);
        this.statusForm.controls['selectDplLocationControl'].setErrors(null);
        this.close();
        this.statusForm.controls['selectActionControl'].setValue(action);
        this.gridRefreshEvent.emit('refreshing');
        this.isLoading = false;
        this.statusForm.controls['qtyControl'].setValue(1);
        action = action == "CheckOut" ? "Check-out" : "Check-in";
        if(this.videoFileSelected){
          this._snackBar.open('Successfully ' + action + '. File is processing at server and may take long time. Refresh this page after a while.');
        }else{
          this._snackBar.open('Item ' + action + ' successfully!');
        }

      }, (response) => {
        this._snackBar.open(response.error.Message || "Something went wrong.");
        this.isLoading = false;
      });
    } else {
      this._snackBar.open("From is invalid");
      this.isLoading = false;
    }
    // remove all the selected images from array
    this.filesToUpload=[]
  }
  edit(){
    // close side bar and show grid
    this.isSidebarRight = false;
    document.body.style.overflow = 'visible';
  }

  // Remove the images from array when clic on cross button
  OnImageDeselect(file:any)
  {

   this.filesToUpload.filter(x=>{
     if(x.name==file.name)
     {
      this.filesToUpload.splice(this.filesToUpload.indexOf(x),1)
     }

   })
  }

//! chips

add(event: MatChipInputEvent): void {


  const value = (event.value || '').trim();
  // Add tag
  if (value) {
    if(this.tags.indexOf(value)==-1)
    {
      this.tags.push(value);
    }
  }
  // Clear the input value
  event.chipInput!.clear();
  this.tagCtrl.setValue(null);


  this.CreateItemTag();
}

remove(tag: string): void {
  const index = this.tags.indexOf(tag);
  if (index >= 0) {
    this.tags.splice(index, 1);
  }

  this.CreateItemTag();
}


selected(event: MatAutocompleteSelectedEvent): void {
  if(this.tags.indexOf(event.option.viewValue)==-1)
  {
    this.tags.push(event.option.viewValue);
  this.tagInput.nativeElement.value = '';
  this.tagCtrl.setValue(null);


  this.CreateItemTag();
  }

  this.tagInput.nativeElement.blur();

}
GetAllTags(tagName?:string)
{
  var data = new FormData();
if(tagName==undefined || tagName==null)
{
  tagName='';
}
data.append('ItemName', tagName);
  this._tagService.GetAllTags(data).subscribe(res=>{
    this.TagResponse=res;
  })
}
applyFilter()
{
  this.GetAllTags(this.tagNameSerch);
}


CreateItemTag()
{
  this._tagService.CreateTagsWithItems(this.tags.toString(),this.ItemDetails.id.toString());
      this.gridRefreshEvent.emit('refreshing');
 }


 getStatusClass(itemStatus: string) {
  var finalClasses = 'status-label ';

  switch (itemStatus) {
    case 'Lost/Stolen': {
      finalClasses += 'stolen';
      break;
    }
    case 'In Stock': {
      finalClasses += 'instock';
      break;
    }
    case 'Out for Repair': {
      finalClasses += 'outforrepair';
      break;
    }
    case 'Checked-out': {
      finalClasses += 'checkout';
      break;
    }
    case 'Broken – Not Fixable': {
      finalClasses += 'notfixable';
      break;
    }
    default: {
      break;
    }
  }
  return finalClasses;
}

GetUserAccessPermission()
     {
      this.userAccessPermission=this._storageService.GetLoginUserPermissions()
     }


SetCheckInCheckoutBaseOnPermissions()
{
  if(this.userAccessPermission.checkInItem && this.userAccessPermission.checkOutItem)
  {

  }
  else{
    if(this.userAccessPermission.checkInItem)
    {
      this.setActionValue('CheckIn');
      this.updateValidationRules();

    }
    if(this.userAccessPermission.checkOutItem)
    {
      this.setActionValue('CheckOut');
      this.updateValidationRules();


    }
  }



}


}
