import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ItemCheckOutHistoryModel } from 'src/app/Models/ComponentModels/ItemCheckOutHistoryModel';
import { ItemService } from 'src/app/Services/component-services/item.service';
import { environment } from 'src/environments/environment';
import lgZoom from 'lightgallery/plugins/zoom';
import { BeforeSlideDetail, InitDetail } from 'lightgallery/lg-events';
import { LightGallery } from 'lightgallery/lightgallery';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddNotesDialogComponent } from '../add-notes-dialog/add-notes-dialog.component';
import { NgImageSliderComponent } from 'ng-image-slider';
import { EditNotesDialogComponent } from '../edit-notes-dialog/edit-notes-dialog.component';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../confirm-dialog/confirm-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ItemLogs } from 'src/app/Models/ComponentModels/ItemLogs';
import { StorageService } from 'src/app/Services/storage-service.service';
import { LoginRespopnseModel } from '../../login/LoginRespopnseModel';
import { DataShareService } from 'src/app/Services/data-share.service';
import { UserAccessPermissions } from 'src/app/Models/AccessPermissions';

@Component({
  selector: 'app-item-details-history',
  templateUrl: './item-details-history.component.html',
  styleUrls: ['./item-details-history.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ItemDetailsHistoryComponent implements OnInit {
  @Input() ItemId:number;
  @Output() refreshTab = new EventEmitter<string>();

  baseURL = environment.ApiUrl;
  pageNo:number = 1;
  PageSize:number = 10;
  paginationFinished = false;

  historyData:ItemCheckOutHistoryModel[] = new Array<ItemCheckOutHistoryModel>();
  itemLogsData:ItemLogs[]=new Array<ItemLogs>();
  loggedInUser: LoginRespopnseModel;
  @Output() gridRefreshEvent = new EventEmitter<string>();
  userAccessPermission:UserAccessPermissions
  IsEmployeeUser:boolean=false
  IsManagerUser:boolean=false;
  IsItemCreatedByUser:boolean=false;
  filterValue = '';
  isLoading = false;
  //itemId:number;
  constructor(
    private _itemService:ItemService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _storageService: StorageService,
    private _dataShareService: DataShareService


  ) {
    this.userAccessPermission=new UserAccessPermissions();

   }

  ngOnInit(): void {
    this.loggedInUser = this._storageService.GetLoggedInUser();
    this.GetUserAccessPermission()
    this.IsEmployeeUser= this._storageService.IsLoginUserEmployee()
    this.IsManagerUser= this._storageService.IsLoginUserManager()

  }
  private lightGallery!: LightGallery;
  private lightGalleryNeedRefresh = false;

  ngAfterViewChecked(): void {

      if (this.lightGalleryNeedRefresh) {
          this.lightGallery.refresh();
          this.lightGalleryNeedRefresh = false;
      }
  }

  settings = {
    counter: false,
    plugins: [lgZoom]
  };
  onInit = (detail: InitDetail): void => {
    this.lightGallery = detail.instance;
};
  onBeforeSlide = (detail: BeforeSlideDetail): void => {
    const { index, prevIndex } = detail;
  };

  public fetchData(itemId:number){
    this.isLoading=true;

    this._itemService.getItemHistory(itemId,this.pageNo,this.PageSize, this.IsEmployeeUser,this.filterValue).subscribe((x) =>{
      this.historyData = x.data || new Array<ItemCheckOutHistoryModel>();

      this.historyData.map((item) =>{
        item.images = this._itemService.populateImagePathsFromCommaSeparatedString(item.imagePath);
        item.videos = this._itemService.populateVideoPathsFromCommaSeparatedString(item.videosPath);
        item.pdfs= this._itemService.populatePdfPathsFromCommaSeparatedString(item.pdfsPath);
        this.IsItemCreatedByUser=item.isItemCreatedByManager
      });
      this.paginationFinished = x.data == null ? true : (x.data.length < this.PageSize);//x.data == null ? true : (x.data.length < this.PageSize);
    this.isLoading=false;
    });

// Get Itemlogs
// this.getItemStatusChangeLog(itemId)

  }
  public getNextItemHistory(itemId:number){
    this.pageNo +=1;
    this._itemService.getItemHistory(itemId,this.pageNo,this.PageSize,this.IsEmployeeUser,this.filterValue).subscribe((x) =>{
      if(x.data){
        x.data.forEach((item) =>{
          item.images = this._itemService.populateImagePathsFromCommaSeparatedString(item.imagePath);
          item.videos = this._itemService.populateVideoPathsFromCommaSeparatedString(item.videosPath);
          item.pdfs = this._itemService.populatePdfPathsFromCommaSeparatedString(item.pdfsPath);
          this.IsItemCreatedByUser=item.isItemCreatedByManager

          this.historyData.push(item);
        });
        this.paginationFinished = (x.data.length < this.PageSize);
      }
      else{
        this.paginationFinished = true;
      }
    });
  }

  addNotes(itemId:number){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "600px";
    dialogConfig.data = itemId;
    var dialogRef = this.dialog.open(AddNotesDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.actionName == 'Notes'){
        result.images = this._itemService.populateImagePathsFromCommaSeparatedString(result.imagePath);
        result.videos = this._itemService.populateVideoPathsFromCommaSeparatedString(result.videosPath);
        result.pdfs = this._itemService.populatePdfPathsFromCommaSeparatedString(result.pdfPath);
        this.historyData = [result].concat(this.historyData); // preappend juggarr
      }
    });
  }


  editHistoryItem(itemHistory:ItemCheckOutHistoryModel){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "600px";
    dialogConfig.data = itemHistory;
    var dialogRef = this.dialog.open(EditNotesDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        let updatedHistoryItem = this.historyData.find(x => x.id == result.id);
        if (updatedHistoryItem) {
          updatedHistoryItem.images = this._itemService.populateImagePathsFromCommaSeparatedString(result.imagePath);
          updatedHistoryItem.videos = this._itemService.populateVideoPathsFromCommaSeparatedString(result.videosPath);
          updatedHistoryItem.pdfs= this._itemService.populatePdfPathsFromCommaSeparatedString(result.pdfPath)
          this.refreshTab.emit();
        }
      }
    });
  }
  confirmDialog(itemHistory:ItemCheckOutHistoryModel): void {
    const title = `Delete History`;
    const message = `Are you sure you want to delete this History?`;

     const dialogData = new ConfirmDialogModel(title, message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "550px",
      data: dialogData,
      panelClass: 'custom-dialog'
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        // user clicked yes
        itemHistory.deleted = true;
        itemHistory.lastModifiedBy=this.loggedInUser.email.toString();

        this._itemService.deleteItemHistory(itemHistory).subscribe(res => {
          this.fetchData(this.ItemId)
          this._snackBar.open('Deleted successfully.', 'OK');
          this._dataShareService.ChangeDeleteItem(1)

        }, (errorResponse) => {
          this._snackBar.open('This entry cannot be deleted. Check-in is already done against it', 'OK');
        });
      }
    });
    // this.gridRefreshEvent.emit('refreshing');

  }


  // getItemStatusChangeLog(itemId:number)
  // {
  //   this._itemService.getItemStatusChangeLog(itemId).subscribe((x:any)=>{
  //     this.itemLogsData=x
  //     },(error)=>{
  //       this._snackBar.open('Some Error Occure While Loading Item Log','OK!')
  //     })
  // }
  GetUserAccessPermission()
  {
   this.userAccessPermission=this._storageService.GetLoginUserPermissions()
  }

  applyFilter(event: Event) {
    this.fetchData(this.ItemId);
  }

}
