import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from './../../Services/auth-service';
import { LoginRequestModel } from './LoginRequestModel';
import { LoginRespopnseModel } from './LoginRespopnseModel';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public username: string = "";
  public password: string = "";
  fieldTextType: boolean;
  loading = false;
  returnUrl: string;

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
  constructor(private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar) {

  }
  emailFormControl = new FormControl('', [Validators.required, Validators.email]);

  matcher = new ErrorStateMatcher();

  ngOnInit(): void {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }
  onSubmit(loginFormData: any) {
    var req: LoginRequestModel = { email: this.username, password: this.password };

    if (req.email == '' || req.password == '') {
      this._snackBar.open('Both Password and user name are required.', 'Please fill login form!');
      return;
    }
    this.loading = true;
    this.authService.login(req).subscribe((x) => {

      this.handleResponse(x);
    },
      err => this.handleResponse(err));
  }
  handleResponse(response: any) {

    if (response.succeeded) {
      this._snackBar.open('Login Successful!', 'Ok');
      this.loading = false;

      this.router.navigateByUrl(this.returnUrl);
    }
    else {
      this._snackBar.open(response.error.message || "Something went wrong. Try again.", 'Login Failed!');
      this.loading = false;
    }
  }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
}
