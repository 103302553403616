import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ItemModel } from 'src/app/Models/ComponentModels/ItemModel';
import { environment } from 'src/environments/environment';
import { AppURLs, AppConstants } from 'src/app/AppConstants';
import { ApiPagedResponse } from 'src/app/Models/Api-Response-Paged';
import { ApiSingleResponse } from 'src/app/Models/API-Response-Single';
import { APIResponseNonPagedSingle } from 'src/app/Models/API-Response-NonPagedSingle';
import {
  ItemCheckOutHistoryModel,
  ngVideoSliderObject,
  pfdFileObject,
} from 'src/app/Models/ComponentModels/ItemCheckOutHistoryModel';
import { CheckinCheckoutReport } from 'src/app/Models/ComponentModels/Checkin-Checkout-Report';
import { ItemLogs } from 'src/app/Models/ComponentModels/ItemLogs';

@Injectable({
  providedIn: 'root',
})
export class ItemService {
  baseURL = environment.ApiUrl;
  URLs: AppURLs;
  ItemServiceURL: string;
  ItemsCheckoutServiceURL: string;

  constructor(private _http: HttpClient) {
    this.URLs = new AppURLs(AppConstants.APIVersion);

    this.ItemServiceURL = environment.apiBaseUrl + this.URLs.ItemsURL;
    this.ItemsCheckoutServiceURL =
      environment.apiBaseUrl + this.URLs.ItemsRecordURL;
  }
  public populateImagePathsFromCommaSeparatedString(
    commaSeparatedString: string
  ) {
    var imagePaths = new Array<string>();
    if (!commaSeparatedString) {
      return new Array();
    }
    var elements = commaSeparatedString.split(',');

    if (elements && elements.length > 0) {
      elements.forEach((item) => {
        var imagePath = this.baseURL + '/' + item;
        imagePaths.push(imagePath);
      });
    }
    return imagePaths;
  }
  public populateVideoPathsFromCommaSeparatedString(
    commaSeparatedString: string
  ) {
    var videoPaths = new Array<ngVideoSliderObject>();
    if (!commaSeparatedString) {
      return new Array();
    }
    var elements = commaSeparatedString.split(',');

    if (elements && elements.length > 0) {
      elements.forEach((item) => {
        var fileNameOnly = item.split('\\')[item.split('\\').length - 1];

        var videoPath: ngVideoSliderObject = {
          video:
            this.baseURL +
            '/StreamVideo/' +
            item.split('\\')[item.split('\\').length - 1],
          posterImage: '../../../assets/images/black-poster.png',
        };
        videoPaths.push(videoPath);
      });
    }
    return videoPaths;
  }
  public populatePdfPathsFromCommaSeparatedString(
    commaSeparatedString: string
  ) {
    var pdfPaths = new Array<pfdFileObject>();
    if (!commaSeparatedString) {
      return new Array();
    }
    var elements = commaSeparatedString.split(',');

    if (elements && elements.length > 0) {
      elements.forEach((item) => {

        // Get the file name from full path remove timeStamp and other path

        const fullPath =this.baseURL + '/' + item;
        const FileName =this.GetFileNameFromURl(fullPath);


        var pdfs:pfdFileObject =
        {
         path: fullPath,
         fileName:FileName
      }

        pdfPaths.push(pdfs);
      });
    }
    console.log(pdfPaths);
    return pdfPaths;
  }

  public  GetFileNameFromURl(path:string)
  {
    const url = new URL(path);
    const fileNameWithTimestamp = url.pathname.split('/').pop();
    const FileName = fileNameWithTimestamp?.replace(/^\d+/, '');
    return FileName;
  }

  public saveItem(item: any): Observable<any> {
    return this._http.post<any>(this.ItemServiceURL, item);
  }
  public saveRecord(data: any): Observable<any> {
    return this._http.post<any>(
      this.ItemsCheckoutServiceURL + `/SaveRecord`,
      data
    );
  }
  public updateItem(formData: any): Observable<ItemModel> {
    return this._http.put<ItemModel>(this.ItemServiceURL, formData);
  }
  public deleteItem(ItemId: number): Observable<number> {
    return this._http.delete<number>(this.ItemServiceURL + '/' + ItemId);
  }
  public getItems(
    pageNo: Number,
    pageSize: Number,
    SortProperty: string,
    sortDirection: string,
    NameOrCodeFilter: string,
    statusFilter: string,
    categoryFilter: string,
    subCategoryFilter: string,
    TagIds: string,
    startDate:string,
    endDate:string,
    showDataFor:string,
    isLoginUserEmployee: boolean,
    isLoginUserManager: boolean,
    selectedUserId:string
  ): Observable<ApiPagedResponse<ItemModel>> {
    return this._http.get<ApiPagedResponse<ItemModel>>(
      this.ItemServiceURL +
        `?PageNumber=${pageNo}&PageSize=${pageSize}&SortProperty=${SortProperty}&sortDirection=${sortDirection}
    &NameOrCodeFilter=${NameOrCodeFilter}&statusFilter=${statusFilter}&categoryFilter=${categoryFilter}&subCategoryFilter=${subCategoryFilter}
    &TagIds=${TagIds}&StartDateRange=${startDate}&EndDateRange=${endDate}&showDataFor=${showDataFor}
    &isLoginUserEmployee=${isLoginUserEmployee}&isLoginUserManager=${isLoginUserManager}
    &selectedUserId=${selectedUserId}
    `
    );
  }
  public getItemHistory(
    itemId: Number,
    pageNo: Number,
    pageSize: Number,
    isLoginUserEmployee:boolean,
    FilterValue:string
  ): Observable<ApiPagedResponse<ItemCheckOutHistoryModel>> {
    return this._http.get<ApiPagedResponse<ItemCheckOutHistoryModel>>(
      this.ItemsCheckoutServiceURL +
        `?itemId=${itemId}&PageNumber=${pageNo}&PageSize=${pageSize}&isLoginUserEmployee=${isLoginUserEmployee}
        &filterValue=${FilterValue}`
    );
  }
  public getItemCheckouts(itemId:Number):Observable<ApiSingleResponse<ItemCheckOutHistoryModel>> {
    return this._http.get<ApiSingleResponse<ItemCheckOutHistoryModel>>(this.ItemsCheckoutServiceURL + `/GetItemCheckedOuts/${itemId}`);
  }

  public updateItemHistory(
    formData: any
  ): Observable<APIResponseNonPagedSingle<ItemCheckOutHistoryModel>> {
    return this._http.put<APIResponseNonPagedSingle<ItemCheckOutHistoryModel>>(
      this.ItemsCheckoutServiceURL,
      formData
    );
  }

  public getAllSpecs(): Observable<APIResponseNonPagedSingle<any>> {
    return this._http.get<APIResponseNonPagedSingle<any>>(
      this.ItemServiceURL + `/GetAllSpecs`
    );
  }
  public getItemById(
    Id: Number
  ): Observable<APIResponseNonPagedSingle<ItemModel>> {
    return this._http.get<APIResponseNonPagedSingle<ItemModel>>(
      this.ItemServiceURL + `/${Id}`
    );
  }

  public deleteItemHistory(
    formData: any
  ): Observable<ItemCheckOutHistoryModel> {
    return this._http.post<any>(
      this.ItemsCheckoutServiceURL + '/DeleteItemRecordHistory',
      formData
    );
  }

   // ************Get Item Logs**************8
   public getItemStatusChangeLog(itemId: Number): Observable<ApiPagedResponse<ItemLogs>> {
    return this._http.get<ApiPagedResponse<ItemLogs>>(
      this.ItemsCheckoutServiceURL +'/GetItemLogs/'+itemId);
  }
   // ************Create Item Logs**************8
   public CreateItemLogs(itemLogs: ItemLogs): Observable<ApiPagedResponse<ItemLogs>> {
    return this._http.post<ApiPagedResponse<ItemLogs>>(
      this.ItemsCheckoutServiceURL +'/CreateItemLogs/',itemLogs);
  }

  public ExportToExcelListOfItems(
    pageNo: Number,
    pageSize: Number,
    SortProperty: string,
    sortDirection: string,
    NameOrCodeFilter: string,
    statusFilter: string,
    categoryFilter: string,
    subCategoryFilter: string,
    isLoginUserEmployee:boolean
  ): Observable<any> {
    return this._http.get(
      this.ItemServiceURL+'/ExportToExcelListOfItems' +
        `?PageNumber=${pageNo}&PageSize=${pageSize}&SortProperty=${SortProperty}&sortDirection=${sortDirection}
    &NameOrCodeFilter=${NameOrCodeFilter}&statusFilter=${statusFilter}&categoryFilter=${categoryFilter}
    &subCategoryFilter=${subCategoryFilter}&isLoginUserEmployee=${isLoginUserEmployee}`,
    { responseType: 'blob' }
    );
  }

  public ExportToExcelListOfItemsForManagers(
    pageNo: Number,
    pageSize: Number,
    SortProperty: string,
    sortDirection: string,
    NameOrCodeFilter: string,
    statusFilter: string,
    categoryFilter: string,
    subCategoryFilter: string
  ): Observable<any> {
    return this._http.get(
      this.ItemServiceURL+'/ExportToExcelListOfItemsForManagers' +
        `?PageNumber=${pageNo}&PageSize=${pageSize}&SortProperty=${SortProperty}&sortDirection=${sortDirection}
    &NameOrCodeFilter=${NameOrCodeFilter}&statusFilter=${statusFilter}&categoryFilter=${categoryFilter}&subCategoryFilter=${subCategoryFilter}`,
    { responseType: 'blob' }
    );
  }



  public DownloadItemExcelTemplate(

  ): Observable<any> {
    return this._http.get(
      this.ItemServiceURL+'/DownloadItemExcelTemplate',
    { responseType: 'blob' }
    );
  }

  public ImportItemExcel(data: any): Observable<any> {
    return this._http.post<any>( this.ItemServiceURL + `/ImportItemExcel`, data);
  }

  public GetExcelHeaders(data: any): Observable<any> {
    return this._http.post<any>( this.ItemServiceURL + `/GetExcelHeaders`, data);
  }
  public ImportExcelDataToItems(data:any):Observable<any>{
    return this._http.post<any>(this.ItemServiceURL+`/ImportExcelDataToItems`,data)
  }

  public RemoveSavedItemExcelFile(filePath:string):Observable<any>{
    return this._http.get<any>(this.ItemServiceURL+'/RemoveSavedItemExcelFile'+`?path=${filePath}`)
  }
}
