import { UserModel } from "./UserModel";

export class ItemCheckOutHistoryModel{
  id:number;
  ItemId:number;
  itemName:string;
  qtyChanged:number;
  notes:string;
  issuedLocation:string;
  expiry:Date;
  actionDate:Date;
  imagePath:string;
  images:string[];
  pdfsPath:string;
  //pdfs:string[];
  pdfs:Array<pfdFileObject>;
  videosPath:string;
  videos:Array<ngVideoSliderObject>;
  actionName:string;
  issuedToUserId:string;
  issuedToUser:UserModel;
  issuedToUserFirstName:string;
  issuedToUserLastName:string;
  issuedToUserEmpId:string;
  issuedByUser:UserModel;
  issuedByUserFirstName:string;
  issuedByUserLastName:string;
  issuedByUserEmpId:string;
  created:Date;
  createdBy:UserModel;
  createdByUserFirstName:string;
  createdByUserLastName:string;
  createdByUserEmpId:string;
  deleted:boolean;
  lastModifiedBy:string;
  isItemCreatedByManager:boolean
}
export class ngVideoSliderObject{
  video:string;
  posterImage:string;
  // alt:string;
  // title:string;
  // order:number;
}

export class pfdFileObject{
  path:string;
  fileName?:string;
  // alt:string;
  // title:string;
  // order:number;
}
