import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Inject,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ItemCheckOutHistoryModel } from 'src/app/Models/ComponentModels/ItemCheckOutHistoryModel';
import { ItemModel } from 'src/app/Models/ComponentModels/ItemModel';
import { UserModel } from 'src/app/Models/ComponentModels/UserModel';
import { ItemService } from 'src/app/Services/component-services/item.service';
import { StorageService } from 'src/app/Services/storage-service.service';
import { ItemDetailsHistoryComponent } from '../item-details-history/item-details-history.component';

@Component({
  selector: 'app-edit-notes-dialog',
  templateUrl: './edit-notes-dialog.component.html',
  styleUrls: ['./edit-notes-dialog.component.scss'],
})
export class EditNotesDialogComponent implements OnInit {
  sizeExceeded: boolean = false;
  fileToUpload: any;
  notesText: string;
  uploadFileControlText: string = 'Browse File';
  loading: boolean = false;
  @ViewChild('fileInput') fileInput: ElementRef;
  fileAttr = 'Choose File';
  filesToUpload: File[];
  OldfilePath: string[];

  OldItemImagePath: string[] = [];
  OldItemVideoPath: string[] = [];
  OldItemPdfPath: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<EditNotesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ItemCheckOutHistoryModel,
    private _itemService: ItemService,
    private _storageService: StorageService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.notesText = this.data?.notes;
   console.log(this.data);

    // ! if old images then split each image comma seperted into array
    if (this.data.imagePath) {
      this.OldItemImagePath = this.data?.imagePath.split(',');
      //  this.RemoveStringFromImageFilePath();
      this.RemoveFilePath('Images');
    }
    // ! if old videos then split each image comma seperted into array
    if (this.data.videosPath) {
      this.OldItemVideoPath = this.data?.videosPath.split(',');
    this.RemoveFilePath('Videos');
    //  this.RemoveStringFromVideoFilePath();
    }
   // ! if old pdfs then split each pdf comma seperated into array

    if(this.data.pdfsPath)
    {
      this.OldItemPdfPath=this.data?.pdfsPath.split(',');
      this.RemoveFilePath('Files');
    }
  }
  close() {
    this.dialogRef.close();
  }
  videoFileSelected: boolean = false;
  uploadFileEvt(imgFile: any) {
    this.filesToUpload = new Array();

    if (imgFile.target.files) {
      var fileNames = '';

      for (var i = 0; i < imgFile.target.files.length; i++) {
        var size = imgFile.target.files[i].size;
        var fileType = imgFile.target.files[i].name.split('.').pop();
        this.videoFileSelected = !(
          fileType.toLowerCase() == 'jpg' ||
          fileType.toLowerCase() == 'jpeg' ||
          fileType.toLowerCase() == 'png'
        );
        size = size / (1024 * 1024);

        if (size > 4 && !this.videoFileSelected) {
          this.fileInput.nativeElement.value = '';
          this.uploadFileControlText = 'Choose File';
          this.sizeExceeded = true;
          return;
        } else {
          this.sizeExceeded = false;
        }
        if (fileNames != '') {
          fileNames = fileNames + ',';
        }
        fileNames = fileNames + imgFile.target.files[i].name;
        this.filesToUpload.push(imgFile.target.files[i]);
      }

      this.fileAttr = fileNames;
      this.uploadFileControlText = fileNames;
    } else {
      //this.fileAttr = 'Choose File';
    }
  }
  editItemHistory(form: any) {
    var formData = new FormData();

    formData.append('Id', this.data?.id.toString());
    formData.append('Notes', this.notesText);
    if (this.OldItemVideoPath) {
      //! Add Resourse/Vidoes with VideoFileName path
        this.AddFilePath('Videos');
      formData.append('OldItemVideoPath', this.OldItemVideoPath?.toString());
    }
    if (this.OldItemImagePath) {
      //! Add Resourse/Images with ImageFileName path
      this.AddFilePath('Images');
      formData.append('OldItemImagePath', this.OldItemImagePath?.toString());
    }
    if(this.OldItemPdfPath)
    {
      //! Add Resourse/Files with  path
     this.AddFilePath('Files');
     formData.append('OldItemPdfPath', this.OldItemPdfPath?.toString());

    }

    if (this.filesToUpload && this.filesToUpload.length > 0) {
      this.filesToUpload.forEach(function (item) {
        formData.append('file', item, item.name);
      });
    } else {
      formData.delete('file');
    }
    this.loading = true;
    this._itemService.updateItemHistory(formData).subscribe(
      (x) => {
        this._snackBar.open('updated successfully!');
        this.filesToUpload = [];
        this.loading = false;
        this.data.notes = this.notesText;
        this.data.imagePath = x.data?.imagePath;
        this.data.videosPath = x.data?.videosPath;
        this.dialogRef.close(x.data);
      },
      (response) => {
        this.loading = false;
        this._snackBar.open(response.error.Message || 'Something went wrong');
      }
    );
  }
  // ! Remove the  images/Videos from array when clic on cross button
  OnNewFileRemove(file: any) {
    this.filesToUpload.filter((x) => {
      if (x.name == file.name) {
        this.filesToUpload.splice(this.filesToUpload.indexOf(x), 1);
      }
    });
  }

  // ! Remove the Old Videos from array when clic on cross button
   OnOldVideoRemove(file: any) {
    this.OldItemVideoPath.splice(this.OldItemVideoPath.indexOf(file), 1);
  }
  // ! Remove the Old images from array when clic on cross button
  OnOldImageRemove(file: any) {
    this.OldItemImagePath.splice(this.OldItemImagePath.indexOf(file), 1);
  }

  // ! Remove the Old Pdf from array when clic on cross button
  OnOldPdfRemove(file: any) {
    this.OldItemPdfPath.splice(this.OldItemPdfPath.indexOf(file), 1);
  }


//! Add the path to the file name
  AddFilePath(fileType:string)
  {
    let NewFilePaths: string[] = [];
    let pathAdd= 'Resources\\'+fileType+'\\';
    if(fileType=='Videos')
    {
     this.OldItemVideoPath.map((path) => {
       let newPath=  pathAdd+ path;
       NewFilePaths.push(newPath);
      });
    this.OldItemVideoPath=NewFilePaths
    }
    if(fileType=='Images')
     {
    this.OldItemImagePath.map((path) => {
      let newPath=  pathAdd+ path;
      NewFilePaths.push(newPath);
      });
      this.OldItemImagePath = NewFilePaths;
       }

       if(fileType=='Files')
       {
      this.OldItemPdfPath.map((path) => {
        let newPath=  pathAdd+ path;
        NewFilePaths.push(newPath);
        });
        this.OldItemPdfPath = NewFilePaths;
         }
  }

  //! Remove the path from the file name
  RemoveFilePath( fileType:string)
  {

    let NewFilePaths: string[] = [];
    let pathRemove= 'Resources\\'+fileType+'\\';

     if(fileType=='Videos')
     {
      this.OldItemVideoPath.map((path) => {
        let newPath=   path.replace(pathRemove, '');
        NewFilePaths.push(newPath);
       });
     this.OldItemVideoPath=NewFilePaths
     }
     if(fileType=='Images')
     {
    this.OldItemImagePath.map((path) => {
      let newPath=   path.replace(pathRemove, '');
      NewFilePaths.push(newPath);
      });
      this.OldItemImagePath = NewFilePaths;
       }

       if(fileType=='Files')
       {
      this.OldItemPdfPath.map((path) => {
        let newPath=   path.replace(pathRemove, '');
        NewFilePaths.push(newPath);
        });
        this.OldItemPdfPath = NewFilePaths;
         }

  }

  RemoveTimeStampFromFileName(fileName:string)
  {
    return fileName?.replace(/^\d+/, '');
  }

}
