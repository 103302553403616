<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="tree-controls">
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding  class="nested-tree-node"
    >

    <a routerLink="{{node.link}}"   routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:
      true}" >
      <span class="nodetext"> {{node.name}} </span>
    </a>
      <!-- use a disabled button to provide padding for tree leaf -->
      <button mat-icon-button disabled></button>
    </mat-tree-node>

    <!-- This is the tree node template for expandable nodes -->
    <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding matTreeNodeToggle class="
        {{treeControl.isExpanded(node) ? 'node-active' : ''}}">
      <div >
        <mat-icon class="parentnode-icon" aria-hidden="true" aria-label="icon">{{node.sidebaricon}}</mat-icon>
        <span class="nodetext"> {{node.name}}</span>
        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
      </div>
    </mat-tree-node>
  </mat-tree>
