<mat-drawer-container class="drawer-container">

  <mat-drawer class="app-drawer col-lg-2" mode="side" opened>
    <div class="app-header">Asset Management</div>
    <app-sidebarnav class="app-sidebarnav"></app-sidebarnav>
    <div class="app-footer">
      <i class="copyright-logo"></i>
      <span class="copyright-text">© 2022 DPL. All Rights Reserved</span>
    </div>
  </mat-drawer>
  <mat-drawer-content class="">
    <mat-toolbar color="primary">
      <!-- <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
        <mat-icon>menu</mat-icon>
      </button> -->
      <div class="rightmenu" [matMenuTriggerFor]="menu">
        <div class="user-profile"></div>
        <button class="username-text" type="button"  mat-button>
          {{loggedInUser.fullName}}
        </button>
        <button mat-icon-button aria-label="Burger">
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item type="button" (click)="logout()">
            <mat-icon>exit_to_app</mat-icon>
            <span>Logout</span>
          </button>

        </mat-menu>

      </div>
    </mat-toolbar>
    <div class="components-wrapper">

      <div class="breadchrumbs-block">
        <app-breadcrumb></app-breadcrumb>
           <!-- <h4 *ngIf="!isReadItemAccess" style="color: red">You don't have enough rights</h4> -->
      </div>
     <router-outlet></router-outlet>
    </div>


  </mat-drawer-content>
</mat-drawer-container>

