import {
  AfterViewInit,
  OnInit,
  Component,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { UserModel } from 'src/app/Models/ComponentModels/UserModel';
import { UserService } from 'src/app/Services/component-services/user-service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UserRole } from 'src/app/Models/ComponentModels/UserRole';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { StorageService } from 'src/app/Services/storage-service.service';
import { UserAccessPermissions } from 'src/app/Models/AccessPermissions';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
})
export class UsersListComponent implements AfterViewInit {
  isLoading = true;
  isDetails = false;
  filterValue = '';
  userType = 'Active';
  userRole = 'All';
  pageSize = 25;
  currentPage = 1;
  allRoles: UserRole[];
  notAllowIMS: boolean = false;
  toogleToolTip:boolean=false;
  UserModelResponse:UserModel;
  recordCount:any;
  searchStart:boolean=false;


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = [
    'First Name',
    'Last Name',
    'Email',
    'EmployeeId',
    'Phone',
    'Address',
    'Address2',
    'Allow IMS'

  ];
  userData: MatTableDataSource<UserModel> = new MatTableDataSource();
  searcheduser: UserModel = new UserModel();
  userAccessPermission:UserAccessPermissions

  constructor(
    private _userService: UserService,
    private changeDetectorRefs: ChangeDetectorRef,
    private _snackBar: MatSnackBar,
    private _router: Router,
    private _StorageService: StorageService
  ) {
    this.userData = new MatTableDataSource<UserModel>();
    this.UserModelResponse=new UserModel();
    this.userAccessPermission=new UserAccessPermissions();

  }
  ngOnInit() {
    this.populateUsersDataGrid();
    this.AllowIMSAccess();
    this.getAllRoles();
    this.GetUserAccessPermission();


  }
  ngAfterViewInit() {
    this.userData.paginator = this.paginator;
  }
  applyFilters() {
    //this.userData.filter = this.filterValue.trim().toLowerCase();
    this.populateUsersDataGrid();
    if (this.userData.paginator) {
      this.userData.paginator.firstPage();
    }
    this.AllowIMSAccess();
  }
  populateUsersDataGrid() {
    this.isLoading = true;
    this._userService
      .getUsers(
        this.currentPage,
        this.pageSize,
        this.filterValue,
        this.userType,
        this.userRole
      )
      .subscribe(
        (x) => {



                  //  const UsersRoles=this.UserModelResponse.roles.map(res=>
                  //    res.id
                  //  )

  //         this.UserModelResponse=JSON.parse(JSON.stringify(x.data))
  //        x.data?.filter(res=>{
  //          res.roles.forEach(role=>{
  //            role.name=='Employee'
  //          })

  //          if()
  //          {
  //         this.notAllowIMS = false;
  //       }
  //        })

          this.userData = new MatTableDataSource<UserModel>(x.data);
          this.recordCount=(x.data)?.length;
          this.searchStart=true;

          setTimeout(() => {
            this.paginator.pageIndex = this.currentPage - 1; // index starts with zero
            this.paginator.length =
              x.totalRecords == undefined ? 0 : x.totalRecords;
          });

          this.userData.paginator = this.paginator;
          this.userData.sort = this.sort;
          this.isLoading = false;
        },
        (response) => {
          this._snackBar.open(
            response.error.Message || 'Could not load data. Try again.',
            'Ok'
          );
          this.isLoading = false;
        }
      );
  }
  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex + 1;
    this.populateUsersDataGrid();
  }
  AdDataLoading = false;
  pullAdData() {
    this.AdDataLoading = true;
    this._snackBar.open(
      'This Operation can take long time. Check below table data after a while.',
      'Ok'
    );
    this._userService.pullADData().subscribe((x) => {
      this.populateUsersDataGrid();
      this.AdDataLoading = false;
    });
  }

  OpenEditForm(user: MatSlideToggleChange) {}

  Changetoggle(e: any, user: any) {

    this.isLoading = true;
    this._userService.UpdateIMSAccess(user).subscribe(
      (res) => {
        this.isLoading = false;
        this._snackBar.open('User Updated successfully.', 'Ok');
      },
      (errorResponse) => {
        // this.isLoadingProg = false;
        this.isLoading = false;
        this._snackBar.open(
          'Some Error Occures While Updating Record' +
            errorResponse.error.Message,
          'Ok!'
        );
      }
    );
  }

  AllowIMSAccess() {
    let email = this._StorageService.GetLoginUserEmail();
    var roles=[];
      this._userService.getLoginUserRoles(email).subscribe((x) => {
       roles=x;
       if (roles.indexOf('SuperAdmin') > -1) {
        this.notAllowIMS = false;
        this.toogleToolTip=true;

      } else {
        this.notAllowIMS=true
        this.toogleToolTip=false;

      }
    });
  }

  getAllRoles() {
    this._userService.getUserRoles().subscribe((x) => {
      this.allRoles = x.data;

    });
  }

  GetUserAccessPermission()
  {
   this.userAccessPermission=this._StorageService.GetLoginUserPermissions()

  }

}
