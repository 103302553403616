import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { LocationModel } from 'src/app/Models/ComponentModels/LocationModel';
import { CategoryModel } from 'src/app/Models/ComponentModels/Category';
import { SubCategoryModel } from 'src/app/Models/ComponentModels/SubCategory';
import { UserModel } from 'src/app/Models/ComponentModels/UserModel';
import { LocationService } from 'src/app/Services/component-services/location-service';
import { CategoryService } from 'src/app/Services/component-services/category-Service';
import { SubCategoryService } from 'src/app/Services/component-services/subcategory-service';
import { UserService } from 'src/app/Services/component-services/user-service';
import { ItemService } from 'src/app/Services/component-services/item.service';
import { ItemModel } from 'src/app/Models/ComponentModels/ItemModel';
import { NameValuePair } from 'src/app/Models/NameValuePair';
import { map, startWith } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AddCategoryComponent } from '../categories/add-category/add-category.component';
import { AddSubCategoryComponent } from '../categories/add-sub-category/add-sub-category.component';
import { DataShareService } from 'src/app/Services/data-share.service';
import { StorageService } from 'src/app/Services/storage-service.service';

import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { TagService } from 'src/app/Services/component-services/tag.service';
import { Tag } from 'src/app/Models/ComponentModels/Tag';
import { TileStyler } from '@angular/material/grid-list/tile-styler';

@Component({
  selector: 'app-items-add',
  templateUrl: './items-add.component.html',
  styleUrls: ['./items-add.component.css'],
})
export class ItemsAddComponent implements OnInit {
  SpecificationNameControl = new FormControl();
  SpecificationValueControl = new FormControl();
  responsibleUserControl = new FormControl();

  formData: ItemModel;
  @ViewChild('fileInput') fileInput: ElementRef;
  fileAttr = 'Choose File';
  Categories: CategoryModel[] = [];
  SubCategories: SubCategoryModel[] = [];
  Locations: LocationModel[] = [];

  itemSpecFormField: NameValuePair = new NameValuePair();
  ItemSpecsDB: string[] = new Array<string>();
  ItemValuesDB: string[] = new Array<string>();
  itemSpecs: NameValuePair[] = new Array<NameValuePair>();
  itemValues: NameValuePair[] = new Array<NameValuePair>();

  isLoading: boolean = false;

  filteredOptions: Observable<string[]> | undefined;

  specsFilter: Observable<string[]>;
  valuesFilter: Observable<string[]>;

  admins: UserModel[] = new Array<UserModel>();
  adminsFilter: Observable<UserModel[]>;

  CategoryIDFromAddCategory: number;
  SubCategoryIDFromAddCategory: number;
  Currencies: string[] = ['PKR', 'USD', 'Lira'];

  CurrencySysmbls: any[];
  today = new Date();

//! chips
separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl();
  filteredFruits: Observable<string[]>;
  tags: string[] = [];

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;


  TagResponse:Tag[]=[]
  tagNameSerch:string='';

   IsManagerUser:boolean=false;
locationDisabled:boolean=false;
  constructor(
    private _locationService: LocationService,
    private _categoryService: CategoryService,
    private _subcategoryService: SubCategoryService,
    private _userService: UserService,
    private _itemService: ItemService,
    private _snackBar: MatSnackBar,
    private _router: Router,
    public dialog: MatDialog,
    public _dataShareService: DataShareService,
    private _storageService: StorageService,
    private _tagService :TagService,


  ) {
    this._dataShareService.currentMessage.subscribe(
      (CategoryId) => (this.CategoryIDFromAddCategory = CategoryId)
    );
    this._dataShareService.SubCategoryID.subscribe(
      (SubCategoryId) => (this.SubCategoryIDFromAddCategory = SubCategoryId)
    );
    this.formData = new ItemModel();

//! chips
// this.filteredFruits = this.tagCtrl.valueChanges.pipe(
//   startWith(null),
//   map((fruit: string | null) => (fruit ? this._filter(fruit) : this.allFruits.slice())),
// );

  }

  ngOnInit(): void {



    this._dataShareService.currentMessage.subscribe(
      (CategoryId) => (this.CategoryIDFromAddCategory = CategoryId)
    );
    this._dataShareService.SubCategoryID.subscribe(
      (SubCategoryId) => (this.SubCategoryIDFromAddCategory = SubCategoryId)
    );

    this.formData.status = 'In Stock';
    this.populateCategories();
    this.populateLocations();

    this._userService.getallAdminsAndManagers().subscribe((x) => {

      this.admins = x.data;
      // If logined User is Admin then him name will auto populate on Reponsible
      let LoginedUserID = this._storageService.GetLoggedInUser().id;
      let LoginedUserRoles = this._storageService.GetLoggedInUser().roles;
      if (LoginedUserRoles.indexOf('Admin') > -1  || LoginedUserRoles.indexOf('Manager') > -1) {
        this.responsibleUserControl.setValue(LoginedUserID);
      }

      this.adminsFilter = this.responsibleUserControl.valueChanges.pipe(
        startWith(''),
        map((value) => this.responsible_user_filter(value))
      );
    });

    this._itemService.getAllSpecs().subscribe((x) => {
      this.ItemSpecsDB = x.data.specNames; //data.map(function(a: { name: any; }) {return a.name;}) || new Array();
      this.ItemValuesDB = x.data.valueNames;

      this.specsFilter = this.SpecificationNameControl.valueChanges.pipe(
        startWith(''),
        map((value) => this.spec_filter(value))
      );
      this.valuesFilter = this.SpecificationValueControl.valueChanges.pipe(
        startWith(''),
        map((value) => this.spec_values_filter(value))
      );
    });

this.GetAllTags('')

this.IsManagerUser= this._storageService.IsLoginUserManager()

if(this.IsManagerUser)
{
  this.responsibleUserControl.disable();

}

  }


  private spec_filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.ItemSpecsDB.filter(
      (option) => option.toLowerCase().indexOf(filterValue) === 0
    );
  }
  private spec_values_filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.ItemValuesDB.filter(
      (option) => option.toLowerCase().indexOf(filterValue) === 0
    );
  }
  private responsible_user_filter(value: string): UserModel[] {
    const filterValue = value.toLowerCase();
    return this.admins.filter(
      (option) =>
        option.firstName.toLowerCase().startsWith(filterValue) ||
        option.lastName.toLowerCase().startsWith(filterValue)
    );
  }
  private populateLocations() {
    this._locationService.getAllLocations().subscribe(
      (x) => (this.Locations = x.data),
      (response) => {
        this._snackBar.open(response.error.Message);
      }
    );
  }
  private populateCategories() {
    this._categoryService.getAllCategories().subscribe(
      (x) => {
        this.Categories = x.data; //== undefined ? new CategoryModel[]() : x.data;
      },
      (response) => {
        this._snackBar.open(response.error.Message);
      }
    );
  }
  private populateSubCategories(CategoryId: Number) {
    this._subcategoryService.getAllSubCategories(CategoryId).subscribe(
      (x) => {
        this.SubCategories = x.data;
      },
      (response) => {
        this._snackBar.open('Please Select the Category', 'OK');
      }
    );
  }
  sizeExceeded: boolean = false;
  videoFileSelected: boolean = false;
  uploadFileEvt(imgFile: any) {
    if (imgFile.target.files && imgFile.target.files[0]) {
      var size = imgFile.target.files[0].size;
      var fileType = imgFile.target.files[0].name.split('.').pop();
      this.videoFileSelected = !(
        fileType.toLowerCase() == 'jpg' ||
        fileType.toLowerCase() == 'jpeg' ||
        fileType.toLowerCase() == 'png'
      );
      size = size / (1024 * 1024);

      if (size > 4 && !this.videoFileSelected) {
        this.fileInput.nativeElement.value = '';
        this.fileAttr = 'Choose File';
        this.sizeExceeded = true;
        return;
      } else {
        this.sizeExceeded = false;
      }
      this.fileToUpload = imgFile.target.files[0];
      this.fileAttr = this.fileToUpload.name;
    } else {
      //this.fileAttr = 'Choose File';
    }
  }
  fileToUpload: any;
  saveItem(form: NgForm) {

    if (form.valid) {
      var itemFormData = form.value;
      debugger
      if (!itemFormData.location) {
debugger
        // IF Liancance Category is selected then select Cloud Location By Defult
        if(this.formData.categoryId==1)
        {

           itemFormData.location=this.formData.locationId
        }
        else{
        this._snackBar.open('Location is required.');
        return;
      }
      }
      if (!itemFormData.Category) {
        this._snackBar.open('Category is required.');
        return;
      }
      var data = new FormData();
      data.append('Name', itemFormData.ItemName);
      data.append('CategoryId', itemFormData.Category);
      data.append('Code', itemFormData.Code || '');
      data.append('Model', itemFormData.ItemModel || '');
      data.append('SerialNo', itemFormData.SerialNo || '');
      data.append('Description', itemFormData.Description || '');
      data.append('LocationId', itemFormData.location);

      if(!this.IsManagerUser)
      {
        data.append('ProcurmentURL', itemFormData.ProcurementURL);

      }
      data.append('Status', 'In Stock');
      if(this.IsManagerUser)
      {
        data.append('Quantity', '1');

      }else{
        data.append('Quantity', itemFormData.Quantity);

      }
      data.append('ResponsibleUserId', this.responsibleUserControl.value);
      // data.append('Tags',this.tags.toString())
      if(itemFormData.PurchaseCost && !this.IsManagerUser)
      {
        data.append('PurchaseCost', itemFormData.PurchaseCost);
        data.append('Currency', itemFormData.Currency);
      }

      // If date in not undefiend teh convert it to tolocaldatestrign fromate
      if (itemFormData.PurchaseDate != undefined && !this.IsManagerUser) {
        let date = new Date(itemFormData.PurchaseDate);
        data.append('PurchaseDate', date.toLocaleDateString());
      }


      if (itemFormData.SubCategory) {
        data.append('SubCategoryId', itemFormData.SubCategory);
      }
      var specNamesList = '';

      this.itemSpecs.forEach((item, i) => {
        specNamesList += item.name + ':' + item.value + ',';
      });

      specNamesList = specNamesList.substring(0, specNamesList.length - 1);

      if (this.itemSpecs.length > 0) {
        data.append('specNamesList', specNamesList);
      }
      if (this.fileToUpload) {
        data.append('file', this.fileToUpload, this.fileToUpload.name);
      } else {
        data.delete('file');
      }
      this.isLoading = true;
      this._itemService.saveItem(data).subscribe(
        (x) => {
          this.isLoading = false;
          if (this.videoFileSelected) {
            this._snackBar.open(
              'Success. File is processing at server and may take long time. Refresh this page after a while.'
            );
          } else {
            this._snackBar.open('Item Added Successfully', 'OK');
            // ! If item is addedd then add tags with item by passing tags and item id
            this._tagService.CreateTagsWithItems(this.tags.toString(),x.id);

          }
          this._router.navigate(['/home/items']);
        },
        (response) => {
          this.isLoading = false;
          this._snackBar.open(response.error.Message);
        }
      );
    } else {
      this._snackBar.open('From is invalid');
    }
  }


  displayFn(options: UserModel[]): (id: string) => string {
    return (id: string) => {
      const correspondingOption = Array.isArray(this.admins)
        ? this.admins.find((option) => option.id === id)
        : null;
      return correspondingOption
        ? correspondingOption.firstName + ' ' + correspondingOption.lastName ||
            ''
        : '';
    };
  }
  AddSpecification() {
    if (this.itemSpecFormField.name && this.itemSpecFormField.value) {
      var data = new NameValuePair();
      data.name = this.itemSpecFormField.name;
      data.value = this.itemSpecFormField.value;
      this.itemSpecs.push(data);
    }
    this.itemSpecFormField.name = '';
    this.itemSpecFormField.value = '';
  }
  DeleteSpecification(specName: string, specValue: string) {
    if (specName && specValue) {
      this.itemSpecs.forEach((spec, index) => {
        if (spec.name == specName && spec.value == specValue)
          this.itemSpecs.splice(index, 1);
      });
    }
  }
  //Hamza
  //Open AddNewCategoryModal
  AddNewCategory() {
    const dialogRef = this.dialog.open(AddCategoryComponent, {
      minWidth: '32vw',
    });

    var resp = dialogRef.afterClosed().subscribe((dialogResult) => {
      dialogRef._containerInstance;
      this.populateCategories();

      // let catid=this.formData.categoryId;

      if (this.CategoryIDFromAddCategory) {
        this.formData.categoryId = this.CategoryIDFromAddCategory;
      }
    });
  }

  //Open AddNewSubCategoryModal
  AddSubCategory() {
    const dialogRef = this.dialog.open(AddSubCategoryComponent, {
      minWidth: '30vw',
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      this.populateSubCategories(this.formData.categoryId);
      if (this.SubCategoryIDFromAddCategory) {
        this.formData.subCategoryId = this.SubCategoryIDFromAddCategory;
      }
    });
  }

//! chips

add(event: MatChipInputEvent): void {


  const value = (event.value || '').trim();
  // Add our fruit
  if (value) {
    if(this.tags.indexOf(value)==-1)
    {
      this.tags.push(value);
    }
  }
  // Clear the input value
  event.chipInput!.clear();
  this.tagCtrl.setValue(null);
}

remove(tag: string): void {
  const index = this.tags.indexOf(tag);
  if (index >= 0) {
    this.tags.splice(index, 1);
  }
}

selected(event: MatAutocompleteSelectedEvent): void {
  if(this.tags.indexOf(event.option.viewValue)==-1)
  {
    this.tags.push(event.option.viewValue);
  this.tagInput.nativeElement.value = '';
  this.tagCtrl.setValue(null);
  }
  this.tagInput.nativeElement.blur();

}

GetAllTags(tagName?:string)
{
  var data = new FormData();
if(tagName==undefined || tagName==null)
{
  tagName='';
}
data.append('ItemName', tagName);
  this._tagService.GetAllTags(data).subscribe(res=>{
    this.TagResponse=res;
  })
}

applyFilter()
{
  this.GetAllTags(this.tagNameSerch);
}



updateSubCategories(event: any) {
  var selectedCategoryId = event.value;
  this.populateSubCategories(selectedCategoryId);
      this.IsDisabledLocaton()
}

IsDisabledLocaton()
{
  if(this.formData.categoryId==1)
    {
      this.locationDisabled=true;
      this.formData.locationId=8

    }
   else{
  this.locationDisabled=false;
    }
}


}
