import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import { of } from 'rxjs';
import { LocationModel } from 'src/app/Models/ComponentModels/LocationModel';
import { AppURLs } from 'src/app/AppConstants';
import { AppConstants } from 'src/app/AppConstants';
import { environment } from 'src/environments/environment';
import { ApiSingleResponse } from 'src/app/Models/API-Response-Single';
import { APIResponseNonPagedSingle } from 'src/app/Models/API-Response-NonPagedSingle';
import { ApiPagedResponse } from 'src/app/Models/Api-Response-Paged';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  URLs:AppURLs;
  locationServiceURL:string;

  constructor(private _http:HttpClient) {
    this.URLs = new AppURLs(AppConstants.APIVersion);

    this.locationServiceURL = environment.apiBaseUrl + this.URLs.LocationsURL;
   }
   public saveLocation(data:any):Observable<any> {
    return this._http.post<any>(this.locationServiceURL,data);
  }
  public updateLocation(formData:any):Observable<number>{
    return this._http.put<number>(this.locationServiceURL,formData);
  }
  getAllLocations():Observable<ApiSingleResponse<LocationModel>>{
    return this._http.get<ApiSingleResponse<LocationModel>>(this.locationServiceURL);
  }

  public getLocations(pageNo:Number, pageSize:Number, NameOrCodeFilter:string):Observable<ApiPagedResponse<LocationModel>> {
    return this._http.get<ApiPagedResponse<LocationModel>>(this.locationServiceURL + `?PageNumber=${pageNo}&PageSize=${pageSize}&NameOrCodeFilter=${NameOrCodeFilter}`);
  }

  getLocationById(Id:number):Observable<APIResponseNonPagedSingle<LocationModel>>{
    return this._http.get<APIResponseNonPagedSingle<LocationModel>>(this.locationServiceURL + '/' + Id);
  }

  public deleteLocation(locationId:number):Observable<number>{
    return this._http.delete<number>(this.locationServiceURL + '/' + locationId);
  }
  public ExportToExcelListOfLocations(

    ): Observable<any> {
      return this._http.get(
        this.locationServiceURL+'/ExportToExcelListOfLocations',
      { responseType: 'blob' }
      );
    }
}
