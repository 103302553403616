import { Component, OnInit } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LocationModel } from 'src/app/Models/ComponentModels/LocationModel';
import { UserModel } from 'src/app/Models/ComponentModels/UserModel';
import { LocationService } from 'src/app/Services/component-services/location-service';
import { UserService } from 'src/app/Services/component-services/user-service';

@Component({
  selector: 'app-location-edit',
  templateUrl: './location-edit.component.html',
  styleUrls: ['./location-edit.component.scss']
})
export class LocationEditComponent implements OnInit {

  isLoading:boolean = false;

  Location = new LocationModel();

  admins: UserModel[] = new Array<UserModel>();
  adminsFilter: Observable<UserModel[]>;

  responsibleUserControl = new FormControl();
  addressControl = new FormControl();

  constructor(
    private _userService: UserService,
    private _locationService: LocationService,
    private _snackBar: MatSnackBar,
    private _router: Router,
    private route: ActivatedRoute,
    ) { }

  ngOnInit(): void {

    const locationId = this.route.snapshot.paramMap.get('id');

    if(locationId){
      this._locationService.getLocationById(+locationId).subscribe((x)=>{
        this.Location = x.data;
        this._userService.getallAdmins().subscribe((x) => {
          this.admins = x.data;

        this.responsibleUserControl.setValue(this.Location.responsibleUserId);
          this.adminsFilter = this.responsibleUserControl.valueChanges.pipe(
            startWith(''),
            map(value => this.responsible_user_filter(value)
          ));
        });
      });
    }


  }

  displayFn(options: UserModel[]): (id: string) => string {
    return (id: string) => {

      const correspondingOption = Array.isArray(this.admins) ? this.admins.find(option => option.id === id) : null;
      return correspondingOption ? correspondingOption.firstName + ' '  + correspondingOption.lastName || '': '';
    }
  }
  private responsible_user_filter(value: string): UserModel[] {
    const filterValue = value.toLowerCase();
    return this.admins.filter(option => option.firstName.toLowerCase().startsWith(filterValue) || option.lastName.toLowerCase().startsWith(filterValue) );
  }

  saveLocation(form: NgForm){
    if (form.valid) {
      var data = new FormData();
      data.append('Id', this.Location.id.toString());
      data.append('Name', this.Location.name);
      data.append('Address', this.Location.address);
      data.append('Phone', this.Location.phone);
      data.append('ResponsibleUserId', this.Location.responsibleUserId);

      this._locationService.updateLocation(data).subscribe((x)=>{

        this._snackBar.open('Location updated successfully!');
        this._router.navigate(['/home/locations']);
      }, (response) => {
        this._snackBar.open(response.error.Message);
      });
    }
  }
}
