export class ItemLogs {
  itemId: number;
  notes: string;
  createdBy:string;
  itemStatus:string;
  itemNewStatus:string;
  itemPreviousStatus:string;
  constructor()
  {
   this.itemId=0;
   this.notes='';
   this.createdBy='';
   this.itemStatus='';
   this.itemNewStatus='';
   this.itemPreviousStatus=''
 }
}
