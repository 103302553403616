import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonTasksService {

  constructor() { }

  getMySQLformatDate(str:string){
    if(!str){return '';}
    var date = new Date(str),
    mnth = ("0" + (date.getMonth()+1)).slice(-2),
    day  = ("0" + date.getDate()).slice(-2);
   var hours  = ("0" + date.getHours()).slice(-2);
   var minutes = ("0" + date.getMinutes()).slice(-2);
   var seconds  = ("0" + date.getSeconds()).slice(-2);
   var year = date.getFullYear();
    return `${year}/${mnth}/${day} ${hours}:${minutes}:${seconds}`
 }
}
