import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs/internal/Observable';
import { map, startWith } from 'rxjs/operators';
import { AccessGroupModel } from 'src/app/Models/ComponentModels/AccessGroupModel';
import { CategoryModel } from 'src/app/Models/ComponentModels/Category';
import { AccessGroupService } from 'src/app/Services/component-services/access-group-service';
import { CategoryService } from 'src/app/Services/component-services/category-Service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessPermission, UserAccessPermissions } from 'src/app/Models/AccessPermissions';

@Component({
  selector: 'app-add-access-group',
  templateUrl: './add-access-group.component.html',
  styleUrls: ['./add-access-group.component.scss']
})
export class AddAccessGroupComponent implements OnInit {
  isLoading:boolean = true;
  isEditForm = false;
  id = -1;
  name = "";
  selectedCategories = new Array<CategoryModel>();
   accessPermissionList :any=new Array<number>();
   userAccessPermissions:UserAccessPermissions
   Permissions=new Array<AccessPermission>();

  categories: CategoryModel[] = new Array<CategoryModel>();
  categoriesFilter: Observable<CategoryModel[]>;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  categoriesControl = new FormControl();
  @ViewChild('categoriesInput') categoriesInput: ElementRef<HTMLInputElement>;

  checked:boolean=true


//* Main Sections
isCategory:boolean=false;
isItem:boolean=false;
isReport:boolean=false;
isMaintenance:boolean=false;
isUsers:boolean=false;

//* Sub Sections
isShowAccessCatagories:boolean=true;
isItemManagement:boolean=false;
isItemMovement:boolean=false;
isActivityReport:boolean=false;
isActiveCheckoutReport:boolean=false;
isLocations:boolean=false;
isCategories:boolean=false;
isSummeryEmails:boolean=false;
isAccessGroup:boolean=false;
// * Active List
isCatActive:boolean=false;
isItemActive:boolean=false;
isReportActive:boolean=false;
isMaintenanceActive:boolean=false;
isUsersActive:boolean=false;
isOpen:boolean=false;
  constructor(
    private _accessGroupService:AccessGroupService,
    private _categoryService:CategoryService,
    private _snackBar:MatSnackBar,
    private _router: Router,
    private route: ActivatedRoute,
    ) {
      this.userAccessPermissions=new UserAccessPermissions();
     }

  ngOnInit(): void {

    const accessGroupId = this.route.snapshot.paramMap.get('id');

    if(accessGroupId){
      this.isEditForm = true;
      this._accessGroupService.getAccessGroupById(+accessGroupId).subscribe(x => {

        if(x.succeeded){
          this.id = x.data.id;
          this.name = x.data.name;
          x.data.relatedCategories.forEach((item) =>{
            this.selectedCategories.push(item);
          });

          x.data.relatedAccessPermissions.forEach((p) =>{
            this.Permissions.push(p);
          });

          this.setCheckBoxesWhenEditAccessGroup();

        }

        this.isLoading = false;
        },(response) => {
            this._snackBar.open(response.error.Message);
      });
    }

    this._categoryService.getAllCategoriesData().subscribe(x => {
      this.categories = x.data;
      this.categoriesFilter = this.categoriesControl.valueChanges.pipe(
        startWith(null),
        map((value: string | null) => (value ? this.categories_filter(value) : this.categories.slice())
      ));
      this.isLoading = false;
      },(response) => {
          this._snackBar.open(response.error.Message);
    });
  }
  saveAccessGroup(form: NgForm){
    this.setUserAccessPermissons()
    if (form.valid) {
      this.isLoading = true;
      var data = {
        Id: this.id,
        Name: this.name,
        Categories: this.selectedCategories.map(function (item) { return item.id }),
        Permissions:this.accessPermissionList
      };

      if (!this.isEditForm) {

        this._accessGroupService.saveAccessGroup(data).subscribe((x) => {
          this._snackBar.open('Access Group added successfully!');
          this.isLoading = false;
          this._router.navigate(['/home/access-groups']);
        }, (response) => {
          this._snackBar.open(response.error.Message);
        });
      } else {
          this._accessGroupService.updateAccessGroup(data).subscribe((x) => {
          this._snackBar.open('Access Group updated successfully!');
          this.isLoading = false;
          this._router.navigate(['/home/access-groups']);
        }, (response) => {
          this._snackBar.open(response.error.Message);
        });
      }
    }
  }

  displayFn(options: CategoryModel[]): (id: number) => string {
    return (id: number) => {

      const correspondingOption = Array.isArray(this.categories) ? this.categories.find(option => option.id === id) : null;

      if(correspondingOption && this.selectedCategories.findIndex(x => x.id == id) == -1){
        this.selectedCategories.push(correspondingOption);
        this.categoriesInput.nativeElement.value = '';
        this.categoriesControl.setValue(null);
      }
      return correspondingOption ? correspondingOption.name || '': '';
    }
  }

  remove(category:CategoryModel): void {

    //var category = this.selectedCategories.find(x => x.id == categoryId);
    const index = this.selectedCategories.indexOf(category);

    if (index >= 0) {
      this.selectedCategories.splice(index, 1);
    }
  }
  private categories_filter(value: string): CategoryModel[] {

    if(value && isNaN(+value)){
      const filterValue = value.toLowerCase();
      return this.categories.filter(option => option.name.toLowerCase().startsWith(filterValue));
    }else{
      return this.categories;
    }
  }
  // public show: number;

  // toggle(index2:any) {
  //   if (this.show == index2) {
  //     this.show = -1;
  //   }
  //   else {
  //     this.show = index2;
  //   }
  // }
  // ! Display Main Sections
  displayMainSection(index:number)
  {
    // CHANGE THE NAME OF THE BUTTON.
    if(index==0)
    {
      this.CloseAllSubSections()
       this.isCategory=true;
       this.isCatActive =true;
       this.isUsers=false;
       this.isItemManagement=false;
    this.isAccessGroup=false;
    }
    if(index==1)
    {
      //this.CloseAllMainSections()
      this.CloseAllSubSections()
      this.isItem = !this.isItem;
      this.isItemActive = !this.isItemActive;
      this.isItemManagement=true;
      this.isCategory=false;
      this.isUsers=false;
      this.isAccessGroup=false;
    }



    if(index==2)
    {
    //this.CloseAllMainSections()
    this.CloseAllSubSections()
    this.isReport = !this.isReport;
    this.isReportActive = !this.isReportActive;
    this.isActivityReport=true;
    this.isCategory=false;
    this.isAccessGroup=false;
    }
    if(index==3)
    {
    //this.CloseAllMainSections()
    this.CloseAllSubSections()
    this.isMaintenance = !this.isMaintenance;
    this.isMaintenanceActive = !this.isMaintenanceActive;
    this.isLocations=true;
    this.isCategory=false;
    this.isUsers=false;
    this.isAccessGroup=false;
    }
    if(index==4)
    {
    //this.CloseAllMainSections()
    this.CloseAllSubSections()
    this.isUsers = true;
    this.isUsersActive = !this.isUsersActive;
    this.isCategory=false;
    this.isAccessGroup=false
    }
  }
  // ! Display Sub Sections
  displaySubSections(index:number)
  {
    if(index==1)
    {
    // this.CloseAllSubSections();
     this.isItemManagement=true;
     this.isItemMovement=false;
     this.isActivityReport=false;
     this.isActiveCheckoutReport=false;
     this.isLocations=false;
     this.isCategories=false;
     this.isSummeryEmails=false;
     this.isAccessGroup=false;
     this.isUsers=false;
     this.isCategory=false;
    }
    if(index==2)
    {
      this.CloseAllSubSections();
      this.isItemMovement=false;
      this.isItemMovement=true;
     this.isActivityReport=false;
     this.isActiveCheckoutReport=false;
     this.isLocations=false;
     this.isCategories=false;
     this.isSummeryEmails=false;
     this.isAccessGroup=false;
     this.isUsers=false;
      this.isCategories=false
      this.isItem=true;
      this.isCategory=false;
    }
    if(index==3)
    {
      this.CloseAllSubSections();
      this.isItemMovement=false;
     this.isActivityReport=true;
     this.isActiveCheckoutReport=false;
     this.isLocations=false;
     this.isCategories=false;
     this.isSummeryEmails=false;
     this.isAccessGroup=false;
     this.isUsers=false;
     this.isCategories=false;
     this.isCategory=false;
    }
    if(index==4)
    {
      this.CloseAllSubSections()
      this.isItemMovement=false;
      this.isItemMovement=false;
     this.isActivityReport=false;
     this.isActiveCheckoutReport=true;
     this.isLocations=false;
     this.isCategories=false;
     this.isSummeryEmails=false;
     this.isAccessGroup=false;
     this.isUsers=false;
     this.isCategories=false;
     this.isCategory=false;

    }
    if(index==5)
    {
      this.CloseAllSubSections()
      this.isItemMovement=false;
      this.isItemMovement=false;
     this.isActivityReport=false;
     this.isActiveCheckoutReport=false;
     this.isLocations=true;
     this.isCategories=false;
     this.isSummeryEmails=false;
     this.isAccessGroup=false;
     this.isUsers=false;
     this.isCategory=false;
    }
    if(index==6)
    {
      this.CloseAllSubSections();
      this.isItemMovement=false;
      this.isItemMovement=false;
     this.isActivityReport=false;
     this.isActiveCheckoutReport=false;
     this.isLocations=false;
     this.isCategories=true;
     this.isSummeryEmails=false;
     this.isAccessGroup=false;
     this.isUsers=false;
     this.isCategory=false;


    }
    if(index==7)
    {
      this.CloseAllSubSections();
      this.isItemMovement=false;
      this.isItemMovement=false;
      this.isActivityReport=false;
      this.isActiveCheckoutReport=false;
      this.isLocations=false;
      this.isCategories=false;
      this.isSummeryEmails=true;
      this.isAccessGroup=false;
      this.isUsers=false;
      this.isCategory=false;
    }
    if(index==8)
    {
      this.CloseAllSubSections();
      this.isItemMovement=false;
      this.isItemMovement=false;
      this.isActivityReport=false;
      this.isActiveCheckoutReport=false;
      this.isLocations=false;
      this.isCategories=false;
      this.isSummeryEmails=false;
      this.isAccessGroup=true;
      this.isUsers=false;
      this.isCategory=false;
    }
  }

  CloseAllMainSections()
  {
    this.isItem=false;
    this.isReport=false;
    this.isShowAccessCatagories=false;
    this.isMaintenance=false;
    this.isUsers=false;



  }

  CloseAllSubSections()
  {
    this.isItemMovement=false
    this.isItemManagement=false
    this.isActivityReport=false
    this.isActiveCheckoutReport=false
    this.isLocations=false;
    this.isCategories=false;
    this.isSummeryEmails=false;
  }

  setUserAccessPermissons()
{
  this.accessPermissionList=[];
  if(this.userAccessPermissions.createItem)
  {
    this.accessPermissionList.push(1)
  }
  if(this.userAccessPermissions.readItem)
  {
    this.accessPermissionList.push(2)
  }
  if(this.userAccessPermissions.updateItem)
  {
    this.accessPermissionList.push(3)
  }
  if(this.userAccessPermissions.delteItem)
  {
    this.accessPermissionList.push(4)
  }
  if(this.userAccessPermissions.cloneItem)
  {
    this.accessPermissionList.push(5)
  }
  if(this.userAccessPermissions.checkInItem)
  {
    this.accessPermissionList.push(6)
  }
  if(this.userAccessPermissions.checkOutItem)
  {
    this.accessPermissionList.push(7)
  }
  if(this.userAccessPermissions.itemImport)
  {
    this.accessPermissionList.push(8)
  }

  if(this.userAccessPermissions.activityReportRead)
  {
    this.accessPermissionList.push(9)
  }
  if(this.userAccessPermissions.activeCheckoutReportRead)
  {
    this.accessPermissionList.push(10)
  }

  if(this.userAccessPermissions.createLocation)
  {
    this.accessPermissionList.push(11)
  }
  if(this.userAccessPermissions.readLocation  )
  {
    this.accessPermissionList.push(12)
  }
  if(this.userAccessPermissions.updateLocation)
  {
    this.accessPermissionList.push(13)
  }
  if(this.userAccessPermissions.deleteLocation)
  {
    this.accessPermissionList.push(14)
  }

  if(this.userAccessPermissions.crateCategorySubCategory)
  {
    this.accessPermissionList.push(15)
  }
  if(this.userAccessPermissions.readCategorySubCategory)
  {
    this.accessPermissionList.push(16)
  }
  if(this.userAccessPermissions.updateCategorySubCategory)
  {
    this.accessPermissionList.push(17)
  }
  if(this.userAccessPermissions.deleteCategorySubCategory)
  {
    this.accessPermissionList.push(18)
  }

  if(this.userAccessPermissions.createSummeryEmail)
  {
    this.accessPermissionList.push(19)
  }
  if(this.userAccessPermissions.readSummeryEmail)
  {
    this.accessPermissionList.push(20)
  }
  if(this.userAccessPermissions.updateSummeryEmail)
  {
    this.accessPermissionList.push(21)
  }
  if(this.userAccessPermissions.deleteSummeryEmail)
  {
    this.accessPermissionList.push(22)
  }

  if(this.userAccessPermissions.createUser)
  {
    this.accessPermissionList.push(23)
  }
  if(this.userAccessPermissions.readUser)
  {
    this.accessPermissionList.push(24)
  }
  if(this.userAccessPermissions.updateUser)
  {
    this.accessPermissionList.push(25)
  }

  if(this.userAccessPermissions.readAccessGroup)
  {
    this.accessPermissionList.push(26)
  }if(this.userAccessPermissions.createAccessGroup)
  {
    this.accessPermissionList.push(27)
  }if(this.userAccessPermissions.updateAccessGroup)
  {
    this.accessPermissionList.push(28)
  }if(this.userAccessPermissions.deleteAccessGroup)
  {
    this.accessPermissionList.push(29)
  }

}

setCheckBoxesWhenEditAccessGroup()
{

  this.Permissions.filter(x=>{
    if(x.accessPermissionID==1)
    {
      this.userAccessPermissions.createItem=true
    }if(x.accessPermissionID==2)
    {
      this.userAccessPermissions.readItem=true

    }if(x.accessPermissionID==3)
    {
      this.userAccessPermissions.updateItem=true
    }if(x.accessPermissionID==4)
    {
      this.userAccessPermissions.delteItem=true
    }if(x.accessPermissionID==5)
    {
      this.userAccessPermissions.cloneItem=true
    }if(x.accessPermissionID==6)
    {
      this.userAccessPermissions.checkInItem=true
    }if(x.accessPermissionID==7)
    {
      this.userAccessPermissions.checkOutItem=true
    }if(x.accessPermissionID==8)
    {
      this.userAccessPermissions.itemImport=true
    }if(x.accessPermissionID==9)
    {
      this.userAccessPermissions.activityReportRead=true
    }if(x.accessPermissionID==10)
    {
      this.userAccessPermissions.activeCheckoutReportRead=true
    }if(x.accessPermissionID==11)
    {
      this.userAccessPermissions.createLocation=true
    }if(x.accessPermissionID==12)
    {
      this.userAccessPermissions.readLocation=true
    }if(x.accessPermissionID==13)
    {
      this.userAccessPermissions.updateLocation=true
    }if(x.accessPermissionID==14)
    {
      this.userAccessPermissions.deleteLocation=true
    }if(x.accessPermissionID==15)
    {
      this.userAccessPermissions.crateCategorySubCategory=true
    }if(x.accessPermissionID==16)
    {
      this.userAccessPermissions.readCategorySubCategory=true
    }if(x.accessPermissionID==17)
    {
      this.userAccessPermissions.updateCategorySubCategory=true
    }if(x.accessPermissionID==18)
    {
      this.userAccessPermissions.deleteCategorySubCategory=true
    }if(x.accessPermissionID==19)
    {
      this.userAccessPermissions.createSummeryEmail=true
    }if(x.accessPermissionID==20)
    {
      this.userAccessPermissions.readSummeryEmail=true
    }if(x.accessPermissionID==21)
    {
      this.userAccessPermissions.updateSummeryEmail=true
    } if(x.accessPermissionID==22){
      this.userAccessPermissions.deleteSummeryEmail=true
    }if(x.accessPermissionID==23)
    {
      this.userAccessPermissions.createUser=true
    }if(x.accessPermissionID==24)
    {
      this.userAccessPermissions.readUser=true
    } if(x.accessPermissionID==25)
    {
      this.userAccessPermissions.updateUser=true
    }

    if(x.accessPermissionID==26)
    {
      this.userAccessPermissions.readAccessGroup=true
    } if(x.accessPermissionID==27)
    {
      this.userAccessPermissions.createAccessGroup=true
    } if(x.accessPermissionID==28)
    {
      this.userAccessPermissions.updateAccessGroup=true
    } if(x.accessPermissionID==29)
    {
      this.userAccessPermissions.deleteAccessGroup=true
    }
  })
}

ToogleAllItemsManagementSelection(event:any)
{
  if(event.checked)
  {
    this.userAccessPermissions.createItem=true
    this.userAccessPermissions.updateItem=true
    this.userAccessPermissions.readItem=true
    this.userAccessPermissions.delteItem=true
    this.userAccessPermissions.cloneItem=true
    this.userAccessPermissions.itemImport=true
  }
  else{
    this.userAccessPermissions.createItem=false
    this.userAccessPermissions.updateItem=false
    this.userAccessPermissions.readItem=false
    this.userAccessPermissions.delteItem=false
    this.userAccessPermissions.cloneItem=false
    this.userAccessPermissions.itemImport=false
  }
}


ToogleAllItemsMovementSelection(event:any)
{
  if(event.checked)
  {
    this.userAccessPermissions.checkInItem=true
    this.userAccessPermissions.checkOutItem=true
  }
  else{
    this.userAccessPermissions.checkInItem=false
    this.userAccessPermissions.checkOutItem=false
  }

}

ToogleAllLocationSelection(event:any)
{
  if(event.checked)
  {
    this.userAccessPermissions.createLocation=true;
    this.userAccessPermissions.updateLocation=true;
    this.userAccessPermissions.readLocation=true;
    this.userAccessPermissions.deleteLocation=true;

  }
  else{
    this.userAccessPermissions.createLocation=false;
    this.userAccessPermissions.updateLocation=false;
    this.userAccessPermissions.readLocation=false;
    this.userAccessPermissions.deleteLocation=false;
  }
}

ToogleAllCategoriesSelection(event:any)
{
  if(event.checked)
  {
    this.userAccessPermissions.crateCategorySubCategory=true;
    this.userAccessPermissions.updateCategorySubCategory=true;
    this.userAccessPermissions.readCategorySubCategory=true;
    this.userAccessPermissions.deleteCategorySubCategory=true;

  }
  else{

    this.userAccessPermissions.crateCategorySubCategory=false;
    this.userAccessPermissions.updateCategorySubCategory=false;
    this.userAccessPermissions.readCategorySubCategory=false;
    this.userAccessPermissions.deleteCategorySubCategory=false;

  }
}

ToogleAllSummeryEmailSelection(event:any)
{
  if(event.checked)
  {
    this.userAccessPermissions.createSummeryEmail=true;
    this.userAccessPermissions.updateSummeryEmail=true;
    this.userAccessPermissions.readSummeryEmail=true;
    this.userAccessPermissions.deleteSummeryEmail=true;

  }
  else{
    this.userAccessPermissions.createSummeryEmail=false;
    this.userAccessPermissions.updateSummeryEmail=false;
    this.userAccessPermissions.readSummeryEmail=false;
    this.userAccessPermissions.deleteSummeryEmail=false;

  }
}


ToogleAllAccessGroupSelection(event:any)
{
  if(event.checked)
  {
    this.userAccessPermissions.createAccessGroup=true;
    this.userAccessPermissions.updateAccessGroup=true;
    this.userAccessPermissions.readAccessGroup=true;
    this.userAccessPermissions.deleteAccessGroup=true;

  }
  else{
    this.userAccessPermissions.createAccessGroup=false;
    this.userAccessPermissions.updateAccessGroup=false;
    this.userAccessPermissions.readAccessGroup=false;
    this.userAccessPermissions.deleteAccessGroup=false;


  }
}

ToogleAllUserManagementSelection(event:any)
{
  if(event.checked)
  {
    this.userAccessPermissions.createUser=true;
    this.userAccessPermissions.updateUser=true;
    this.userAccessPermissions.readUser=true;
  }
  else{
    this.userAccessPermissions.createUser=false;
    this.userAccessPermissions.updateUser=false;
    this.userAccessPermissions.readUser=false;
  }
}



  }



