<mat-progress-bar [ngStyle]="{'visibility':isLoading==true ? 'visible' : 'hidden'}" color="primary"
  mode="indeterminate"></mat-progress-bar>
<div class="col-lg-12 components-block">
  <div class="components-header">
    <h2>Locations List</h2>
  </div>
  <div class="filterbar-block" style="margin-left: 10px;">
    <mat-form-field appearance="standard">
      <mat-label>Search</mat-label>
      <input matInput #searchValue (keyup)="applyFilters()" [(ngModel)]="filterValue" placeholder="Name,Code" #input>
      <div class="filtericon">
        <mat-icon aria-hidden="false" aria-label="Example home icon">search</mat-icon>
      </div>
    </mat-form-field>
  </div>

  <div class="mat-table-block">
    <table mat-table matSort [dataSource]="locationData">

      <ng-container matColumnDef="Code">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;">Code</th>
        <td mat-cell *matCellDef="let location" style="text-align: center;">
          {{location.id}}
        </td>
      </ng-container>
      <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let location">
          {{location.name}}
        </td>
      </ng-container>
      <ng-container matColumnDef="Address">
        <th mat-header-cell *matHeaderCellDef>Address</th>
        <td mat-cell *matCellDef="let location">
          {{location.address}}
        </td>
      </ng-container>
      <!-- Action Column -->
      <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef> </th>

        <td mat-cell *matCellDef="let location" class="action-link">
          <span class="actions-box">
            <button matTooltip="Edit" matTooltipClass="matTooltip-Custom" matTooltipPosition="above"
            class="column-name-link"
            routerLink="/home/location/edit/{{location.id}}"
            [hidden]="!userAccessPermission.updateLocation">
            <mat-icon>edit</mat-icon>
          </button>
          </span>
          <span class="actions-box">
          <button matTooltip="Delete" matTooltipClass="matTooltip-Custom" matTooltipPosition="above"
            class="column-name-link" (click)="confirmDialog(location)"
            [hidden]="!userAccessPermission.deleteLocation">
            <mat-icon>delete</mat-icon>
          </button>
          </span>
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[25, 50, 100]" #paginator [pageIndex]="currentPage" [pageSize]="pageSize"
      (page)="pageChanged($event)" showFirstLastButtons aria-label="Select page of items">
    </mat-paginator>
  </div>
</div>
