<div class="static-page not-found-page">
    <div class="container">
        <div class="static-block">
            <div class="static-logo-block"><a class="static-logo-box">DPL Inventory</a></div>
            <div class="static-header static-header-bg">
                <h4>Page not found :(</h4>
                <i class="notfound-icon notfound-left"></i>
                <i class="notfound-icon notfound-right"></i>
            </div>
            <div class="static-content">
                <div class="not-found-text"><span>4</span><span>0</span><span>4</span></div>
                <p>Sorry We can't find that page!</p>
                <p>Either something went wrong or the page doesn't exist anymore.</p><br>
                <div class="static-actions">
                    <a href="\home\items" class="mat-focus-indicator mat-button mat-raised-button mat-button-base mat-primary">Home Page</a>
            </div>

            </div>
        </div>
    </div>
</div>
