import { ItemSpecsValuesViewModel } from "./ItemSpecsValuesViewModel";

type Nullable<T> = T | null;

export class ItemModel{
  id:number;
  name:string;
  quantity:number;
  totalQuantity:number;
  code:string;
  model:string;
  status:string;
  responsibleUserId:string;
  responsibleUser:string;
  description:string;
  locationId:number;
  location:string;
  categoryId:number;
  category:string;
  subCategoryId?:number;
  subCategory?:string;
  procurmentURL?:string;
  itemImagePath:string;
  itemVideoPath:string;
  created:Date;
  createdBy:string;
  serialNo:string;
  totalCheckOutQuntity:number;
  purchaseCost:number;
  tagName:string;
  //  purchaseCost: Nullable<number>;
  currency:string;
  purchaseDate:Date;
  isItemCreatedByManager:boolean
  itemTagsView:itemTagsView[];
  itemSpecs:ItemSpecsValuesViewModel[];

  constructor() {
    this.id = 0;
    this.quantity = 1;
    this.totalQuantity=1;
    this.categoryId = 0;
    this.name = '';
    this.description = '';
    this.code = '';
    this.model = '';
    this.responsibleUserId = '';
    this.procurmentURL = '';
    this.locationId = 0;
    this.createdBy='';
    this.serialNo='';
    this.totalCheckOutQuntity=0;
    // this.purchaseDate=new Date();
    // this.purchaseCost=null;
    this.currency='PKR'
    this.tagName="";
    this.isItemCreatedByManager=false;
  }



}

export class itemTagsView{
  tagId:number;
  tagName:string;
}
