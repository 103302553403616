import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import { of } from 'rxjs';
import { SubCategoryModel } from 'src/app/Models/ComponentModels/SubCategory';
import { AppURLs } from 'src/app/AppConstants';
import { AppConstants } from 'src/app/AppConstants';
import { environment } from 'src/environments/environment';
import { ApiSingleResponse } from 'src/app/Models/API-Response-Single';

@Injectable({
  providedIn: 'root'
})
export class SubCategoryService {

  URLs:AppURLs;
  subCategoryServiceURL:string;

  constructor(private _http:HttpClient) {
    this.URLs = new AppURLs(AppConstants.APIVersion);

    this.subCategoryServiceURL = environment.apiBaseUrl + this.URLs.SubCategoriesURL;
   }

  getAllSubCategories(CategoryId:Number):Observable<ApiSingleResponse<SubCategoryModel>>{
    return this._http.get<ApiSingleResponse<SubCategoryModel>>(this.subCategoryServiceURL+ `?CategoryId=${CategoryId}`);
  }
  addNewSubCategory(SubCategoryModel:SubCategoryModel):Observable<any>
  {
   return this._http.post<any>(this.subCategoryServiceURL,SubCategoryModel)
  }

  GetAllSubCategoriesList():Observable<ApiSingleResponse<SubCategoryModel>>{
    return this._http.get<ApiSingleResponse<SubCategoryModel>>(this.subCategoryServiceURL+'/GetAllSubCategoriesList' );
  }
  UpdateSubCategory(SubCategoryModel:SubCategoryModel):Observable<any>
  {
   return this._http.post<any>(this.subCategoryServiceURL+'/UpdateSubCategory',SubCategoryModel)
  }

  public ExportToExcelListOfCategories(

    ): Observable<any> {
      return this._http.get(
        this.subCategoryServiceURL+'/ExportToExcelListOfCategories',
      { responseType: 'blob' }
      );
    }

}
