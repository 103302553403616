import { Injectable, OnInit } from '@angular/core';
import { LoginRespopnseModel } from '../components/login/LoginRespopnseModel';
import { AccessPermission, UserAccessPermissions } from '../Models/AccessPermissions';
@Injectable({
  providedIn: 'root'
})
export class StorageService implements OnInit {

  accessPermission:AccessPermission[]
  userAccessPermissions:UserAccessPermissions
  notEnogugRights:string="You don't have enough rights"
  private UserKey: string = "LoggedInUser";


  constructor() {
    this.GetLoginUserRoles();
    this.userAccessPermissions=new UserAccessPermissions()
   }
  ngOnInit(): void {
    this.IsLoginUserEmployee();

  }



  public StoreLoggedInUser(user: LoginRespopnseModel) {
    var userJSON = JSON.stringify(user);

    localStorage.setItem(this.UserKey, userJSON);
  }

  public GetLoggedInUser(): LoginRespopnseModel {
    var userJSON = localStorage.getItem(this.UserKey);

    if(userJSON){
      return (JSON.parse(userJSON || ''));
    }else{
      return new LoginRespopnseModel();
    }
  }

  public removeStoredUser() {
    localStorage.removeItem(this.UserKey);
  }

  public GetLoginUserRoles()
  {
    let userJSON = JSON.parse(localStorage.getItem(this.UserKey)!) || '' ;
    return userJSON.roles;
  }

  public GetLoginUserEmail()
  {
    let userJSON = JSON.parse(localStorage.getItem(this.UserKey)!) || '' ;
    return userJSON.email;

  }

  public GetLoginUserPermissions()
  {

    let loginUserJson = JSON.parse(localStorage.getItem(this.UserKey)!) || '' ;
    let loginUserPermission=loginUserJson.accessPermission

    this.accessPermission=loginUserPermission;
    this.userAccessPermissions=new UserAccessPermissions()

    this.accessPermission.filter(x=>{

      // ! Item
      if(x.accessPermissionID==1)// Create Item
      {
        this.userAccessPermissions.createItem=true
      }
      if(x.accessPermissionID==2)// Read Item
      {
         this.userAccessPermissions.readItem=true;
      }
      if(x.accessPermissionID==3)// Update Item
      {
         this.userAccessPermissions.updateItem=true;
      }
      if(x.accessPermissionID==4)// Delete Item
      {
         this.userAccessPermissions.delteItem=true;
      }
      if(x.accessPermissionID==5)// cloneItem Item
      {
         this.userAccessPermissions.cloneItem=true;
      }
      if(x.accessPermissionID==6)// checkInItem Item
      {
         this.userAccessPermissions.checkInItem=true;
      }
      if(x.accessPermissionID==7)// checkOutItem Item
      {
         this.userAccessPermissions.checkOutItem=true;
      }
      if(x.accessPermissionID==8)// checkOutItem Item
      {
         this.userAccessPermissions.itemImport=true;
      }

      // ! Report
      if(x.accessPermissionID==9)// checkOutItem Item
      {
         this.userAccessPermissions.activityReportRead=true;
      }
      if(x.accessPermissionID==10)// checkOutItem Item
      {
         this.userAccessPermissions.activeCheckoutReportRead=true;
      }

      // ! Maintenance
      // * Locations
      if(x.accessPermissionID==11)//
      {
         this.userAccessPermissions.createLocation=true;
      }
      if(x.accessPermissionID==12)//
      {
         this.userAccessPermissions.readLocation=true;
      }
      if(x.accessPermissionID==13)//
      {
         this.userAccessPermissions.updateLocation=true;
      }
      if(x.accessPermissionID==14)//
      {
         this.userAccessPermissions.deleteLocation=true;
      }

      // * Category

      if(x.accessPermissionID==15)//
      {
         this.userAccessPermissions.crateCategorySubCategory=true;
      }
      if(x.accessPermissionID==16)//
      {
         this.userAccessPermissions.readCategorySubCategory=true;
      }
      if(x.accessPermissionID==17)//
      {
         this.userAccessPermissions.updateCategorySubCategory=true;
      }
      if(x.accessPermissionID==18)//
      {
         this.userAccessPermissions.deleteCategorySubCategory=true;
      }

      // * Summery Email
      if(x.accessPermissionID==19)//
      {
         this.userAccessPermissions.createSummeryEmail=true;
      }
      if(x.accessPermissionID==20)//
      {
         this.userAccessPermissions.readSummeryEmail=true;
      }
      if(x.accessPermissionID==21)//
      {
         this.userAccessPermissions.updateSummeryEmail=true;
      }
      if(x.accessPermissionID==22)//
      {
         this.userAccessPermissions.deleteSummeryEmail=true;
      }
      // * Access Groups

      if(x.accessPermissionID==26)//
      {
         this.userAccessPermissions.readAccessGroup=true;
      }
      if(x.accessPermissionID==27)//
      {
         this.userAccessPermissions.createAccessGroup=true;
      }
      if(x.accessPermissionID==28)//
      {
         this.userAccessPermissions.updateAccessGroup=true;
      }
      if(x.accessPermissionID==29)//
      {
         this.userAccessPermissions.deleteAccessGroup=true;
      }




      // ! User
      if(x.accessPermissionID==23)//
      {
         this.userAccessPermissions.createUser=true;
      }
      if(x.accessPermissionID==24)//
      {
         this.userAccessPermissions.readUser=true;
      }
      if(x.accessPermissionID==25)//
      {
         this.userAccessPermissions.updateUser=true;
      }

      //! Access Group
      if(x.accessPermissionID==26)
      {
         this.userAccessPermissions.readAccessGroup=true;
      }if(x.accessPermissionID==27)
      {
         this.userAccessPermissions.createAccessGroup=true;
      }if(x.accessPermissionID==28)
      {
         this.userAccessPermissions.updateAccessGroup=true;
      }if(x.accessPermissionID==29)
      {
         this.userAccessPermissions.deleteAccessGroup=true;
      }






    })

     return this.userAccessPermissions;
  }

  public IsLoginUserEmployee()
  {
    let userJSON = JSON.parse(localStorage.getItem(this.UserKey)!) || '' ;
    if(userJSON.roles.length==1 && userJSON.roles[0]=='Employee')
      {
        return true;
      }
    else{
      return false;
  }
  }

  public IsLoginUserManager()
  {
    let userJSON = JSON.parse(localStorage.getItem(this.UserKey)!) || '' ;



    if(userJSON.roles.length==2 && userJSON.roles[0]=='Employee' && userJSON.roles[1]=='Manager')
      {

        return true;
      }
    else{
      return false;
  }
  }

}
