import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { Exception } from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  Sentry.init({
    environment: 'production',
    dsn: 'https://7e0fce83cc2647608d9f8c4a6ced1444@o1243303.ingest.sentry.io/6408910',
    integrations: [
      new BrowserTracing({
        tracingOrigins: ['http://ims.dplit.com/', 'http://api.ims.dplit.com/'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  });
} else if (environment.dev) {
  Sentry.init({
    environment: 'dev',
    dsn: 'https://7e0fce83cc2647608d9f8c4a6ced1444@o1243303.ingest.sentry.io/6408910',
    integrations: [
      new BrowserTracing({
        tracingOrigins: [
          'http://dev-ims.dplit.com/',
          'http://api.dev-ims.dplit.com/',
        ],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.7,
  });
}
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => {
    console.error(err);
    Sentry.captureException(err);
  });
