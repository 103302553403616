export class LocationModel{
  id:number;
  name:string;
  address:string;
  phone:string;
  responsibleUserId:string;

  constructor() {
    this.id = 0;
    this.address = '';
    this.name = '';
    this.phone = '';
    this.responsibleUserId = '';
  }
}
