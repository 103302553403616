export enum AppConstants {
  APIVersion = 1,
}
export class AppURLs {

  private readonly ApiVersion: number = 1;

  constructor(ApiVersion: number) {
    this.ApiVersion = ApiVersion;
  }

  public UsersURL: string = `/v${this.ApiVersion}/Users`;
  public ItemsURL: string = `/v${this.ApiVersion}/Item`;
  public ItemsRecordURL: string = `/v${this.ApiVersion}/ItemsRecord`;
  public LocationsURL: string = `/v${this.ApiVersion}/Location`;
  public CategoriesURL: string = `/v${this.ApiVersion}/Category`;
  public SubCategoriesURL: string = `/v${this.ApiVersion}/SubCategory`;
  public ReportsURL: string = `/v${this.ApiVersion}/Reports`;
  public AccessGroup: string = `/v${this.ApiVersion}/AccessGroup`;
  public Alert: string = `/v${this.ApiVersion}/Alert`;

  public TagsURL: string = `/v${this.ApiVersion}/Tags`;


}
