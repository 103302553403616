<form name="itemNotesForm" #itemNotesForm="ngForm" (submit)="saveNotes(itemNotesForm)" novalidate>

<mat-dialog-content>
    <header class="dialog-header">
        <h2>Add Notes</h2>
        <a class="close-button" (click)="close()">
            <mat-icon>close</mat-icon>
        </a>
    </header>
     <div class="row">
        <div class="col-lg-12 form-group upload-controls upload-controls-field">
            <label class="upload-image-video-label">Upload Image/Video</label>
            <mat-form-field>
              <span class="choose-files">Choose Files</span>

              <div>
                <mat-toolbar>
                  <input matInput [(ngModel)]="fileAttr" readonly name="fileAttr" />
                  <button name="uploadFileControlText" ngDefaultControl  mat-flat-button [(ngModel)]="uploadFileControlText" class="browsebtn" color="primary">
                    Browse File
                  </button>
                </mat-toolbar>
                <input type="file" #fileInput id="uploadFile" multiple (change)="uploadFileEvt($event)" name="uploadFile"
                  accept="image/*,video/*" />
                <mat-error style="margin-top: 10px;" *ngIf="sizeExceeded">Max file size exceeded. Please upload less
                  than 4 MB image.</mat-error>
              </div>
            </mat-form-field>
            <mat-chip-list #chipList aria-label="Image selection">
              <mat-chip *ngFor="let file of filesToUpload" (removed)="OnImageDeselect(file)">
                {{file.name}}
                <button matChipRemove>
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip>
            </mat-chip-list>
          </div>
        <div class="col-lg-12 form-group">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Notes</mat-label>
              <textarea name="notesText" type="text" matInput placeholder="Enter your notes" required
              [(ngModel)]="notesText" style="height:60px"></textarea>

              <mat-error >
                Notes is <strong>required</strong>
              </mat-error>
            </mat-form-field>
        </div>
      </div>
</mat-dialog-content>

<mat-dialog-actions class="col-lg-12 form-group dialog-actions">
    <button type="submit" [disabled]="loading" mat-raised-button color="primary" mat-button class="save-btn">Save</button>
</mat-dialog-actions>


</form>
