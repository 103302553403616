import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  ValidatorFn,
} from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fromEvent, Observable } from 'rxjs';
import { UserModel } from './../../../Models/ComponentModels/UserModel';
import { UserService } from './../../../Services/component-services/user-service';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
} from 'rxjs/operators';
import { UserRole } from 'src/app/Models/ComponentModels/UserRole';
import { NameValuePair } from 'src/app/Models/NameValuePair';
import { StorageService } from './../../../Services/storage-service.service';
import { AccessGroupService } from 'src/app/Services/component-services/access-group-service';
import { AccessGroupModel } from 'src/app/Models/ComponentModels/AccessGroupModel';
import { V } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-users-add',
  templateUrl: './users-add.component.html',
  styleUrls: ['./users-add.component.scss'],
})
export class UsersAddComponent implements OnInit {
  searchUserControl = new FormControl('');
  profileForm = this._formBuilder.group({
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    userName: new FormControl('', Validators.minLength(6)),
    employeeId: new FormControl(''),
    address: new FormControl(''),
    address2: new FormControl(''),
    status: new FormControl(''),
    email: new FormControl('', [Validators.required, Validators.email]),
    phoneNumber: new FormControl('', Validators.minLength(11)),
    selectedRoles: new FormControl([], Validators.required),
    selectedAccessGroups: new FormControl([], Validators.required),
    Id: new FormControl(''),
    allowIMS: new FormControl()
  });

  isLoading: boolean = false;
  isLoadingProg: boolean = false;
  isEditUserMode: boolean = false;
  Users: UserModel[];
  UserRoles: UserRole[];
  AccessGroups: AccessGroupModel[];
  searcheduser: UserModel = new UserModel();
  statuslist: NameValuePair[] = [
    { value: 'Active', name: 'Active' },
    { value: 'InActive', name: 'InActive' },
  ];
  toogleToolTip: boolean = false;
  superAdmin: boolean = false;
  UserAssignroles: any;
  ChangeEmployee: boolean = false;

  constructor(
    private _userService: UserService,
    private _accessGroupService:AccessGroupService,
    private _snackBar: MatSnackBar,
    private _router: Router,
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _StorageService: StorageService
  ) {
    this.profileForm.disable();

  }

  ngOnInit(): void {
    this.getAllRoles();
    this.getAllAccessGroups();
    const userId = this.route.snapshot.paramMap.get('id');

    if (userId) {
      this.isEditUserMode = true;
      this._userService.getUserById(userId).subscribe(
        (x) => {
          this.searcheduser = x.data;
          this.profileForm.patchValue({
            selectedRoles: this.searcheduser.roles.map((x) => x.id)
          });
          this.profileForm.patchValue({
            selectedAccessGroups: this.searcheduser.accessGroupIds
          });
          this.searchUserControl.setValue(this.searcheduser);
          this.profileForm.enable();
          this.rolesAssignmentAccess();

        },
        (errResponse) => {
          this._snackBar.open(
            errResponse.error.Message || 'Something went wrong.'
          );
        }
      );
    }
  }
  getAllRoles() {
    this._userService.getUserRoles().subscribe((x) => {
      this.UserRoles = x.data;
      const userId = this.route.snapshot.paramMap.get('id');

      if (!userId) {
        this.profileForm.patchValue({
          selectedRoles: this.UserRoles.map((x) => x.id),
        });
      }
    });
  }
  getAllAccessGroups() {
    this._accessGroupService.getAllAccessGroupsPagination(-1,-1).subscribe((x) => {
      this.AccessGroups = JSON.parse(JSON.stringify(x.data)) ;
      const userId = this.route.snapshot.paramMap.get('id');

      if (!userId) {
        this.profileForm.patchValue({
          selectedAccessGroups: this.AccessGroups.map((x) => x.id),
        });
      }
    });
  }
  clearForm() {
    this.searcheduser = new UserModel();
    this.profileForm.disable();
  }
  SearchUsers(event: any) {
    var searchString = event.target.value.replace(/ /g, '');
    if (searchString.length < 3) {
      return;
    }

    this.isLoading = true;
    this.profileForm.patchValue({
      selectedRoles: [],
      selectedAccessGroups: [],
    });
    this.profileForm.disable();
    this._userService.searchallUsers(searchString).subscribe(
      (response) => {
        this.Users = response.data;
        this.isLoading = false;
      },
      (errResponse) => {
        this._snackBar.open(errResponse.error.Message);
        this.isLoading = false;
      }
    );
  }
  selectUser(selectedId: any, users: UserModel[]) {
    var user = users.find((x) => x.id == selectedId);
    if (user) {
      this.searcheduser = user;
      this.profileForm.enable();

      this.profileForm.patchValue({
        selectedRoles: user.roles?.map((x) => x.id),
      }); // To trigger change

      this.profileForm.patchValue({
        selectedAccessGroups: this.searcheduser.accessGroupIds
      });
      // Enables/Disables Roles Assignment Dropdown on the basis of login users Roles
      this.rolesAssignmentAccess();
    }
  }
  displayFn(options: UserModel[]): (id: string) => string {

    return (id: string) => {
      const correspondingOption = Array.isArray(options)
        ? options.find((option) => option.id === id)
        : null;

      if (correspondingOption) {
        return (
          correspondingOption.firstName +
          ' ' +
          (correspondingOption.lastName || '')
        );
      }
      return '';
    };
  }
  saveuser() {
    if (!this.profileForm.valid) {
      this._snackBar.open('One or more validation errors detected.', 'Ok!');
      return;
    }

    var data = this.profileForm.value;

    // if(data.selectedRoles.length==1 &&
    //   data.selectedRoles=='e8c2e3b5-bc55-40ac-8735-715a7bb02880'||
    //   data.selectedRoles=='72b84cfa-b14f-43ac-9cb4-5feedee3c60a')
    // {
    //   data.allowIMS=false
    // }

    this.isLoadingProg = true;
    this._userService.saveOrUpdateUser(data).subscribe(
      (response) => {
        this.profileForm.disable();
        this.searcheduser = new UserModel();
        this.isLoadingProg = false;
        this._snackBar.open('User saved successfully.', 'Ok');
        this._router.navigate(['/home/users']);
      },
      (errorResponse) => {
        this.isLoadingProg = false;
        this._snackBar.open(errorResponse.error.Message, 'Ok!');
      }
    );
  }
  onRoleRemoved(role: UserRole) {
    if (this.searcheduser) {
      const selectedRolesIds = this.searcheduser.roles;
      this.removeFirst(selectedRolesIds, role);

      this.profileForm.patchValue({
        selectedRoles: selectedRolesIds,
      }); // To trigger change
    }
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  rolesAssignmentAccess() {
    let email = this._StorageService.GetLoginUserEmail();
    this.UserAssignroles = [];
    this._userService.getLoginUserRoles(email).subscribe((x) => {
      this.UserAssignroles = x;

      if (this.UserAssignroles.indexOf('SuperAdmin') > -1) {
        this.profileForm.controls['selectedRoles'].enable();

        var selectedRoles = this.profileForm.controls['selectedRoles'].value;
        var superAdminRoleId = this.UserRoles.find(x => x.name == "Admin")?.id;
        if(selectedRoles.indexOf(superAdminRoleId) > -1){
          // user has selected Admin in the dropdown so now we enable access Groups
          this.profileForm.controls['selectedAccessGroups'].enable();
        }else{
          this.profileForm.controls['selectedAccessGroups'].disable();
        }
        // this.profileForm.controls['allowIMS'].enable();
        this.superAdmin = true;
        this.toogleToolTip = true;
      }

      else {
        this.profileForm.controls['selectedRoles'].disable();
        this.profileForm.controls['selectedAccessGroups'].disable();
        this.profileForm.controls['allowIMS'].disable();
        this.toogleToolTip = false;
      }
    });


  }
  //  Change The Toogle on The basis of Role Change
  OnRoleChange(e: any) {

    var selectedRoles = this.profileForm.controls['selectedRoles'].value;

    var SuperAdminRoleId = this.UserRoles.find(x => x.name == "SuperAdmin")?.id;
    var AdminRoleId = this.UserRoles.find(x => x.name == "Admin")?.id;
    var ManagerRoleId= this.UserRoles.find(x=>x.name=='Manager')?.id;
    var EmployeeRoleId= this.UserRoles.find(x=>x.name=='Employee')?.id;

    if(selectedRoles.indexOf(AdminRoleId) > -1){
      // user has selected Admin in the dropdown so now we enable access Groups
      this.profileForm.controls['selectedAccessGroups'].enable();
    }else{
      this.profileForm.controls['selectedAccessGroups'].disable();
    }

    //! If Only Manager Role is Selected then  Selecte Employee By Defult
    if(selectedRoles.indexOf(ManagerRoleId)>-1 && selectedRoles.indexOf(EmployeeRoleId)<=-1)
     {
      selectedRoles.push(EmployeeRoleId);

      this.profileForm.controls['selectedRoles'].setValue(selectedRoles);

      console.log(this.profileForm.controls['selectedRoles'].value)

    }


  }
}
