<mat-progress-bar [ngStyle]="{'visibility':isLoading==true ? 'visible' : 'hidden'}" color="primary"
  mode="indeterminate"></mat-progress-bar>

<div class="col-lg-12 components-block">
  <div class="components-header">
    <h2>Import Items </h2>
  </div>
  <div class="row">
    <div class="col-lg-5 form-group upload-controls">
      <h2>Download the excel template to get started</h2>
      <button (click)="DownloadItemExcelTemplate()" type="button" mat-stroked-button color="primary"
        class="download-excel">Download Template <mat-icon>get_app</mat-icon>
      </button>
      <p>Once you have filled the template, select the file and import the items into the system </p>
      <div class="upload-controls-button">
        <mat-form-field>
          <div>
            <mat-toolbar>
              <input matInput [(ngModel)]="fileAttr" readonly name="name" />
              <button mat-stroked-button class="browsebtn" color="primary">
                Browse
              </button>
            </mat-toolbar>
            <input #myInput type="file" value="File" #fileInput id="uploadFile" (change)="onFileSelect($event)"
              name="uploadFile"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />

          </div>
        </mat-form-field>
        <button [disabled]="btnDisables" (click)="onfileUpload()" class="col-lg-6" mat-flat-button type="button"
          color="primary" class="upload-file">Start Import
        </button>
      </div>


    </div>


  </div>
  <div class="row">
    <div class="col-lg-5 error-block">
      <div class="error-content">
        <ul class="error-message-list">
          <li *ngFor="let err of errorsList ;let i = index">
            <mat-error> {{i+1}}.{{err}}. </mat-error>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
