import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginRespopnseModel } from './../components/login/LoginRespopnseModel';
import { Router } from '@angular/router';
import { environment } from './../../environments/environment';
import { LoginRequestModel } from '../components/login/LoginRequestModel';
import { StorageService } from './storage-service.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {


  constructor(
    private http: HttpClient,
    private router: Router,
    private storageService:StorageService){

  }



  login(loginInfo:LoginRequestModel) {
    return this.http.post<any>(`${environment.apiBaseUrl}/Account/authenticate`,loginInfo ).pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                this.storageService.StoreLoggedInUser(user.data)
                return user;
            }));
  }

  public logout(): void {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  public tokenExpired(token: string) {

    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }
}
