<form name="editHistoryForm" #editHistoryForm="ngForm" (submit)="editItemHistory(editHistoryForm)" novalidate>

  <mat-dialog-content>
    <header class="dialog-header">
      <h2>Edit History</h2>
      <a class="close-button" (click)="close()">
        <mat-icon>close</mat-icon>
      </a>
    </header>
    <div class="row">
      <div class="col-lg-12 form-group">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Notes</mat-label>
          <textarea name="notesText" type="text" matInput placeholder="Enter your notes" [(ngModel)]="notesText"
            style="height:60px"></textarea>
        </mat-form-field>
      </div>

      <div class="col-lg-12 form-group upload-controls upload-controls-field">
        <label class="upload-image-video-label">Upload Image/Video/PDF</label>
        <mat-form-field>
          <span class="choose-files">Choose Files</span>
          <div>
            <mat-toolbar>
              <input matInput [(ngModel)]="fileAttr" readonly name="fileAttr" />
              <button name="uploadFileControlText" ngDefaultControl mat-flat-button [(ngModel)]="uploadFileControlText"
                class="browsebtn" color="primary">
                Browse File
              </button>
            </mat-toolbar>

            <input type="file" #fileInput id="uploadFile" multiple (change)="uploadFileEvt($event)" name="uploadFile"
            accept=".pdf, .jpg, .jpeg, .png, .gif, .bmp, .mp4, .mov, .avi, .wmv, .flv" />
            <mat-error style="margin-top: 10px;" *ngIf="sizeExceeded">Max file size exceeded. Please upload less
              than 4 MB image.</mat-error>
          </div>
        </mat-form-field>
        <mat-chip-list #chipList aria-label="Image selection">
          <mat-chip class="mat-chip-new-uploaded" *ngFor="let file of filesToUpload" (removed)="OnNewFileRemove(file)">
            {{file.name}}
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
        </mat-chip-list>
      </div>

      <!-- ** Videos -->
      <div class="mat-chip-old-file-container">
        <mat-chip-list #chipList aria-label="Image selection">
          <mat-chip *ngFor="let file of OldItemVideoPath" (removed)="OnOldVideoRemove(file)">
            {{RemoveTimeStampFromFileName(file)}}
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
        </mat-chip-list>

        <!--** Images -->
        <mat-chip-list #chipList aria-label="Image selection">
          <mat-chip *ngFor="let file of OldItemImagePath" (removed)="OnOldImageRemove(file)">
            {{RemoveTimeStampFromFileName(file)}}
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
        </mat-chip-list>

        <!-- !PDF -->
        <mat-chip-list #chipList aria-label="Pdf selection">
          <mat-chip *ngFor="let file of OldItemPdfPath" (removed)="OnOldPdfRemove(file)">
            {{RemoveTimeStampFromFileName(file)}}
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
        </mat-chip-list>

      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="col-lg-12 form-group dialog-actions">
    <button type="submit" [disabled]="loading" mat-raised-button color="primary" mat-button
      class="save-btn">Save</button>
  </mat-dialog-actions>


</form>
