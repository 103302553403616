import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import { of } from 'rxjs';
import { CategoryModel } from 'src/app/Models/ComponentModels/Category';
import { AppURLs } from 'src/app/AppConstants';
import { AppConstants } from 'src/app/AppConstants';
import { environment } from 'src/environments/environment';
import { ApiSingleResponse } from 'src/app/Models/API-Response-Single';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  URLs:AppURLs;
  categoryServiceURL:string;

  constructor(private _http:HttpClient) {
    this.URLs = new AppURLs(AppConstants.APIVersion);

    this.categoryServiceURL = environment.apiBaseUrl + this.URLs.CategoriesURL;
   }

  getAllCategories():Observable<ApiSingleResponse<CategoryModel>>{
    // this brings only allowed categories
    return this._http.get<ApiSingleResponse<CategoryModel>>(this.categoryServiceURL);
  }
  getAllCategoriesData():Observable<ApiSingleResponse<CategoryModel>>{
    return this._http.get<ApiSingleResponse<CategoryModel>>(this.categoryServiceURL + "/GetAllCategories");
  }
  addNewCategory(categoryModel:CategoryModel):Observable<any>
  {

    return this._http.post<any>(this.categoryServiceURL,categoryModel);
  }
UpdateCategory(categoryModel:CategoryModel):Observable<any>
{

  return this._http.post<any>(this.categoryServiceURL+'/UpdateCategory',categoryModel);
}


}
