import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as saveAs from 'file-saver';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ItemService } from 'src/app/Services/component-services/item.service';
import { LocationService } from 'src/app/Services/component-services/location-service';
import { SubCategoryService } from 'src/app/Services/component-services/subcategory-service';

@Component({
  selector: 'app-import-item',
  templateUrl: './import-item.component.html',
  styleUrls: ['./import-item.component.scss']
})
export class ImportItemComponent implements OnInit {

  @ViewChild('UploadFileInput', { static: false }) uploadFileInput: ElementRef;
  //fileUploadForm: FormGroup;
  //fileInputLabel: string;

  selectedFile:any;
  fileReciverPath:string;
  isHeaderMatch:boolean=false;
  // private destroy$: Subject<void> = new Subject<void>();
  excelHeaders: string[] = ["Name*","Model","SerialNo","Code","Quantity*","Description","Location*","Category*","SubCategory*","ProcurmentURL","PurchaseCost","Currency","PurchaseDate"];
  errors:string;
  errorsList:string[];
  isLoading:boolean=false;
  fileAttr = 'Choose File';
btnDisables:boolean=true;
  @ViewChild('myInput')
myInputVariable: ElementRef;

  constructor(
    private _itemService: ItemService,
    private _subCategories :SubCategoryService,
    private _locations:LocationService,
    private _snackBar: MatSnackBar

    ) {

     }


  ngOnInit(): void {
  }
  DownloadItemExcelTemplate()
  {
    this._itemService.DownloadItemExcelTemplate().subscribe(blob => {
      saveAs(blob, 'ItemImportTemplate.xlsx');
    },(error)=>{
    });
  //  this.ExportToExcelListOfCategories();
   // this.ExportToExcelListOfLocations();
  }

  ExportToExcelListOfCategories()
  {
    this._subCategories.ExportToExcelListOfCategories().subscribe(blob => {
      saveAs(blob, 'CategoriesList.xlsx');
    },(error)=>{
    });
  }
  ExportToExcelListOfLocations()
  {
    this._locations.ExportToExcelListOfLocations().subscribe(blob => {
      saveAs(blob, 'LocationsList.xlsx');
    },(error)=>{
    });
  }


onFileSelect(event: any)
{

  this.errorsList=[];
  if (event.target.files.length > 0) {
    this.selectedFile=event.target.files[0];
    this.fileAttr = this.selectedFile.name;
     this.btnDisables=false
  }
}

  onfileUpload() {
    this.errorsList=[];
      if (this.selectedFile) {
this.isLoading=true;
        //! Upload file to temporary path until all the processing done
      const formData = new FormData();
      formData.append('file',this.selectedFile);
        this._itemService.ImportItemExcel(formData).subscribe(response=>{
        if(response!=null || response!="")
        {
          this.fileReciverPath=response[0];
          const formData = new FormData();
          formData.append('filePath',response[0]);
          formData.append('fileName',response[1]);

        //! Get the  headers of uploaded file
          this._itemService.GetExcelHeaders(formData).subscribe(res=>{
            if(res)
            {
              this.getExcelHeader(res);
            }
          },err=>{
            this._snackBar.open("Some error occures while processing file please re-upload file");
          })
        }
        else{
          this.isLoading=false;
          this._snackBar.open('Some Error Occure While Uploading File !','OK');
        }
        })
      }
      else{
        this._snackBar.open("Please select the Excel file ");
        return;
      }

  }
  getExcelHeader(request:any) {
    //! Compare  headers
    let i = 0;
    if(request.length!=this.excelHeaders.length)
    {
      this.isLoading=false;
      this.reset();
        this._snackBar.open('Columns in the file should match with the columns mentioned in the sample file!');
        this.isHeaderMatch=false;
        this._itemService.RemoveSavedItemExcelFile(this.fileReciverPath).subscribe(res=>{
        },err=>{
          this.reset();
          this._snackBar.open("Some error occures while processing file ");

        })
        return;
    }
    for (let j = 0; j < request.length; j++) {
      if (request[j] == this.excelHeaders[j]) {
        this.isHeaderMatch=true;
          i++;
      } else {
        this.isLoading=false;
        this.reset();
        this._snackBar.open('Column headres mismatch ['+this.excelHeaders[j]+']');
        this.isHeaderMatch=false;
        this._itemService.RemoveSavedItemExcelFile(this.fileReciverPath).subscribe(res=>{
        })
          break;
      }
  }
  if(this.isHeaderMatch)
  {
     //! If headers are correct then start processign importing
          this.ImportExcelDataToItems()
  }

  }

  ImportExcelDataToItems() {
    const formData = new FormData();
    formData.append('filePath', this.fileReciverPath);
   this._itemService.ImportExcelDataToItems(formData).subscribe(res=>{

     if(res)
     {
      this.isLoading=false;
      this.reset();
      this.errorsList=[];
      this._snackBar.open('Data Imported From Excel Successfully')
      this._itemService.RemoveSavedItemExcelFile(this.fileReciverPath).subscribe(res=>{

      })
     }

   },e=>{

    this.isLoading=false;
    this.reset();
     this.errors=e.error.Message;
    this.commaSeperatedStringToArray(this.errors)
    this._itemService.RemoveSavedItemExcelFile(this.fileReciverPath).subscribe(res=>{

    })
   })

  }

commaSeperatedStringToArray(errors:string)
{
   this.errorsList = errors.split(',');
}


reset() {
  this.myInputVariable.nativeElement.value = "";
  this.selectedFile="";
  this.fileAttr = "Choose File";
  this.btnDisables=true
}

}
