import { Component, OnInit,ElementRef ,ViewChild, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from '@angular/material/snack-bar';
import { ItemCheckOutHistoryModel } from 'src/app/Models/ComponentModels/ItemCheckOutHistoryModel';
import { ItemModel } from 'src/app/Models/ComponentModels/ItemModel';
import { UserModel } from 'src/app/Models/ComponentModels/UserModel';
import { ItemService } from 'src/app/Services/component-services/item.service';
import { StorageService } from 'src/app/Services/storage-service.service';
@Component({
  selector: 'app-add-notes-dialog',
  templateUrl: './add-notes-dialog.component.html',
  styleUrls: ['./add-notes-dialog.component.scss']
})
export class AddNotesDialogComponent implements OnInit {
  sizeExceeded: boolean = false;
  fileToUpload: any;
  notesText: string;
  uploadFileControlText:string = "Browse File";
  loading:boolean = false;
  @ViewChild('fileInput') fileInput: ElementRef;
  fileAttr = 'Choose File';
  filesToUpload: File[];

  constructor(
    public dialogRef: MatDialogRef<AddNotesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _itemService:ItemService,
    private _storageService:StorageService,
    private _snackBar: MatSnackBar
    ){}

  ngOnInit(): void {
  }
  close() {
    this.dialogRef.close();
  }
  videoFileSelected:boolean = false;
  uploadFileEvt(imgFile: any) {
    this.filesToUpload = new Array();

    if (imgFile.target.files) {
      var fileNames = '';

      for(var i =0; i<imgFile.target.files.length; i++){
        var size = imgFile.target.files[i].size;
        var fileType = imgFile.target.files[i].name.split('.').pop();
        this.videoFileSelected = !(fileType.toLowerCase() == 'jpg' || fileType.toLowerCase() == 'jpeg'|| fileType.toLowerCase() == 'png');
        size = size / (1024 * 1024);

        if (size > 4 && !this.videoFileSelected) {
          this.fileInput.nativeElement.value = "";
          this.uploadFileControlText = 'Choose File';
          this.sizeExceeded = true;
          return;
        }
        else {
          this.sizeExceeded = false;
        }
        if(fileNames != ''){
          fileNames = fileNames + ',';
        }
        fileNames = fileNames + imgFile.target.files[i].name;
        this.filesToUpload.push(imgFile.target.files[i]);
      }

      this.fileAttr = fileNames;
      this.uploadFileControlText = fileNames;

    } else {
      //this.fileAttr = 'Choose File';
    }
  }
  saveNotes(form:any){

    if (form.valid) {
      var formData = new FormData();

      var user = this._storageService.GetLoggedInUser();
      var userModelobj = new UserModel();

      if (user != null) {
        formData.append('IssuedByUserId', user.id);

        userModelobj.firstName = user.fullName;
        userModelobj.email = user.email;
      }

      formData.append('ItemId', this.data);
      formData.append('ActionName', 'Notes');
      formData.append('Notes', this.notesText);

      if (this.filesToUpload && this.filesToUpload.length > 0) {
        this.filesToUpload.forEach(function (item) {
          formData.append('file', item, item.name);
        });

      } else {
        formData.delete('file');
      }
      this.loading = true;
      this._itemService.saveRecord(formData).subscribe((x) => {
        this._snackBar.open('Note added successfully!');
        this.filesToUpload=[]

        this.loading = false;

        var itemHistory = new ItemCheckOutHistoryModel();
        itemHistory.id = x.data.id;
        itemHistory.imagePath = x.data.imagePath;
        itemHistory.videosPath = x.data.videosPath;
        itemHistory.created = new Date();
        itemHistory.actionName = 'Notes';
        itemHistory.notes = this.notesText;
        itemHistory.issuedByUser = userModelobj;
        itemHistory.issuedByUserFirstName = userModelobj?.firstName;
        itemHistory.issuedByUserLastName = userModelobj?.lastName;
        this.dialogRef.close(itemHistory);
      },(error)=>{
        this._snackBar.open('Note added but could not send email !');
        this.loading = false;
        this.dialogRef.close();
      });
    }
  }
  OnImageDeselect(file:any)
  {
   this.filesToUpload.filter(x=>{
     if(x.name==file.name)
     {
      this.filesToUpload.splice(this.filesToUpload.indexOf(x),1)
     }

   })
  }
}
