import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CategoryModel } from 'src/app/Models/ComponentModels/Category';
import { SubCategoryModel } from 'src/app/Models/ComponentModels/SubCategory';
import { CategoryService } from 'src/app/Services/component-services/category-Service';
import { SubCategoryService } from 'src/app/Services/component-services/subcategory-service';
import { DataShareService } from 'src/app/Services/data-share.service';

@Component({
  selector: 'app-add-sub-category',
  templateUrl: './add-sub-category.component.html',
  styleUrls: ['./add-sub-category.component.scss']
})
export class AddSubCategoryComponent implements OnInit {

  Subcategory_name:string
  subCategoryModel:SubCategoryModel;
  Categories: CategoryModel[] = [];
  isLoading = false;

  constructor(
               private _categoryService: CategoryService,
               private _subCategoryService: SubCategoryService,
               private _snackBar: MatSnackBar,
               public SubCategoryModalRef: MatDialogRef<AddSubCategoryComponent>,
               private _dataShareService: DataShareService


    )
  {
    this.subCategoryModel=new SubCategoryModel()

  }
  ngOnInit(): void {
    this.populateCategories()
  }
  private populateCategories() {
    this._categoryService.getAllCategories().subscribe((x) => {
      this.Categories = x.data//== undefined ? new CategoryModel[]() : x.data;
    })
  }

  AddNewSubCategory(form: NgForm)
  {
    if (form.valid) {
      if(!this.subCategoryModel.categoryId){
        this._snackBar.open("Category is required.");
        return;
      }
       if(!this.subCategoryModel.name){
        this._snackBar.open("SubCategory name is required.");
        return;
      }
      if(this.subCategoryModel.name.length<3)
      {
        this._snackBar.open("SubCategory name length Should be greater than 3 Letters");
        return;
      }
      this.isLoading=true
    this._subCategoryService.addNewSubCategory(this.subCategoryModel).subscribe(response=>{
      this._snackBar.open("SubCategory Added successfully.", "Ok");
      if(response.data)
      {
        this._dataShareService.changeSubCategoryId(response.data)
      }

      this.CloseSubCategoryModal();
      this.isLoading=false
    },(errorResponse)=>{
      //this._snackBar.open(errorResponse.error.Message, "Ok!");
      this._snackBar.open("Unable to Add Duplicate SubCategory", "Ok!");
      this.isLoading=false

    })
  }
  }

  CloseSubCategoryModal(): void {
    this.SubCategoryModalRef.close();
  }

  CloseSubCategoryModalByButton()
  {
    this._dataShareService.changeSubCategoryId(0)
    this.SubCategoryModalRef.close();
  }
}
