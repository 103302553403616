import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CategoryModel } from 'src/app/Models/ComponentModels/Category';
import { CategoryService } from 'src/app/Services/component-services/category-Service';
import { DataShareService } from 'src/app/Services/data-share.service';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.scss'],
})
export class AddCategoryComponent implements OnInit {
  categoryRequest: CategoryModel;
  isLoading = false;
  categoryResponse: CategoryModel[];
  SetCategoryBtn: string = 'Add';

  displayedColumns: string[] = ['ID','Category','Actions'];

  message:string;


  constructor(
    private _categoryService: CategoryService,
    private _snackBar: MatSnackBar,
    public categoryModalRef: MatDialogRef<AddCategoryComponent>,
    private _dataShareService: DataShareService
  ) {
    this.categoryRequest = new CategoryModel();
  }
  ngOnInit(): void {
    this.categoryResponse = [];
    this._categoryService.getAllCategories().subscribe((response) => {
      this.categoryResponse = response.data;
    });
  }

  SetCategory() {
    if (this.categoryRequest.name.length < 3) {
      this._snackBar.open(
        'Category Name Should be at least 3 character long.',
        'Ok'
      );
      return;
    }
    if (!this.categoryRequest.id) {
      this.AddNewCategory();
    } else {
      this.UpdateCategory();
    }
  }

  AddNewCategory() {
    this.isLoading = true;
    this._categoryService.addNewCategory(this.categoryRequest).subscribe(
      (response) => {
        this._snackBar.open('Category Added successfully.', 'Ok');
        this.isLoading = false;
        if(response.data)
        {
          this._dataShareService.changeCategoryId(response.data)
        }
       this.CloseCategoryModal();
      },
      (errorResponse) => {
        this._snackBar.open('Unable to Add the Duplicate Category', 'Ok!');
        this.isLoading = false;
      }
    );
  }

  UpdateCategory() {
    this.isLoading = true;
    this._categoryService.UpdateCategory(this.categoryRequest).subscribe(
      (response) => {
        this._snackBar.open('Category Updated successfully.', 'Ok');
        this.isLoading = false;
        this.CloseCategoryModal();
      },
      (errorResponse) => {
        this._snackBar.open('Unable to Update  Category', 'Ok!');
        this.isLoading = false;
      }
    );
  }

  EditCategory(category: any) {
    this.categoryRequest = category;
    this.SetCategoryBtn = 'Update';
  }

  CloseCategoryModal(): void {
    this.categoryModalRef.close();
  }

  CloseCategoryModalByButton()
  {
    this._dataShareService.changeCategoryId(0)
    this.categoryModalRef.close();
  }


}
