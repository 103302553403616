<mat-progress-bar [ngStyle]="{'visibility':isLoading==true ? 'visible' : 'hidden'}" color="primary" mode="indeterminate"></mat-progress-bar>

<div class="col-lg-12 components-block">
  <div class="components-header">
    <h2>Access Groups</h2>
    <button   mat-raised-button color="primary" mat-button class="save-btn" style="width: auto" routerLink="/home/add-access-groups">Add Access Group</button>

  </div>





  <div class="mat-table-block">

    <table mat-table matSort (matSortChange)="SortChange($event)" [dataSource]="gridData">

      <ng-container matColumnDef="name">
        <th mat-header-cell  *matHeaderCellDef style="width:20%;" mat-sort-header sortActionDescription="name"> Name </th>
        <td mat-cell *matCellDef="let item" style="width:20%;"> {{item.name}}</td>
      </ng-container>

      <ng-container matColumnDef="categories">
        <th mat-header-cell  *matHeaderCellDef style="width:25%; text-align: left;" mat-sort-header sortActionDescription="qtyIcategoriesssued"><span>Categories</span>  </th>
        <td mat-cell *matCellDef="let item"  style="width:25%; text-align: left;">

          {{item.isFullAccessGiver? 'All Categories':formatCommaSeparatedNameList(item.relatedCategories)}}
        </td>


      </ng-container>



      <ng-container matColumnDef="permissions">
        <th mat-header-cell  *matHeaderCellDef style="width:45%; text-align: left;" mat-sort-header sortActionDescription="qtyIcategoriesssued"><span>Permissions</span>  </th>
        <td mat-cell *matCellDef="let item" style="width: 45%; text-align: left;">
          {{item.isFullAccessGiver? 'All Permissions':formatCommaSeparatedPermissionsList(item.relatedAccessPermissions)}}

        </td>

      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef style="width:10%; text-align: left;"> </th>
        <td mat-cell *matCellDef="let itemObj" class="action-link" style="width:10%; text-align: left;">
          <span class="actions-box">
            <button [disabled]="itemObj.isFullAccessGiver || !userAccessPermission.updateAccessGroup" [ngStyle]="{'opacity': (itemObj.isFullAccessGiver) ? '0.5' : '1'}"
             [matTooltip]="userAccessPermission.updateAccessGroup ? 'Edit' : '' "
             matTooltipClass="matTooltip-Custom"
             matTooltipPosition="above"
              class="column-name-link action-icon" routerLink="/home/add-access-groups/{{itemObj.id}}">
              <mat-icon
              [matTooltip]="!userAccessPermission.updateAccessGroup ? _storageService.notEnogugRights : '' "
              matTooltipClass="matTooltip-Custom"
              matTooltipPosition="above"
              >edit</mat-icon>
            </button>

            <button [disabled]="itemObj.isFullAccessGiver || !userAccessPermission.deleteAccessGroup" [ngStyle]="{'opacity': (itemObj.isFullAccessGiver) ? '0.5' : '1'}"
             [matTooltip]="userAccessPermission.deleteAccessGroup ? 'Delete' : '' "
             matTooltipClass="matTooltip-Custom"
             matTooltipPosition="above"
              class="column-name-link action-icon" (click)="confirmDialog(itemObj)">
              <mat-icon
              [matTooltip]="!userAccessPermission.deleteAccessGroup ? _storageService.notEnogugRights : '' "
              matTooltipClass="matTooltip-Custom"
              matTooltipPosition="above">delete</mat-icon>
            </button>
          </span>

        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[25, 50, 100]" #paginator [pageIndex]="currentPage" [pageSize]="pageSize"
      (page)="pageChanged($event)" showFirstLastButtons aria-label="Select page of items">
    </mat-paginator>
  </div>
</div>
