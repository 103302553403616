import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ItemModel } from 'src/app/Models/ComponentModels/ItemModel';
import { environment } from 'src/environments/environment';
import { AppURLs,AppConstants } from 'src/app/AppConstants';
import { ApiPagedResponse } from 'src/app/Models/Api-Response-Paged';
import { ApiSingleResponse } from 'src/app/Models/API-Response-Single';
import { APIResponseNonPagedSingle } from 'src/app/Models/API-Response-NonPagedSingle';
import { ItemCheckOutHistoryModel } from 'src/app/Models/ComponentModels/ItemCheckOutHistoryModel';
import { CheckinCheckoutReport } from 'src/app/Models/ComponentModels/Checkin-Checkout-Report';
import { ActiveCheckOutsReportModel } from 'src/app/Models/ComponentModels/ActiveCheckoutsReportsModel';
import { StringMappingType } from 'typescript';


@Injectable({
  providedIn: 'root'
})
export class ReportService {
  baseURL = environment.ApiUrl;
  URLs:AppURLs;
  ReportServiceURL:string;

  constructor(private _http:HttpClient) {
    this.URLs = new AppURLs(AppConstants.APIVersion);

    this.ReportServiceURL = environment.apiBaseUrl + this.URLs.ReportsURL;
   }
   public getItemCheckinCheckoutReport(
    pageNo: Number,
    pageSize: Number,
    SortProperty: string,
    sortDirection: string,
    userId: string,
    typeFilter: string,
    selectedStartDate: string,
    selectedEndDate: string,
    categoryFilter: string,
    subCategoryFilter: string,
    item_checkin_out_filter: string,
    chekoutType:string
  ): Observable<ApiPagedResponse<CheckinCheckoutReport>> {
    return this._http.get<ApiPagedResponse<CheckinCheckoutReport>>(
      this.ReportServiceURL +
        `/Checkin-CheckOut-Report?PageNumber=${pageNo}&PageSize=${pageSize}&SortProperty=${SortProperty}&sortDirection=${sortDirection}&userId=${userId}&typeFilter=${typeFilter}
    &StartDate=${selectedStartDate}&EndDate=${selectedEndDate}&categoryFilter=${categoryFilter}&subCategoryFilter=${subCategoryFilter}
    &item_checkin_out_filter=${item_checkin_out_filter}&chekoutType=${chekoutType}`
    );
  }

   public downloadCheckinCheckOutReport(
    pageNo: Number,
    pageSize: Number,
    SortProperty: string,
    sortDirection: string,
    userId: string,
    typeFilter: string,
    selectedStartDate: string,
    selectedEndDate: string,
    categoryFilter: string,
    subCategoryFilter: string,
    item_checkin_out_filter: string
  ): Observable<any> {
    return this._http.get(
      this.ReportServiceURL +
        `/DownloadCheckinCheckOutReport?PageNumber=${pageNo}&PageSize=${pageSize}&SortProperty=${SortProperty}&sortDirection=${sortDirection}&userId=${userId}&typeFilter=${typeFilter}
        &StartDate=${selectedStartDate}&EndDate=${selectedEndDate}&categoryFilter=${categoryFilter}&subCategoryFilter=${subCategoryFilter}&item_checkin_out_filter=${item_checkin_out_filter}`,
      { responseType: 'blob' }
    );
  }

   public getActiveCheckOutsReport(pageNo:Number, pageSize:Number,SortProperty:string,sortDirection:string, userId:string, startDate:string, endDate:string):Observable<ApiPagedResponse<ActiveCheckOutsReportModel>> {
    return this._http.get<ApiPagedResponse<ActiveCheckOutsReportModel>>(this.ReportServiceURL + `/Active-CheckOuts-Report?PageNumber=${pageNo}&PageSize=${pageSize}&SortProperty=${SortProperty}&sortDirection=${sortDirection}&userId=${userId}&startDate=${startDate}&endDate=${endDate}`);
  }
  public downloadActiveCheckOutsReport(pageNo:Number, pageSize:Number,SortProperty:string,sortDirection:string, userId:string, startDate:string,endDate:string):Observable<any> {
    return this._http.get(this.ReportServiceURL + `/Download-Active-CheckOuts-Report?PageNumber=${pageNo}&PageSize=${pageSize}&SortProperty=${SortProperty}&sortDirection=${sortDirection}&userId=${userId}&startDate=${startDate}&endDate=${endDate}`,{responseType: "blob"});
  }

}
