

import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { AppComponent } from './components/AppComponent/app.component';
import {MatRadioModule} from '@angular/material/radio';

//********************************* Feature  Modules *********************************
import { ToastrModule } from 'ngx-toastr';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { BreadcrumbModule} from 'xng-breadcrumb';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { LightgalleryModule } from 'lightgallery/angular';
import { MaterialSharedModule } from './Shared/material-shared/material-shared.module';
import { NgImageSliderModule } from 'ng-image-slider';
import * as Sentry from "@sentry/angular";

//********************************* Feature  Components *********************************
import { LoginComponent } from './components/login/login.component';
import { BreadcrumbComponent } from './components/layouts/breadcrumb/breadcrumb.component';
import { UsersListComponent } from './components/Users/list/users-list/users-list.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { UsersAddComponent } from './components/Users/add/users-add.component';
import { TableComponent } from './components/table/table.component';
import { ItemDetailsHistoryComponent } from './components/Items/item-details-history/item-details-history.component';
import { ItemDetailsTabComponent } from './components/Items/item-details-tab/item-details-tab.component';
import { SidebarnavComponent } from './components/layouts/sidebarnav/sidebarnav.component';
import { ItemsListingComponent } from './components/Items/items-listing/items-listing.component';
import { ItemsEditComponent } from './components/Items/items-edit/items-edit.component';
import { ItemsAddComponent } from './components/Items/items-add/items-add.component';
import { HomeComponent } from './components/home/home.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { CheckinCheckoutReportComponent } from './components/reports/checkin-checkout-report/checkin-checkout-report.component';
import { AddNotesDialogComponent } from './components/Items/add-notes-dialog/add-notes-dialog.component';
import { ItemsAddDuplicateComponent } from './components/items/items-add-duplicate/items-add-duplicate.component';
import { LocationsAddComponent } from './components/location/locations-add/locations-add.component';
import { AddCategoryComponent } from './components/Items/categories/add-category/add-category.component';
import { AddSubCategoryComponent } from './components/Items/categories/add-sub-category/add-sub-category.component';
import { LocationEditComponent } from './components/location/location-edit/location-edit.component';
import { LocationsListComponent } from './components/location/locations-list/locations-list.component';
import { CategoriesComponent } from './components/categories/categories/categories.component';
import { EditNotesDialogComponent } from './components/Items/edit-notes-dialog/edit-notes-dialog.component';
import { ActiveCheckoutsComponent } from './components/reports/active-checkouts/active-checkouts.component';
import { AccessGroupListingComponent } from './components/AccessGroup/listing/listing.component';

//*********************************  Services *********************************
import { AuthInterceptorService } from './Services/auth-interceptor.service';
import { StorageService } from './Services/storage-service.service';
import { SubCategoryService } from './Services/component-services/subcategory-service';
import { CategoryService } from './Services/component-services/category-Service';
import { LocationService } from './Services/component-services/location-service';
import { UserService } from './Services/component-services/user-service';
import { AuthenticationService} from './Services/auth-service';
import { MatDialogRef } from '@angular/material/dialog';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material/snack-bar';
import { AuthGuard } from './Guards/auth.guard';
import { DataShareService } from './Services/data-share.service';
import { AddAccessGroupComponent } from './components/AccessGroup/add-access-group/add-access-group.component';
import { MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { SuperAdminGuard } from './Guards/superAdmin.guard';
import { Router } from '@angular/router';
import { ImportItemComponent } from './components/Items/import-item/import-item.component';
import { MatPseudoCheckboxModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { SummaryAlertsComponent } from './components/summary-alerts/summary-alerts.component';
import { NotEnoughRightsComponent } from './components/not-enough-rights/not-enough-rights.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};
@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        HomeComponent,
        SidebarnavComponent,
        ItemsAddComponent,
        ItemsListingComponent,
        ItemsEditComponent,
        ItemDetailsTabComponent,
        TableComponent,
        UsersAddComponent,
        NotfoundComponent,
        UsersListComponent,
        BreadcrumbComponent,
        ItemDetailsHistoryComponent,
        ConfirmDialogComponent,
        CheckinCheckoutReportComponent,
        AddNotesDialogComponent,
        ItemsAddDuplicateComponent,
        LocationsAddComponent,
        AddCategoryComponent,
        AddSubCategoryComponent,
        LocationEditComponent,
        LocationsListComponent,
        CategoriesComponent,
        EditNotesDialogComponent,
        ActiveCheckoutsComponent,
        AccessGroupListingComponent,
        AddAccessGroupComponent,
        ImportItemComponent,
        SummaryAlertsComponent,
        NotEnoughRightsComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        MDBBootstrapModule,
        HttpClientModule,
        FormsModule,
        // Featueres
        NgxMaskModule.forRoot(maskConfig),
        BreadcrumbModule,
        ToastrModule.forRoot(),
        LightgalleryModule,
        NgImageSliderModule,
        MaterialSharedModule,
        MatRadioModule,
        MatPseudoCheckboxModule,
    ],
    providers: [
        AuthGuard,
        SuperAdminGuard,
        AuthenticationService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true,
        },
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 8000 } },
        {
            provide: MAT_CHIPS_DEFAULT_OPTIONS,
            useValue: {
                separatorKeyCodes: [ENTER, COMMA]
            },
        },
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        StorageService,
        UserService,
        LocationService,
        CategoryService,
        SubCategoryService,
        {
            provide: MatDialogRef,
            useValue: {}
        },
        DataShareService,
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

}
