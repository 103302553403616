import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Breadcrumb } from 'src/app/Models/ComponentModels/breadcrumb.model';
import { BreadcrumbService } from 'xng-breadcrumb';
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  constructor(private breadcrumbService: BreadcrumbService) {

  }



  ngOnInit(): void {
  }

}
