import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import {AuthenticationService} from '../../Services/auth-service';
import { StorageService } from '../../Services/storage-service.service';
@Injectable({
  providedIn: 'root'
})
export class UserUpdatGuard implements CanActivate {
  constructor(private router:Router,private authenticationService: AuthenticationService, private storageService:StorageService){

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {


      const currentUser = this.storageService.GetLoggedInUser();
      const userAccessPermission=this.storageService.GetLoginUserPermissions()

      if (userAccessPermission.updateUser) {

          return true;
      }

      this.router.navigate(['/home/notEnoughRights']);
      return false;
  }

}
