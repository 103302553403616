
<mat-progress-bar [ngStyle]="{'visibility':isLoadingProg==true ? 'visible' : 'hidden'}" color="primary"
  mode="indeterminate"></mat-progress-bar>

<form name="UserForm" [formGroup]="profileForm"  (ngSubmit)="saveuser()" novalidate>
    <div class="col-lg-11 components-block">

      <div class="components-header">
        <h2>Add User</h2>
        <button type="submit" mat-raised-button color="primary" [disabled]="!profileForm.enabled" mat-button class="save-btn">Save</button>
        <button type="button" class="cancel-btn"  routerLink="/home/users" mat-raised-button color="lighter" mat-button>Cancel</button>

      </div>
      <div class="components-form">

        <div class="components-vertical-tab">
          <div class="components-vertical-link">
            <ul>
              <li><a>User Information</a></li>
            </ul>
          </div>
          <div class="components-vertical-content">

            <div class="item-information-block">
              <h2>Search User Information</h2>
               <div class="row item-row">
                <div class="col-lg-6 form-group searchbox-group">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Search User</mat-label>
                    <input id="search-box #searchBox" disabled="{{isEditUserMode}}" matInput placeholder="Search" name="searchUser" ControlName="searchUserControl" value="{{searcheduser.firstName}} {{searcheduser.lastName}}"
                      aria-label="State" [matAutocomplete]="auto" (keyup)="SearchUsers($event)">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn(Users)" (optionSelected)="selectUser($event.option.value,Users)">
                      <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                      <ng-container *ngIf="!isLoading">
                        <mat-option *ngFor="let user of Users" [value]="user.id">
                          <span>{{user.firstName == null ?'':user.firstName}} {{user.lastName == null ?'':user.lastName}}<b>&nbsp;{{user.employeeId== null ?'':'ID:' + user.employeeId}}</b></span>
                        </mat-option>
                      </ng-container>
                    </mat-autocomplete>
                  </mat-form-field>
                  <button type="button" (click)="clearForm()" class="searchboxclose"><mat-icon>close</mat-icon></button>
                </div>
                </div>
              <h2>User Information</h2>
              <div class="row item-row" >
                <div class="col-lg-6 form-group" >
                  <mat-form-field class="full-width" appearance="outline" >
                    <mat-label>Role</mat-label>
                    <mat-select #selectedRoles multiple formControlName="selectedRoles" (selectionChange)="OnRoleChange($event)">
                      <mat-option *ngFor="let role of UserRoles" [value]="role.id">{{role.name}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="profileForm.controls.selectedRoles.invalid">
                      Please select role
                    </mat-error>
                  </mat-form-field>
                  </div>

                  <div class="col-lg-6 form-group">
                      <!-- <mat-slide-toggle
                      *ngIf="searcheduser.isEmployee"
                      [disabled]='searcheduser.isEmployee && !ChangeEmployee'
                      matTooltip="Employees are not allowed access to AMS"
                      formControlName="allowIMS" [(ngModel)]="searcheduser.allowIMS">
                    </mat-slide-toggle> -->


                  <mat-slide-toggle
                  [disabled]='!superAdmin'
                  [matTooltip]="!toogleToolTip ? 'Only SuperAdmin can change the access' : ''"
                  formControlName="allowIMS" [(ngModel)]="searcheduser.allowIMS">
                </mat-slide-toggle>
                <span class="mat-toggle-text">{{ searcheduser.allowIMS ? 'On' : 'Off' }}</span>
                  </div>
              </div>
              <div class="row item-row">
                 <div class="col-lg-6 form-group">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>First Name</mat-label>
                    <input required name="firstName" matInput placeholder="Enter your First Name"
                    formControlName="firstName" [(ngModel)]="searcheduser.firstName">
                      <mat-error *ngIf="profileForm.controls.firstName.hasError('required')">
                        First Name is <strong>required</strong>
                      </mat-error>
                  </mat-form-field>
                </div>

                <div class="col-lg-6 form-group">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Last Name</mat-label>
                    <input required name="lastName" matInput placeholder="Enter your Last Name"
                    formControlName="lastName" [(ngModel)]="searcheduser.lastName">
                    <mat-error *ngIf="profileForm.controls.lastName.hasError('required')">
                      Last Name is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 form-group">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>User Name</mat-label>
                    <input readonly required name="username" minlength="5" matInput placeholder="Enter your username"
                    formControlName="userName" [(ngModel)]="searcheduser.userName">
                    <mat-error *ngIf="profileForm.controls.userName.hasError('required')">
                      User Name is <strong>required</strong>
                    </mat-error>
                      <mat-error *ngIf="profileForm.controls.userName.errors?.minlength">
                        userName must be atleast <strong>6 characters long.</strong>
                      </mat-error>
                  </mat-form-field>
                </div>

                <div class="col-lg-6 form-group">
                  <mat-form-field class="full-width readonly-field" appearance="outline">
                    <mat-label>Employee ID</mat-label>
                    <input readonly name="employeeid" type="number" matInput placeholder="Enter your employee ID" required
                    formControlName="employeeId" [(ngModel)]="searcheduser.employeeId">
                    <mat-error *ngIf="profileForm.controls.employeeId.hasError('required')">
                      Employee ID is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>

                </div>
                <div class="col-lg-12 form-group">
                  <mat-form-field class="full-width readonly-field" appearance="outline">
                    <mat-label>Address</mat-label>
                    <textarea readonly name="address"  type="text" matInput placeholder="Enter your address" required
                    formControlName="address" [(ngModel)]="searcheduser.address"></textarea>

                    <mat-error *ngIf="profileForm.controls.address.hasError('required')">
                      Address is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-lg-12 form-group">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Address 2</mat-label>
                    <textarea name="Address2"  type="text" matInput placeholder="Enter your address"
                    formControlName="address2" [(ngModel)]="searcheduser.address2"></textarea>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 form-group">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Status</mat-label>
                    <mat-select name="status" required formControlName="status" [(ngModel)]="searcheduser.status">
                      <mat-option>None</mat-option>
                      <mat-option *ngFor="let status of statuslist" [value]="status.value">
                        {{status.name}}
                      </mat-option>

                    </mat-select>
                    <mat-error *ngIf="profileForm.controls.status.hasError('required')">
                      Status ID is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 form-group">
                  <mat-form-field class="full-width  readonly-field" appearance="outline">
                    <mat-label>Email</mat-label>
                    <input readonly type="email" required name="email"  matInput placeholder="Enter your email" formControlName="email" [(ngModel)]="searcheduser.email">
                    <mat-error *ngIf="profileForm.controls.email.invalid">
                      Please provide valid email
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="col-lg-6 form-group">
                  <mat-form-field class="full-width readonly-field" appearance="outline">
                    <mat-label>Phone Number</mat-label>
                    <input readonly name="phonenumber"  matInput placeholder="Enter your phonenumber" mask="(0000) 0000000"
                    formControlName="phoneNumber" [(ngModel)]="searcheduser.phoneNumber">
                    <mat-error *ngIf="profileForm.controls.phoneNumber.invalid">
                      Please provide valid Phone Number
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 form-group" >
                  <mat-form-field class="full-width" appearance="outline" >
                    <mat-label>Access Groups</mat-label>
                    <mat-select
                    #selectedAccessGroups multiple formControlName="selectedAccessGroups">
                      <mat-option *ngFor="let group of AccessGroups" [value]="group.id">{{group.name}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="profileForm.controls.selectedAccessGroups.invalid">
                      Please select Access Group
                    </mat-error>
                  </mat-form-field>
                  </div>
                <input type="hidden" name="userId" formControlName="Id" [(ngModel)]="searcheduser.id">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
