import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants, AppURLs } from 'src/app/AppConstants';
import { APIResponseNonPagedSingle } from 'src/app/Models/API-Response-NonPagedSingle';
import { ApiPagedResponse } from 'src/app/Models/Api-Response-Paged';
import { ApiSingleResponse } from 'src/app/Models/API-Response-Single';
import { AccessGroupModel } from 'src/app/Models/ComponentModels/AccessGroupModel';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccessGroupService {

  URLs:AppURLs;
  accessGroupServiceURL:string;

  constructor(private _http:HttpClient) {
    this.URLs = new AppURLs(AppConstants.APIVersion);

    this.accessGroupServiceURL = environment.apiBaseUrl + this.URLs.AccessGroup;
   }
   public saveAccessGroup(data:any):Observable<any> {
    return this._http.post<any>(this.accessGroupServiceURL,data);
  }
  public updateAccessGroup(formData:any):Observable<number>{
    return this._http.put<number>(this.accessGroupServiceURL,formData);
  }
  getAllAccessGroups():Observable<ApiSingleResponse<AccessGroupModel>>{
    return this._http.get<ApiSingleResponse<AccessGroupModel>>(this.accessGroupServiceURL);
  }
  getAccessGroupById(Id:number):Observable<APIResponseNonPagedSingle<AccessGroupModel>>{
    return this._http.get<APIResponseNonPagedSingle<AccessGroupModel>>(this.accessGroupServiceURL + '/' + Id);
  }

  public deleteAccessGroup(accessGroupId:number):Observable<number>{
    return this._http.delete<number>(this.accessGroupServiceURL + '/' + accessGroupId);
  }

  getAllAccessGroupsPagination(pageNo:Number, pageSize:Number):Observable<ApiPagedResponse<AccessGroupModel>>{
    return this._http.get<ApiPagedResponse<AccessGroupModel>>(this.accessGroupServiceURL+`?PageNumber=${pageNo}&PageSize=${pageSize}`);
  }


  // public getLocations(pageNo:Number, pageSize:Number, NameOrCodeFilter:string):Observable<ApiPagedResponse<LocationModel>> {
  //   return this._http.get<ApiPagedResponse<LocationModel>>(this.locationServiceURL + `?PageNumber=${pageNo}&PageSize=${pageSize}&NameOrCodeFilter=${NameOrCodeFilter}`);
  // }

}
