<form name="ItemForm" #ItemForm="ngForm" (submit)="saveItem(ItemForm)" novalidate>
  <div class="col-lg-11 components-block">

    <div class="components-header">
      <h2>Add Item</h2>
      <button type="submit" mat-raised-button color="primary" [disabled]="isLoading" mat-button
        class="save-btn">Save</button>
    </div>
    <div class="components-form">

      <div class="components-vertical-tab">
        <div class="components-vertical-link">
          <ul>
            <li><a>Item Information</a></li>
          </ul>
        </div>
        <div class="components-vertical-content">

          <div class="item-information-block">
            <h2>Item Information</h2>
            <div class="row item-row">
              <div class="col-lg-6 form-group">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Name</mat-label>
                  <input required name="ItemName" #ItemName="ngModel" matInput placeholder="Enter your Item Name"
                    [(ngModel)]="formData.name">
                  <!-- <mat-error *ngIf="ItemName.hasError('required')">
                      Item Name is <strong>required</strong>
                    </mat-error> -->
                </mat-form-field>
              </div>

              <div class="col-lg-6 form-group">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Code</mat-label>
                  <input name="Code" type="text" matInput placeholder="Enter Code" #Code="ngModel"
                    [(ngModel)]="formData.code">
                </mat-form-field>
              </div>
              <div class="col-lg-6 form-group">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Model</mat-label>
                  <input name="ItemModel" #ItemName="ngModel" matInput placeholder="Enter your Item Model"
                    [(ngModel)]="formData.model">

                </mat-form-field>
              </div>
              <div class="col-lg-6 form-group">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Serial No</mat-label>
                  <input name="SerialNo" #SerialNo="ngModel" matInput placeholder="Enter Serial No"
                    [(ngModel)]="formData.serialNo">
                </mat-form-field>
              </div>

              <div class="col-lg-6 form-group">
                <mat-form-field   class="full-width" appearance="outline">
                  <mat-label>Quantity</mat-label>
                  <input [disabled]="IsManagerUser" name="Quantity" type="number" matInput placeholder="Enter your Quantity" required
                    #Quantity="ngModel" [(ngModel)]="formData.quantity" min="1">
                </mat-form-field>
              </div>

              <div class="col-lg-12 form-group">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Description</mat-label>
                  <textarea name="Description" matInput placeholder="Enter your Description" #Description="ngModel"
                    [(ngModel)]="formData.description"></textarea>
                </mat-form-field>
              </div>

              <div class="col-lg-6 form-group">
                <div class="category-form-group">
                  <mat-form-field class="full-width category-form-field" appearance="outline">
                    <mat-label>Select a Category</mat-label>
                    <mat-select name="Category" #Category="ngModel" [(ngModel)]="formData.categoryId"
                      (selectionChange)="updateSubCategories($event)" required>
                      <mat-option>None</mat-option>
                      <mat-option *ngFor="let category of Categories" [value]="category.id">
                        {{category.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <a (click)="AddNewCategory()" class="addbox">
                    <mat-icon>library_add</mat-icon>
                  </a>
                </div>
              </div>
              <div class="col-lg-6 form-group">
                <div class="category-form-group">
                  <mat-form-field class="full-width category-form-field" appearance="outline">
                    <mat-label>Sub Category</mat-label>
                    <mat-select name="SubCategory" [(ngModel)]="formData.subCategoryId" required>
                      <mat-option>None</mat-option>
                      <mat-option *ngFor="let subCategory of SubCategories" [value]="subCategory.id">
                        {{subCategory.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <a (click)="AddSubCategory()" class="addbox">
                    <mat-icon>library_add</mat-icon>
                  </a>
                </div>
              </div>

              <div class="col-lg-6 form-group">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Responsible</mat-label>
                  <input matInput placeholder="Search" name="responsibleUser" [(ngModel)]="formData.responsibleUserId"
                    aria-label="State" [matAutocomplete]="autoResponsibleUser" [formControl]="responsibleUserControl">
                  <mat-autocomplete #autoResponsibleUser="matAutocomplete" [displayWith]="displayFn(admins)">
                    <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                    <ng-container *ngIf="!isLoading">
                      <mat-option *ngFor="let user of adminsFilter | async" [value]="user.id">
                        <span>{{user.firstName}} {{user.lastName}}<b
                            *ngIf="user.employeeId">({{user.employeeId}})</b></span>
                      </mat-option>
                    </ng-container>
                  </mat-autocomplete>
                </mat-form-field>
              </div>

              <div class="col-lg-6 form-group">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Default Location</mat-label>
                  <mat-select [disabled]="locationDisabled"
                  name="location"
                  required #Location="ngModel" [(ngModel)]="formData.locationId"
                  >
                    <mat-option>None</mat-option>
                    <mat-option  *ngFor="let location of Locations" [value]="location.id">
                      {{location.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>



              <div  class="col-lg-6 form-group" [hidden]="IsManagerUser">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Procurement URL</mat-label>
                  <input name="ProcurementURL" matInput placeholder="Enter your Procurement URL"
                    [(ngModel)]="formData.procurmentURL">
                </mat-form-field>
              </div>

              <div class="col-lg-6 form-group">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Status</mat-label>
                  <mat-select disabled="true" name="Status" #Status="ngModel" [(ngModel)]="formData.status">
                    <mat-option value="In Stock">In Stock</mat-option>
                    <mat-option value="Checked-out">Checked-out</mat-option>
                    <mat-option value="Lost/Stolen">Lost/Stolen</mat-option>
                    <mat-option value="Out for Repair">Out for Repair</mat-option>
                    <mat-option value="Broken – Not Fixable">Broken – Not Fixable</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-lg-4 form-group" [hidden]="IsManagerUser">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Purchase Cost</mat-label>
                  <input name="PurchaseCost" type="number" matInput placeholder="Enter Purchase Cost"
                    #PurchaseCost="ngModel" [(ngModel)]="formData.purchaseCost">
                </mat-form-field>
              </div>

              <div class="col-lg-2 form-group" [hidden]="IsManagerUser">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-select [disabled]="formData.purchaseCost==undefined || formData.purchaseCost<1" name="Currency"
                    #Currency="ngModel" [(ngModel)]="formData.currency">
                    <mat-option *ngFor="let cur of _dataShareService.Currencies" [value]="cur">
                      {{cur}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-lg-6 form-group" [hidden]="IsManagerUser">
                <mat-form-field appearance="outline" class="example-form-field">
                  <mat-label>Purchase Date</mat-label>
                  <input matInput [matDatepicker]="datepicker" format='dd/MM/yyyy hh:mm a' name="PurchaseDate"
                    #PurchaseDate="ngModel" [(ngModel)]="formData.purchaseDate" [max]="today">
                  <mat-error *ngIf="PurchaseDate.invalid">
                    Please provide valid Purchase Date
                  </mat-error>
                  <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                  <mat-datepicker #datepicker>
                    <mat-datepicker-actions>
                      <button mat-button matDatepickerCancel>Cancel</button>
                      <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                    </mat-datepicker-actions>
                  </mat-datepicker>
                </mat-form-field>
              </div>


              <div class="col-lg-12 form-group form-tags-group">
                <mat-form-field class="example-chip-list" appearance="outline">
                  <mat-label>Add Tags</mat-label>
                  <mat-chip-list #chipList aria-label="Fruit selection">
                    <mat-chip *ngFor="let tag of tags" (removed)="remove(tag)">
                      {{tag}}
                      <button matChipRemove>
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </mat-chip>
                    <input (keyup)="applyFilter()" placeholder="New Tag" #tagInput [formControl]="tagCtrl" [matAutocomplete]="auto"
                      [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      (matChipInputTokenEnd)="add($event)" [(ngModel)]="tagNameSerch">
                  </mat-chip-list>
                  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                    <mat-option *ngFor="let tag of TagResponse" [value]="tag.tagId">
                      {{tag.tagName}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>


            </div>


            <br>
            <div class="col-lg-4 form-group"></div>
            <h2>Specs </h2>
            <div class="row item-row">
              <div class="col-lg-5 form-group">
                <mat-form-field class="full-width" appearance="outline">
                  <input type="text" name="SpecificationName" placeholder="Name" aria-label="Specification Name"
                    matInput [formControl]="SpecificationNameControl" [matAutocomplete]="specAuto"
                    [(ngModel)]="itemSpecFormField.name">
                  <mat-autocomplete autoActiveFirstOption #specAuto="matAutocomplete">
                    <mat-option *ngFor="let option of specsFilter | async" [value]="option">
                      {{option}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>

              <div class="col-lg-5 form-group">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Value</mat-label>
                  <input name="SpecificationValue" [formControl]="SpecificationValueControl" matInput
                    [matAutocomplete]="specValueAuto" placeholder="Enter your Value"
                    [(ngModel)]="itemSpecFormField.value">
                  <mat-autocomplete autoActiveFirstOption #specValueAuto="matAutocomplete">
                    <mat-option *ngFor="let option of valuesFilter | async" [value]="option">
                      {{option}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div class="col-lg-2 form-group">
                <button style="margin-top: 15px;" type="button" (click)="AddSpecification()" mat-raised-button
                  color="primary" mat-button class="save-btn">Add</button>
              </div>
            </div>

            <div [hidden]="itemSpecs.length == 0" class="col-lg-8 form-group">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Value</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let spec of itemSpecs">
                    <td>{{spec.name}}</td>
                    <td>{{spec.value}}</td>
                    <td (click)="DeleteSpecification(spec.name,spec.value)">
                      <mat-icon style="cursor: pointer;" aria-hidden="false" aria-label="Delete icon">delete_outline
                      </mat-icon>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h2>Upload image/Video </h2>
            <div class="row item-row">
              <div class="col-lg-6 form-group upload-controls">
                <mat-form-field>
                  <div>
                    <mat-toolbar>
                      <input matInput [(ngModel)]="fileAttr" readonly name="name" />
                      <button mat-flat-button class="browsebtn" color="primary">
                        Browse File
                      </button>
                    </mat-toolbar>
                    <input type="file" #fileInput id="uploadFile" (change)="uploadFileEvt($event)" name="uploadFile"
                      accept="image/*,video/*" />
                    <mat-error style="margin-top: 10px;" *ngIf="sizeExceeded">Max file size exceeded. Please upload less
                      than 4 MB image.</mat-error>
                  </div>
                </mat-form-field>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>

  </div>
</form>
