import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants, AppURLs } from 'src/app/AppConstants';
import { TagsViewModel } from 'src/app/Models/ComponentModels/TagViewModels';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TagService {
  baseURL = environment.ApiUrl;
  URLs: AppURLs;
  TagServiceURL: string;
  ItemsCheckoutServiceURL: string;
  constructor(private _http: HttpClient)
  {
    this.URLs = new AppURLs(AppConstants.APIVersion);

    this.TagServiceURL = environment.apiBaseUrl + this.URLs.TagsURL;
  }

  public GetAllTags(filters:any):Observable<any>{

    return this._http.post<any>(this.TagServiceURL+'/GetAllTags',filters);
    }

    public GetAllTagsByItemId( ItemId:string):Observable<TagsViewModel[]>{
      return this._http.get<TagsViewModel[]>(this.TagServiceURL+'/GetTagsByItemId/'+ItemId);
      }

      public CreateItemTag( data:any){
        return this._http.post(this.TagServiceURL+'/CreateItemTags',data);
        }


// Shared Function
        CreateTagsWithItems( tags:string, itemId:string){
          var data=new FormData()
          data.append('TagName',tags)
          data.append('ItemId',itemId)

          this.CreateItemTag(data).subscribe(res=>{
            if(data){

            }
          })
          }







}
