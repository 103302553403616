import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UserAccessPermissions } from 'src/app/Models/AccessPermissions';
import { LocationModel } from 'src/app/Models/ComponentModels/LocationModel';
import { LocationService } from 'src/app/Services/component-services/location-service';
import { StorageService } from 'src/app/Services/storage-service.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-locations-list',
  templateUrl: './locations-list.component.html',
  styleUrls: ['./locations-list.component.scss']
})
export class LocationsListComponent implements AfterViewInit {
  isLoading = true;
  locationData:MatTableDataSource<LocationModel> = new MatTableDataSource();
  filterValue = '';
  pageSize = 25;
  currentPage = 1;

  displayedColumns: string[] = ['Code','Name','Address','Actions'];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  userAccessPermission:UserAccessPermissions

  constructor(
             private _locationService:LocationService,
             private _snackBar: MatSnackBar,
             public dialog: MatDialog,
             private _storageService:StorageService,

  ) {
    this.locationData = new MatTableDataSource<LocationModel>();
    this.userAccessPermission=new UserAccessPermissions();

   }

  ngOnInit(): void {
    this.populateLocationsDataGrid();
    this.GetUserAccessPermission();
  }
  ngAfterViewInit() {
    this.locationData.paginator = this.paginator;
  }
  applyFilters() {
    this.populateLocationsDataGrid()
    if (this.locationData.paginator) {
      this.locationData.paginator.firstPage();
    }
  }
  populateLocationsDataGrid()
  {
    this.isLoading = true;
     this._locationService.getLocations(this.currentPage,this.pageSize,this.filterValue).subscribe(response=>{
      this.locationData=  new MatTableDataSource<LocationModel>(response.data);

      setTimeout(() => {
        this.paginator.pageIndex = (this.currentPage - 1); // index starts with zero
        this.paginator.length = response.totalRecords == undefined ? 0 : response.totalRecords;
      });
      this.locationData.paginator = this.paginator;
      this.locationData.sort = this.sort;
      this.isLoading = false;
    },
    (response) => {
      this._snackBar.open(response.error.Message || "Could not load data. Try again.","Ok");
      this.isLoading = false;
    })
    this.locationData.sort = this.sort;
  }
  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex + 1;
    this.populateLocationsDataGrid();
  }
  confirmDialog(locationObj: LocationModel): void {
    const title = `Delete Location`;
    const message = `Are you sure you want to delete this location?`;

     const dialogData = new ConfirmDialogModel(title, message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "550px",
      data: dialogData,
      panelClass: 'custom-dialog'
    });

    dialogRef.afterClosed().subscribe(dialogResult => {

      if (dialogResult) { // user clicked yes
        this._locationService.deleteLocation(locationObj.id).subscribe((x) => {
          this._snackBar.open('Deleted successfully.', 'Ok');
          this.populateLocationsDataGrid();
        },(errorResponse)=>{
          this._snackBar.open('Location cannot be deleted as an Item is assigned to it', 'Ok');
        });
      }
     });
  }
  GetUserAccessPermission()
     {
      this.userAccessPermission=this._storageService.GetLoginUserPermissions()
     }
}
