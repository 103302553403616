<div class="main-app">
  <div class="general-block">
    <div class="logincontainer">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 loginform">
        <div class="sample-form-block">
          <div id="sample-form-auto" class="sample-form-auto">
            <i class="logo-box"></i>
            <h2>DPL Asset Management</h2>
            <span class="bottomline">Log In with your AD credentials </span>
            <form id="signin" #loginForm (submit)="onSubmit(loginForm)" class="">

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Email Address</mat-label>
                <input matInput [(ngModel)]="username" name="username" placeholder="Enter your username" value="">
              </mat-form-field>

              <mat-form-field class="password-field full-width" appearance="outline">
                <mat-label>Password</mat-label>
                <input matInput [type]="fieldTextType ? 'text' : 'password'" [(ngModel)]="password" name="password" placeholder="Enter your Password" type="password" value="">
                <span class="passwordinput-group-text">
                  <i
                    class="password-icon"
                    [ngClass]="{
                      'password-hide': !fieldTextType,
                      'password-show': fieldTextType
                    }"
                    (click)="toggleFieldTextType()"
                  ></i>
                  </span>
              </mat-form-field>
                <button mat-raised-button color="primary" [class.spinner]="loading" [disabled]="loading" class="full-width login-button">Log In</button>

            </form>
            <footer class="login-footer">© 2022 DPL. All Rights Reserved</footer>
          </div>
        </div>
      </div>
      <div
        class="col-xs-12 col-sm-12 col-md-6 col-lg-6 loginbanner sample-content-block"
      ></div>
    </div>
  </div>
</div>
