import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as saveAs from 'file-saver';
import { BeforeSlideDetail } from 'lightgallery/lg-events';
import { LightGallery } from 'lightgallery/lightgallery';
import lgZoom from 'lightgallery/plugins/zoom';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ActiveCheckOutsReportModel } from 'src/app/Models/ComponentModels/ActiveCheckoutsReportsModel';
import { CheckinCheckoutReport } from 'src/app/Models/ComponentModels/Checkin-Checkout-Report';
import { UserModel } from 'src/app/Models/ComponentModels/UserModel';
import { CommonTasksService } from 'src/app/Services/common-tasks.service';
import { ItemService } from 'src/app/Services/component-services/item.service';
import { ReportService } from 'src/app/Services/component-services/report-service';
import { UserService } from 'src/app/Services/component-services/user-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-active-checkouts',
  templateUrl: './active-checkouts.component.html',
  styleUrls: ['./active-checkouts.component.scss']
})
export class ActiveCheckoutsComponent implements OnInit {

  baseURL = environment.ApiUrl;
  isLoading = false;
  filterValue = '';
  sortColumn = '';
  sortDirection = '';
  typeFilter = '';
  pageSize = 25;
  currentPage = 1;

  selectedUser:UserModel = new UserModel();
  selectedStartDate:string = '';
  selectedEndDate:string = '';
  users: UserModel[] = new Array<UserModel>();
  usersFilter: Observable<UserModel[]>;
  userSearchControl = new FormControl();
  typeFilterControl = new FormControl();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['userFullName','qtyIssued','lastCheckOutDate','lastCheckOutByUserFullName'];
  reportData:MatTableDataSource<ActiveCheckOutsReportModel> = new MatTableDataSource();
  recordCount: any;
  searchDone:boolean=false;

  constructor(
    private _reportsService:ReportService,
    private _userService:UserService,
    private _commonTasksService: CommonTasksService,
    private _snackBar: MatSnackBar,
    private _router: Router,
    ) {
    this.reportData = new MatTableDataSource<ActiveCheckOutsReportModel>();
   }
   ngOnInit(){
    this._userService.getallEmployees().subscribe((x) => {
      var data: any = x;

      this.users = <UserModel[]>data.result.data;
      this.users.map(function (x) {
        x.name = x.firstName?.trim() + " " + x.lastName?.trim();
      });
      this.usersFilter = this.userSearchControl.valueChanges.pipe(
        startWith(''),
        map(value => this.responsible_user_filter(value)
      ));
    });

    this.populateReportDataGrid();
  }
   ngAfterViewInit() {
    this.reportData.sort = this.sort;
    this.reportData.paginator = this.paginator;
  }

  applyFilters() {
    this.populateReportDataGrid();
    if (this.reportData.paginator) {
      this.reportData.paginator.firstPage();
    }
  }
  SortChange(sortState: Sort){
    this.sortColumn = sortState.active;
    this.sortDirection = sortState.direction;

    // this.populateReportDataGrid();
  }
  private responsible_user_filter(value: string): UserModel[] {
    const filterValue = value.toLowerCase();
    if (this.users) {
      return this.users.filter((option) => option?.firstName?.toLowerCase().includes(filterValue)
        || option?.lastName?.toLowerCase().includes(filterValue) || (option?.name?.toLowerCase().includes(filterValue)));
    } else {
      return [];
    }
  }
  displayFn(options: UserModel[]): (id: string) => string {
    return (id: string) => {
      const correspondingOption = Array.isArray(this.users) ? this.users.find(option => option.id === id) : null;
      return correspondingOption ? correspondingOption.firstName + ' '  + correspondingOption.lastName || '': '';
    }
  }
  populateReportDataGrid(){

    this.isLoading = true;

    this._reportsService.getActiveCheckOutsReport(this.currentPage, this.pageSize,this.sortColumn,
      this.sortDirection, this.selectedUser.id, this._commonTasksService.getMySQLformatDate(this.selectedStartDate),
      this._commonTasksService.getMySQLformatDate(this.selectedEndDate)).subscribe((x) => {
      x.data?.forEach(function(item){
        item.userFullName = item?.user?.firstName + " " + item?.user?.lastName;
        item.lastCheckOutByUserFullName = item?.lastCheckOutBy?.firstName + " " + item?.lastCheckOutBy?.lastName;
      });
      this.reportData = new MatTableDataSource<ActiveCheckOutsReportModel>(x.data);

      this.recordCount= x.data?.length
      this.searchDone=true;
      setTimeout(() => {
        this.paginator.pageIndex = (this.currentPage - 1); // index starts with zero
        this.paginator.length = x.totalRecords == undefined ? 0 : x.totalRecords;
      });

      this.reportData.paginator = this.paginator;
      this.reportData.sort = this.sort;
      this.isLoading = false;
    },
      (response) => {
        this._snackBar.open(response.error.Message || "Could not load data. Try again.","Ok");
        this.isLoading = false;
      });
  }
  pageChanged(event: PageEvent) {
    // this.pageSize = event.pageSize;
    // this.currentPage = event.pageIndex + 1;
    //this.populateReportDataGrid();
  }

  downloadExcel(){

    this._reportsService.downloadActiveCheckOutsReport(this.currentPage, this.pageSize,this.sortColumn,
      this.sortDirection, this.selectedUser.id, this._commonTasksService.getMySQLformatDate(this.selectedStartDate),
      this._commonTasksService.getMySQLformatDate(this.selectedEndDate)).subscribe(blob => {
      saveAs(blob, 'ActiveCheckOutsReportModel.xlsx');
    });
  }
  RedirectToInOutReport(item:any)
  {
    this._router.navigateByUrl('home/user-report',{state:item});

  }
}

