import { Component } from '@angular/core';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(private breadcrumbService: BreadcrumbService) {

    const isHome = true;
    const breadcrumb = true;
    //breadcrumbService.set('home', 'Home View'); // path for HomeComponent
    breadcrumbService.set('home/users', 'Users List');
    breadcrumbService.set('home/user/add', 'Add User');
    breadcrumbService.set('home/user/add/:id', 'Edit User');
    breadcrumbService.set('home/item/add', 'Add Item');
    breadcrumbService.set('home/locations', 'Locations List');
    breadcrumbService.set('home/location/add', 'Add Location');
    breadcrumbService.set('home/location/edit/:id', 'Edit Location');
    breadcrumbService.set('home/item/edit/:id', 'Edit Item');
    breadcrumbService.set('home/items', 'Items List');
    breadcrumbService.set('home/item/import', 'Import Items');
    breadcrumbService.set('home/user-report', 'Activity Report');
    breadcrumbService.set('home/active-checkouts-report', 'Active Check-outs Report');
    breadcrumbService.set('home/category', 'Category');
    breadcrumbService.set('home/access-groups', 'Access Groups');
    breadcrumbService.set('home/add-access-groups', 'Add Access Group');
    breadcrumbService.set('home/add-alert', 'Summary Email');
    breadcrumbService.set('home/notEnoughRights', 'Not enough permissions');

    breadcrumbService.set('home', {
      routeInterceptor: (routeLink, breadcrumb) =>
      isHome ? '/home/items' : routeLink,
    });
  }
  title = 'DPL-Assets Management';
}
