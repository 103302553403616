<mat-progress-bar [ngStyle]="{'visibility':isLoading==true ? 'visible' : 'hidden'}" color="primary" mode="indeterminate"></mat-progress-bar>

<div class="col-lg-12 components-block">
  <div class="components-header">
    <h2>Active Check-outs Report</h2>
  </div>
  <div class="filterbar-block" style="margin-left: 10px;">

    <div class="grid-filter-dropdown">
      <mat-form-field appearance="standard">
        <mat-label>User</mat-label>
        <input matInput placeholder="Search" name="responsibleUser" [(ngModel)]="selectedUser.id" aria-label="State"
          [matAutocomplete]="autoResponsibleUser" [formControl]="userSearchControl">
        <mat-autocomplete #autoResponsibleUser="matAutocomplete" style="width:300px" [displayWith]="displayFn(users)">
          <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
          <ng-container *ngIf="!isLoading">
            <mat-option *ngFor="let user of usersFilter | async" [value]="user.id">
              <span>{{user.firstName}} {{user.lastName}} <b *ngIf="user.employeeId">({{user.employeeId}})</b></span>
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div style="margin-left: 15px;" class="grid-filter-dropdown">
      <mat-form-field appearance="standard">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input format='dd/MM/yyyy'  [(ngModel)]="selectedStartDate" matStartDate placeholder="Start date">
          <input format='dd/MM/yyyy'  [(ngModel)]="selectedEndDate" matEndDate placeholder="End date">
        </mat-date-range-input>
        <!-- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>

    </div>
    <div class="pulladdata-block">
      <button type="submit" (click)="applyFilters()"
        mat-raised-button color="primary" mat-button class="pulldata-btn">Apply Filters</button>
    </div>
    <div class="pulladdata-block">
      <button type="button" (click)="downloadExcel()"
      mat-stroked-button color="primary"  class="pulldata-btn download-btn">Download Excel</button>
    </div>
  </div>
  <div class="mat-table-block">

    <table mat-table matSort (matSortChange)="SortChange($event)" [dataSource]="reportData">

      <ng-container matColumnDef="userFullName">
        <th mat-header-cell  *matHeaderCellDef style="width:10%;" mat-sort-header sortActionDescription="userFullName"> User </th>
        <td mat-cell *matCellDef="let item" style="width:10%;"> {{item.userFullName}}</td>
      </ng-container>

      <ng-container matColumnDef="qtyIssued">
        <th  mat-header-cell  *matHeaderCellDef style="width:10%; text-align: left;" mat-sort-header sortActionDescription="qtyIssued">
          <span>Total Checked-out</span>
        </th>
        <!-- <td  mat-cell *matCellDef="let item" style="width:10%; text-align: left;"> {{item.qtyIssued}} </td> -->
        <td  mat-cell *matCellDef="let item" style="width:10%; text-align: left;"> <a class="column-name-link" type="button"  style="cursor: pointer;color: #00a3ff;" (click)="RedirectToInOutReport(item)"> {{item.qtyIssued}}</a> </td>
      </ng-container>


      <ng-container matColumnDef="lastCheckOutDate">
        <th mat-header-cell  *matHeaderCellDef mat-sort-header sortActionDescription="lastCheckOutDate"  style="width:10%;">Check-out Date </th>
        <td mat-cell *matCellDef="let item" style="width:10%;">
            {{item.lastCheckOutDate | date:'dd/MM/YYY'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="lastCheckOutByUserFullName">
        <th mat-header-cell  *matHeaderCellDef mat-sort-header sortActionDescription="lastCheckOutByUserFullName" style="width:12%;">Last Check-out By </th>
        <td mat-cell *matCellDef="let item" style="width:12%;">
             {{item.lastCheckOutByUserFullName}}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div class="record-count" *ngIf="!recordCount && searchDone">No Record Found</div>

    <mat-paginator *ngIf="recordCount"  [pageSizeOptions]="[25, 50, 100]" #paginator [pageIndex]="currentPage" [pageSize]="pageSize"
      (page)="pageChanged($event)" showFirstLastButtons aria-label="Select page of items">
    </mat-paginator>
  </div>
</div>
<div class="record-count" > {{recordCount}}</div>

