<form name="AccessGroupForm" #AccessGroupForm="ngForm" (submit)="saveAccessGroup(AccessGroupForm)" novalidate>
  <div class="col-lg-11 components-block">

    <div class="components-header">
      <h2>Add Access Group</h2>
      <button type="submit" [disabled]="isLoading" mat-raised-button color="primary" mat-button class="save-btn">{{isEditForm ? "Update" : "Save"}}</button>
    </div>
    <div class="components-form">
<!-- Access Group-->
<div class="ag-group-main access-group-name">
  <div class="row item-row">
    <div class="col-lg-6 form-group ">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Access Group Name</mat-label>
        <input required name="AccessGroupName" #AccessGroupName="ngModel" matInput placeholder="Enter your Location Name"
          [(ngModel)]="name">
        <mat-error *ngIf="AccessGroupName.hasError('required')">
          Access Group Name is <strong>required</strong>
          </mat-error>
      </mat-form-field>
    </div>
    </div>

  </div>
      <div class="components-vertical-tab">
       <!--!--------------------** Left Sidebar **------------------ -->
        <div class="components-vertical-link">
          <ul>
            <li><a [ngClass]="isCatActive ? 'active' : ''" (click)="displayMainSection(0)">Data Access</a></li>
            <li><a  [ngClass]="isItemActive ? 'active' : ''" (click)="displayMainSection(1)">Items<mat-icon>keyboard_arrow_right</mat-icon></a>
              <ul class="vertical-sub-link" *ngIf="isItem">
                <li><a (click)="displaySubSections(1)" >Item Management</a> </li>
                <li><a (click)="displaySubSections(2)" >Item Movement </a></li>
              </ul>
            </li>
            <li><a [ngClass]="isReportActive ? 'active' : ''" (click)="displayMainSection(2)">Reports<mat-icon>keyboard_arrow_right</mat-icon></a>
              <ul class="vertical-sub-link" *ngIf="isReport">
                <li><a (click)="displaySubSections(3)">Activity Report</a></li>
                <li><a (click)="displaySubSections(4)">Active Check-outs Report</a></li>
              </ul>
            </li>
            <li><a [ngClass]="isMaintenanceActive ? 'active' : ''" (click)="displayMainSection(3)">Maintenance<mat-icon>keyboard_arrow_right</mat-icon></a>
              <ul class="vertical-sub-link" *ngIf="isMaintenance">
                <li><a (click)="displaySubSections(5)">Locations</a></li>
                <li><a (click)="displaySubSections(6)">Categories</a></li>
                <li><a (click)="displaySubSections(7)">Summary Emails</a></li>
                <li><a (click)="displaySubSections(8)">Access Groups</a></li>

              </ul>
            </li>
            <li><a [ngClass]="isUsersActive ? 'active' : ''" (click)="displayMainSection(4)">User Management</a></li>

          </ul>
        </div>


        <!--!--------------------** Left Side Menue Container **------------------ -->
        <div class="components-vertical-content">

          <div class="item-information-block">

  <!--Access Category  -->
  <div class="ag-group-main" *ngIf="isCategory">
    <h2>Data Access (Categories)</h2>
    <div class="row item-row">
      <div class="col-lg-6 form-group">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Category</mat-label>
          <mat-chip-list #chipList aria-label="Category selection">
            <mat-chip
              *ngFor="let category of selectedCategories"
              (removed)="remove(category)">
              {{category.name}}
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
            <input
              placeholder="Select categories..."
              #categoriesInput
              [formControl]="categoriesControl"
              [matAutocomplete]="autocategories"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              >
          </mat-chip-list>
          <mat-autocomplete #autocategories="matAutocomplete" [displayWith]="displayFn(categories)">
            <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
            <ng-container *ngIf="!isLoading">
              <mat-option *ngFor="let category of categoriesFilter | async" [value]="category.id">
                <span>{{category.name}} </span>
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      </div>

    </div>
             <!-- Items -->
            <div class="ag-group-main" *ngIf="isItemManagement">
              <div class="ag-group-head">
                <h2>Item Management</h2>
                <section class="select-all-checkboxs">
               <mat-checkbox class="checked-input select-all-text" name="createItem"
                  (change)="ToogleAllItemsManagementSelection($event)">Select All</mat-checkbox>
                </section>
              </div>

              <section class="example-section">
                <mat-checkbox class="checked-input" name="createItem" [(ngModel)]="userAccessPermissions.createItem">Create</mat-checkbox>
                <mat-checkbox class="checked-input" name="updateItem" [(ngModel)]="userAccessPermissions.updateItem">Edit</mat-checkbox>
                <mat-checkbox class="checked-input" name="readItem" [(ngModel)]="userAccessPermissions.readItem">View</mat-checkbox>
                <mat-checkbox class="checked-input" name="delteItem" [(ngModel)]="userAccessPermissions.delteItem">Delete</mat-checkbox>
                <mat-checkbox class="checked-input" name="cloneItem" [(ngModel)]="userAccessPermissions.cloneItem">Clone</mat-checkbox>
                <mat-checkbox class="checked-input" name="itemImport" [(ngModel)]="userAccessPermissions.itemImport">Import Excel</mat-checkbox>
              </section>
            </div>

            <div class="ag-group-main" *ngIf="isItemMovement">
              <h2>Item Movement</h2>
              <section class="select-all-checkboxs">
                <mat-checkbox class="checked-input select-all-text" name="createItem"
                (change)="ToogleAllItemsMovementSelection($event)">Select All</mat-checkbox>
              </section>
              <section class="example-section">
                <mat-checkbox class="checked-input" name="checkInItem"  [(ngModel)]="userAccessPermissions.checkInItem">Check-In</mat-checkbox>
                <mat-checkbox class="checked-input" name="checkOutItem" [(ngModel)]="userAccessPermissions.checkOutItem">Check-Out</mat-checkbox>

              </section>
            </div>

            <!--Reports -->

            <div class="ag-group-main" *ngIf="isActivityReport">
              <h2>Activity Report</h2>

              <section class="example-section">

                <mat-checkbox class="checked-input" name="activityReportRead" [(ngModel)]="userAccessPermissions.activityReportRead">View</mat-checkbox>

              </section>
            </div>

            <div class="ag-group-main" *ngIf="isActiveCheckoutReport">
              <h2>Active Check-outs Report</h2>
              <section class="example-section">
                <mat-checkbox class="checked-input" name="activeCheckoutReportRead" [(ngModel)]="userAccessPermissions.activeCheckoutReportRead">View</mat-checkbox>
              </section>
            </div>

            <!-- Maintenance -->
            <div class="ag-group-main" *ngIf="isLocations">

              <h2>Locations</h2>
              <section class="select-all-checkboxs">
                <mat-checkbox class="checked-input select-all-text" name="createItem"
                (change)="ToogleAllLocationSelection($event)" >Select All</mat-checkbox>
              </section>
              <section class="example-section">
                <mat-checkbox class="checked-input" name="createLocation" [(ngModel)]="userAccessPermissions.createLocation">Create</mat-checkbox>
                <mat-checkbox class="checked-input" name="updateLocation" [(ngModel)]="userAccessPermissions.updateLocation">Edit</mat-checkbox>
                <mat-checkbox class="checked-input" name="readLocation"   [(ngModel)]="userAccessPermissions.readLocation">View</mat-checkbox>
                <mat-checkbox class="checked-input" name="deleteLocation" [(ngModel)]="userAccessPermissions.deleteLocation">Delete</mat-checkbox>
              </section>
            </div>

            <div class="ag-group-main" *ngIf="isCategories">
              <h2>Categories</h2>
              <section class="select-all-checkboxs" >
                <mat-checkbox class="checked-input select-all-text" name="createItem"
                (change)="ToogleAllCategoriesSelection($event)">Select All</mat-checkbox>
              </section>
              <section class="example-section">
                <mat-checkbox class="checked-input" name="crateCategorySubCategory"  [(ngModel)]="userAccessPermissions.crateCategorySubCategory">Create</mat-checkbox>
                <mat-checkbox class="checked-input" name="updateCategorySubCategory" [(ngModel)]="userAccessPermissions.updateCategorySubCategory">Edit</mat-checkbox>
                <mat-checkbox class="checked-input" name="readCategorySubCategory"   [(ngModel)]="userAccessPermissions.readCategorySubCategory">View</mat-checkbox>
                <mat-checkbox class="checked-input" name="deleteCategorySubCategory" [(ngModel)]="userAccessPermissions.deleteCategorySubCategory">Delete</mat-checkbox>
              </section>
            </div>

            <div class="ag-group-main" *ngIf="isSummeryEmails">
              <h2>Summary Emails</h2>
              <section class="select-all-checkboxs">
                <mat-checkbox class="checked-input select-all-text" name="createItem"
                (change)="ToogleAllSummeryEmailSelection($event)">Select All</mat-checkbox>
              </section>
              <section class="example-section">
                <mat-checkbox class="checked-input" name="createSummeryEmail" [(ngModel)]="userAccessPermissions.createSummeryEmail">Create</mat-checkbox>
                <mat-checkbox class="checked-input" name="updateSummeryEmail" [(ngModel)]="userAccessPermissions.updateSummeryEmail">Edit</mat-checkbox>
                <mat-checkbox class="checked-input" name="readSummeryEmail" [(ngModel)]="userAccessPermissions.readSummeryEmail">View</mat-checkbox>
                <mat-checkbox class="checked-input" name="deleteSummeryEmail" [(ngModel)]="userAccessPermissions.deleteSummeryEmail">Delete</mat-checkbox>
              </section>
            </div>

            <div class="ag-group-main" *ngIf="isAccessGroup">
              <h2>Access Groups</h2>
              <section class="select-all-checkboxs">
                <mat-checkbox class="checked-input select-all-text" name="createItem"
               (change)="ToogleAllAccessGroupSelection($event)" >Select All</mat-checkbox>
              </section>
              <section class="example-section">
                <mat-checkbox class="checked-input" name="createAccessGroup" [(ngModel)]="userAccessPermissions.createAccessGroup">Create</mat-checkbox>
                <mat-checkbox class="checked-input" name="updateAccessGroup" [(ngModel)]="userAccessPermissions.updateAccessGroup">Edit</mat-checkbox>
                <mat-checkbox class="checked-input" name="readAccessGroup"   [(ngModel)]="userAccessPermissions.readAccessGroup">View</mat-checkbox>
                <mat-checkbox class="checked-input" name="deleteAccessGroup" [(ngModel)]="userAccessPermissions.deleteAccessGroup">Delete</mat-checkbox>
              </section>
            </div>

            <!-- Users -->
            <div class="ag-group-main" *ngIf="isUsers">
              <h2>User Management</h2>
              <section class="select-all-checkboxs">
                <mat-checkbox class="checked-input select-all-text" name="createItem"
                (change)="ToogleAllUserManagementSelection($event)">Select All</mat-checkbox>
              </section>
              <section class="example-section">
                <mat-checkbox class="checked-input" name="createUser" [(ngModel)]="userAccessPermissions.createUser">Create</mat-checkbox>
                <mat-checkbox class="checked-input" name="updateUser" [(ngModel)]="userAccessPermissions.updateUser">Edit</mat-checkbox>
                <mat-checkbox class="checked-input" name="readUser" [(ngModel)]="userAccessPermissions.readUser">View</mat-checkbox>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
