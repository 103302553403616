import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import * as saveAs from 'file-saver';
import { BeforeSlideDetail } from 'lightgallery/lg-events';
import { LightGallery } from 'lightgallery/lightgallery';
import lgZoom from 'lightgallery/plugins/zoom';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CategoryModel } from 'src/app/Models/ComponentModels/Category';
import { CheckinCheckoutReport } from 'src/app/Models/ComponentModels/Checkin-Checkout-Report';
import { SubCategoryModel } from 'src/app/Models/ComponentModels/SubCategory';
import { UserModel } from 'src/app/Models/ComponentModels/UserModel';
import { CategoryService } from 'src/app/Services/component-services/category-Service';
import { ItemService } from 'src/app/Services/component-services/item.service';
import { ReportService } from 'src/app/Services/component-services/report-service';
import { SubCategoryService } from 'src/app/Services/component-services/subcategory-service';
import { UserService } from 'src/app/Services/component-services/user-service';
import { environment } from 'src/environments/environment';

import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { CommonTasksService } from 'src/app/Services/common-tasks.service';

@Component({
  selector: 'app-checkin-checkout-report',
  templateUrl: './checkin-checkout-report.component.html',
  styleUrls: ['./checkin-checkout-report.component.scss'],
})
export class CheckinCheckoutReportComponent implements OnInit {
  baseURL = environment.ApiUrl;
  isLoading = false;
  filterValue = '';
  filterValues = '';
  sortColumn = '';
  sortDirection = '';
  typeFilter = 'All';
  chekoutType = '';
  type = 'All';

  pageSize = 25;
  currentPage = 1;
  categoryFilter: string = '';
  subCategoryFilter: string = '';

  selectedUser: UserModel = new UserModel();
  selectedStartDate: string = '';
  selectedEndDate: string = '';
  // users: UserModel[] = new Array<UserModel>();
  Users: UserModel[];

  public isSidebarRight: boolean = false;
  usersFilter: Observable<UserModel[]>;
  userSearchControl = new FormControl();
  typeFilterControl = new FormControl();
  Categories: CategoryModel[] = [];
  SubCategories: SubCategoryModel[] = [];
  recordCount: any;
  searchStart: boolean = false;
  selectedUserbyInOutReport: any;

  searcheduser: UserModel = new UserModel();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  filtersCount:number=0
  displayedColumns: string[] = [
    'IssuedByUserFirstName',
    'UserFirstName',
    'ActionName',
    'ItemName',
    'ItemCategory',
    'ItemSubCategory',
    'ActionDate',
    'QtyChanged',
    'IssuedLocation',
  ];
  reportData: MatTableDataSource<CheckinCheckoutReport> =
    new MatTableDataSource();
  // Users: string[]

  isCheckout: boolean = false;
  isCheckin: boolean = false;
  allCheckinCheckout: boolean = false;

  profileForm = this._formBuilder.group({
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    employeeId: new FormControl(''),
    Id: new FormControl(''),
  });
  searchUserControl = new FormControl('');

  constructor(
    private _itemService: ItemService,
    private _reportService: ReportService,
    private _userService: UserService,
    private changeDetectorRefs: ChangeDetectorRef,
    private _snackBar: MatSnackBar,
    private _router: Router,
    private _categoryService: CategoryService,
    private _subcategoryService: SubCategoryService,
    private _formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private _commonTasksService:CommonTasksService
  ) {
    this.reportData = new MatTableDataSource<CheckinCheckoutReport>();
  }
  ngOnInit() {
      this._route.queryParams.subscribe((params) => {
        this.selectedStartDate = params['startdate'];
        this.selectedEndDate = params['enddate'];
        this.typeFilter = params['type']; // either type = checkin or checkout

        if(this.typeFilter && this.typeFilter == 'checkout'){
          this.typeFilter ='CheckOut';

        }
        else if(this.typeFilter == 'checkin'){
          this.typeFilter ='CheckIn';
        }
        else{
          this.typeFilter ='All';

        }
      });
    var getInActiveUsers: boolean = true;
    this._userService.getallEmployees(getInActiveUsers).subscribe((x) => {
      var data: any = x;
      this.Users = JSON.parse(JSON.stringify(<UserModel>data.result.data));
    });

    this.populateCategories();

    if (history.state.userId) {
      this.selectedUser = history.state.user;
      this.typeFilter = history.state.actionName;
      this.type = 'Active';
    }
    this.populateReportDataGrid();

    if (history.state.userId) {
      this._userService.getUserById(history.state.userId).subscribe(
        (x) => {
          this.searcheduser = <UserModel>x.data || new UserModel();
          this.profileForm.patchValue({
            selectedRoles: this.searcheduser.roles.map((x) => x.id),
          });
        },
        (errResponse) => {
          this._snackBar.open(
            errResponse.error.Message || 'Something went wrong.'
          );
        }
      );
    }

    this.OnChangeFilterValues()
  }
  ngAfterViewInit() {
    this.reportData.sort = this.sort;
    this.reportData.paginator = this.paginator;
  }

  private lightGallery!: LightGallery;
  private needRefresh = false;

  ngAfterViewChecked(): void {
    if (this.needRefresh) {
      this.lightGallery.refresh();
      this.needRefresh = false;
    }
  }
  settings = {
    counter: false,
    plugins: [lgZoom],
  };
  clearDateRange() {
    this.selectedStartDate = '';
    this.selectedEndDate = '';
    this.OnChangeFilterValues()
  }
  onBeforeSlide = (detail: BeforeSlideDetail): void => {
    const { index, prevIndex } = detail;
  };
  applyFilters() {
    this.populateReportDataGrid();
    if (this.reportData.paginator) {
      this.reportData.paginator.firstPage();
    }
    this.isSidebarRight = false;
  }

  SortChange(sortState: Sort) {
    this.sortColumn = sortState.active;
    this.sortDirection = sortState.direction;

    this.populateReportDataGrid();
  }

  populateReportDataGrid() {

    this.isLoading = true;
    this.allCheckinCheckout = true;
    //!Active and InActiveChecuts
    //! 1.  Active + checkout
    if (this.typeFilter && this.typeFilter.toLowerCase() == 'checkout' && this.type == 'Active') {
      this.chekoutType = 'active-checkout';
      this.isCheckout = true;
      this.isCheckin = false;
      this.allCheckinCheckout = false;
    }
    //! 2. Inactive + Checkout
    if (this.typeFilter && this.typeFilter.toLowerCase() == 'checkout' && this.type == 'InActive') {
      this.chekoutType = 'inactive-checkout';
      this.isCheckout = true;
      this.isCheckin = false;
      this.allCheckinCheckout = false;
    }
    //! 4.  Inactive + CheckIn
    if (this.typeFilter && this.typeFilter.toLowerCase() == 'checkin' && this.type == 'InActive') {
      this.chekoutType = 'inactive-checkin';
      this.isCheckout = false;
      this.isCheckin = true;
      this.allCheckinCheckout = false;
    }
    if (this.typeFilter && this.typeFilter.toLowerCase() == 'checkin' && this.type == 'Active') {
      this.isCheckin = true;
      this.allCheckinCheckout = false;
      this.isCheckout = false;
    }
    if (this.typeFilter && this.typeFilter.toLowerCase() == 'instock'  && this.type == 'Active')
     {
        this.chekoutType = 'activestatus';
    }

    if (this.typeFilter && this.typeFilter.toLowerCase() == 'loststolen'  && this.type == 'Active')
    {
       this.chekoutType = 'activestatus';
   }
   if (this.typeFilter && this.typeFilter.toLowerCase() == 'outforrepair'  && this.type == 'Active')
   {
      this.chekoutType = 'activestatus';
  }
  if (this.typeFilter && this.typeFilter.toLowerCase() == 'brokennotfixable'  && this.type == 'Active')
  {
     this.chekoutType = 'activestatus';
  }

  //------InActive
  if (this.typeFilter && this.typeFilter.toLowerCase() == 'instock'  && this.type == 'InActive')
     {
        this.chekoutType = 'inactivestatus';
    }

    if (this.typeFilter && this.typeFilter.toLowerCase() == 'loststolen'  && this.type == 'InActive')
    {
       this.chekoutType = 'inactivestatus';
   }
   if (this.typeFilter && this.typeFilter.toLowerCase() == 'outforrepair'  && this.type == 'InActive')
   {
      this.chekoutType = 'inactivestatus';
  }
  if (this.typeFilter && this.typeFilter.toLowerCase() == 'brokennotfixable'  && this.type == 'InActive')
  {
     this.chekoutType = 'inactivestatus';
  }



    if (this.allCheckinCheckout) {
      this.isCheckout = false;
      this.isCheckin = false;
    }

    var formatedStartDate = '';
    var formatedEndDate = '';

    if (this.selectedStartDate && this.selectedEndDate) {
      formatedStartDate = this._commonTasksService.getMySQLformatDate(this.selectedStartDate);
      formatedEndDate = this._commonTasksService.getMySQLformatDate(this.selectedEndDate);
    }

    this._reportService
      .getItemCheckinCheckoutReport(
        this.currentPage,
        this.pageSize,
        this.sortColumn,
        this.sortDirection,
        this.selectedUser.id,
        this.typeFilter,
        formatedStartDate,
        formatedEndDate,
        this.categoryFilter,
        this.subCategoryFilter,
        this.filterValues,
        this.chekoutType
      )
      .subscribe(
        (x) => {
          x.data?.map((item) => {
            if (item.itemImage) {
              item.itemImage = this.baseURL + '/' + item.itemImage;
            }

            item.images = this.populateImagePathsFromCommaSeparatedString(
              item.imagePath
            );
          });
          this.reportData = new MatTableDataSource<CheckinCheckoutReport>(
            x.data
          );
          this.recordCount = x.data?.length;
          this.searchStart = true;

          setTimeout(() => {
            if(this.paginator){
              this.paginator.pageIndex = this.currentPage - 1; // index starts with zero
              this.paginator.length =
                x.totalRecords == undefined ? 0 : x.totalRecords;
            }
          });

          this.reportData.paginator = this.paginator;
          this.reportData.sort = this.sort;
          this.isLoading = false;
        },
        (response) => {
          this._snackBar.open(
            response.error.Message || 'Could not load data. Try again.',
            'Ok'
          );
          this.isLoading = false;
        }
      );

    this.chekoutType = '';
  }
  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex + 1;
    this.populateReportDataGrid();
  }
  private populateImagePathsFromCommaSeparatedString(
    commaSeparatedString: string
  ) {
    var imagePaths = new Array<string>();
    if (!commaSeparatedString) {
      return new Array();
    }
    var elements = commaSeparatedString.split(',');

    if (elements && elements.length > 0) {
      elements.forEach((item) => {
        var imagePath = this.baseURL + '/' + item;
        imagePaths.push(imagePath);
      });
    }
    return imagePaths;
  }
  downloadExcel() {
    var formatedStartDate = '';
    var formatedEndDate = '';

    if (this.selectedStartDate && this.selectedEndDate) {
      formatedStartDate = new Date(this.selectedStartDate).toLocaleString();
      formatedEndDate = new Date(this.selectedEndDate).toLocaleString();
    }
    this._reportService
      .downloadCheckinCheckOutReport(
        -1,
        -1,
        this.sortColumn,
        this.sortDirection,
        this.selectedUser.id,
        this.typeFilter,
        formatedStartDate,
        formatedEndDate,
        this.categoryFilter,
        this.subCategoryFilter,
        this.filterValues
      )
      .subscribe((blob) => {
        saveAs(blob, 'CheckinCheckOutReport.xlsx');
      });
  }

  populateCategories() {
    this._categoryService.getAllCategories().subscribe((x) => {
      this.Categories = x.data;
    });
  }
  private populateSubCategories(CategoryId: Number) {
    if (CategoryId) {
      this._subcategoryService.getAllSubCategories(CategoryId).subscribe(
        (x) => {
          this.SubCategories = x.data;
        },
        (response) => {
          this._snackBar.open(response.error.Message);
        }
      );
    } else {
      this.subCategoryFilter = '';
      this.SubCategories = [];
    }
  }
  updateSubCategories(event: any) {
    var selectedCategoryId = event.value;
    this.subCategoryFilter = '';

    if (selectedCategoryId == '') {
      this.subCategoryFilter = selectedCategoryId;
    }
    this.populateSubCategories(selectedCategoryId);
  }

  SearchUsers(event: any) {
    let name = event.target.value;
    var searchString = event.target.value.replace(/ /g, '');
    // if (searchString.length < 3) {
    //   return;
    // }
    if (searchString.length < 1) {
      var getInActiveUsers: boolean = true;
      this._userService.getallEmployees(getInActiveUsers).subscribe((x) => {
        var data: any = x;
        this.Users = JSON.parse(JSON.stringify(<UserModel>data.result.data));
        this.searchUserControl.setValue(this.searcheduser);
      });
      this.applyFilters()
    }

    this._userService.searchallUsers(searchString).subscribe(
      (response) => {
        if (response.data.length > 0) {
          this.Users = response.data;
        }
      },
      (errResponse) => {
        this._snackBar.open(errResponse.error.Message);
      }
    );
  }

  displayFn(options: UserModel[]): (id: string) => string {

    return (id: string) => {
      const correspondingOption = Array.isArray(options)
        ? options.find((option) => option.id === id)
        : null;

      if (correspondingOption) {
        return (
          correspondingOption.firstName +
          ' ' +
          (correspondingOption.lastName || '')
        );
      }
      return '';
    };
  }
  selectUser(selectedId: any, users: UserModel[]) {
    var user = users.find((x) => x.id == selectedId);
    if (user) {
      this.searcheduser = user;
      this.profileForm.enable();

      this.profileForm.patchValue({
        selectedRoles: user.roles?.map((x) => x.id),
      }); // To trigger change
    }
    this.applyFilters()
    // Enables/Disables Roles Assignment Dropdown on the basis of login users Roles
  }
  openFiltersSidePanel() {
    this.isSidebarRight = true;
  }
  close(){
    // close side bar and show grid
    this.isSidebarRight = false;
    document.body.style.overflow = 'visible';
  }

  clearall(){
    // Action Type
    this.typeFilter='All'
    // Active/InActive
    this.type='All'
   this.selectedStartDate='';
   this.selectedEndDate=''
   this.categoryFilter='';
   this.subCategoryFilter = '';
   this.selectedUser.id='';
   this.filterValues=''
   this.applyFilters()
   this.filtersCount=0;
}
OnChangeFilterValues(){

       this.filtersCount=0;
       if( this.typeFilter!='All')
       {
         this.filtersCount=this.filtersCount+1;
       }
       if(this.type=='Active' || this.type=='InActive')
       {
         this.filtersCount=this.filtersCount+1;
       }
       if(this.categoryFilter!='' )
       {
         this.filtersCount=this.filtersCount+1;
       }
       if(this.subCategoryFilter!='')
       {
         this.filtersCount=this.filtersCount+1;
       }
       if(this.selectedStartDate!=undefined  && this.selectedStartDate!=''
         && this.selectedEndDate!=undefined  && this.selectedEndDate!='')
       {
         this.filtersCount=this.filtersCount+1;
       }
     }


}
