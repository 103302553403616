import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/Services/auth-service';
import { StorageService } from 'src/app/Services/storage-service.service';
import { LoginRespopnseModel } from '../login/LoginRespopnseModel';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  loggedInUser:LoginRespopnseModel;

  isReadItemAccess:boolean

  constructor(private authService:AuthenticationService, private _storageService:StorageService) {}

  ngOnInit() {
    this.loggedInUser = this._storageService.GetLoggedInUser();
    const userAccessPermission=this._storageService.GetLoginUserPermissions()

    if (userAccessPermission.readItem) {
       this.isReadItemAccess=userAccessPermission.readItem;
    }

  }
  logout(){
    this.authService.logout();
  }
}
