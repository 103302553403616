<mat-progress-bar [ngStyle]="{'visibility':isLoading==true ? 'visible' : 'hidden'}" color="primary" mode="indeterminate"></mat-progress-bar>
<div class="col-lg-12 components-block">
  <div class="components-header">
    <h2>Activity Report</h2>
    <div class="pulladdata-block" >
      <button type="button" (click)="downloadExcel()" style="margin-top: 1px;"
      mat-stroked-button color="primary"  class="pulldata-btn download-btn">Download Excel</button>
    </div>
  </div>
  <div class="filterbar-block">
    
    <div class="grid-filter-dropdown" >
      <mat-form-field appearance="standard">
        <mat-label>Search User</mat-label>
        <input id="search-box #searchBox"  matInput placeholder="Search" name="searchUser" ControlName="searchUserControl" value="{{searcheduser.firstName}} {{searcheduser.lastName}}"
          aria-label="State" [matAutocomplete]="auto" (keyup)="SearchUsers($event)" [(ngModel)]="selectedUser.id">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn(Users)" (optionSelected)="selectUser($event.option.value,Users)">
          <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
          <ng-container *ngIf="!isLoading">
            <mat-option *ngFor="let user of Users"  [value]="user.id">
              <span>{{user.firstName}} {{user.lastName}} <b *ngIf="user.employeeId">({{user.employeeId}})</b></span>
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div class="grid-filter-dropdown allFilter">
      <mat-form-field appearance="standard">
        <mat-label>Filter</mat-label>
        <input  matInput  (keyup)="applyFilters()" [(ngModel)]="filterValues" placeholder="Item, Action By, Qty" #input>
        <div class="filtericon">
          <mat-icon aria-hidden="false" aria-label="Example home icon">search</mat-icon>
        </div>
      </mat-form-field>
    </div>

    <div class="filtersdata-block">
      <button *ngIf="filtersCount" type="button" (click)="clearall()" mat-stroked-button color="primary" class="clear-btn">
        Clear All
      </button>
      <button type="button" (click)="openFiltersSidePanel()"
      mat-stroked-button color="primary"  class="more-filters">
        More Filters
        <span *ngIf="filtersCount!= 0">{{filtersCount}} Selected</span>
    </button>

    </div>
  </div>

<!-- Material Table  -->

  <div class="mat-table-block">
    <table mat-table matSort (matSortChange)="SortChange($event)" [dataSource]="reportData  ">

      <ng-container matColumnDef="IssuedByUserFirstName">
        <th mat-header-cell  *matHeaderCellDef style="width:15%;" mat-sort-header sortActionDescription="IssuedByUserFirstName"> Action By </th>
        <td mat-cell *matCellDef="let item  " style="width:15%;"> {{item.issuedByUserFirstName  }} {{item.issuedByUserLastName}} </td>
      </ng-container>

      <ng-container matColumnDef="UserFirstName">
        <th mat-header-cell  *matHeaderCellDef style="width:10%;" mat-sort-header sortActionDescription="UserFirstName"> User </th>
        <td mat-cell *matCellDef="let item" style="width:10%;"> {{item.userFirstName}} {{item.userLastName}}</td>
      </ng-container>

      <ng-container matColumnDef="ActionName">
        <th mat-header-cell  *matHeaderCellDef style="width:10%; text-align: center;" mat-sort-header sortActionDescription="ActionName"><span>Action</span>  </th>
        <td mat-cell *matCellDef="let item"  style="width:10%; text-align: center;">
          <!-- <span *ngIf="allCheckinCheckout">{{item.actionName == 'CheckOut'  ?  "Check-out":"Check-in"}}</span> -->
          <span *ngIf="allCheckinCheckout">{{item.actionName }}</span>
          <span *ngIf='isCheckout'>Check-out</span>
          <span *ngIf='isCheckin'>Check-in</span>

        </td>
      </ng-container>

      <ng-container matColumnDef="ItemName">
        <th mat-header-cell  *matHeaderCellDef style="width:12%;" mat-sort-header sortActionDescription="ItemName">Item Name </th>
        <td mat-cell *matCellDef="let item" style="width:12%;">
          <a href="/home/items?itemId={{item.itemId}}" target="_blank" class="column-name-link">
                 {{item.itemName}}
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="ItemCategory">
        <th mat-header-cell  *matHeaderCellDef style="width:10%;" mat-sort-header sortActionDescription="ItemCategory">Category </th>
        <td mat-cell *matCellDef="let item" style="width:10%;">
            {{item.itemCategory }}
        </td>
      </ng-container>

      <ng-container matColumnDef="ItemSubCategory">
        <th mat-header-cell  *matHeaderCellDef style="width:15%;" mat-sort-header sortActionDescription="ItemSubCategory">Sub-category </th>
        <td mat-cell *matCellDef="let item" style="width:15%;">
          {{item.itemSubCategory   }}
        </td>
      </ng-container>

      <ng-container matColumnDef="ActionDate">
        <th mat-header-cell *matHeaderCellDef style="width:10%; text-align: left;" mat-sort-header sortActionDescription="ActionDate"> Action Date </th>
        <td mat-cell *matCellDef="let item" style="width:10%; text-align: left;"> {{item.actionDate | date:'dd/MM/YYY'}} </td>
      </ng-container>

      <ng-container matColumnDef="QtyChanged" >
        <th mat-header-cell  *matHeaderCellDef   style="width:6%; text-align: center;"  mat-sort-header sortActionDescription="QtyChanged"><span>Quantity</span>  </th>
        <td mat-cell *matCellDef="let item" style="width:6%; text-align: center;"> {{item.qtyChanged}} </td>
      </ng-container>

      <ng-container matColumnDef="IssuedLocation" style="width:35%;">
        <th mat-header-cell *matHeaderCellDef style="width:35%;"  mat-sort-header sortActionDescription="IssuedLocation"> Location </th>
        <td mat-cell *matCellDef="let item"> {{item.issuedLocation}} </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div class="record-count" *ngIf="!recordCount && searchStart ">No Record Found</div>

    <mat-paginator *ngIf="recordCount" [pageSizeOptions]="[25, 50, 100]" #paginator [pageIndex]="currentPage" [pageSize]="pageSize"
      (page)="pageChanged($event)" showFirstLastButtons aria-label="Select page of items">
    </mat-paginator>
  </div>
</div>
<div class="components-details-section filters-components" [hidden]="!isSidebarRight">
  <div class="mat-overlay"></div>
  <div class="components-details-area">
    <div class="details-header">
      <h2>Filters</h2>
      <span class="close-icon">
        <mat-icon (click)="close()">clear</mat-icon>
      </span>
    </div>
    <div class="details-content">


      <div class="grid-filter-dropdown">
        <mat-form-field appearance="standard">
          <mat-label>Category</mat-label>
          <mat-select name="Category" #Category="ngModel" [(ngModel)]="categoryFilter"
                      (selectionChange)="updateSubCategories($event)" (selectionChange)="OnChangeFilterValues()">
                      <mat-option value="">All</mat-option>
                      <mat-option *ngFor="let category of Categories" [value]="category.id">
                        {{category.name}}
                      </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="grid-filter-dropdown">
        <mat-form-field appearance="standard">
          <mat-label>Sub-category</mat-label>
          <mat-select name="SubCategory"  [(ngModel)]="subCategoryFilter" (selectionChange)="OnChangeFilterValues()">
            <mat-option  value="">All</mat-option>
            <mat-option *ngFor="let subCategory of SubCategories" [value]="subCategory.id">
              {{subCategory.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div  class="grid-filter-dropdown">
        <mat-form-field appearance="standard">
          <mat-label>Action</mat-label>
          <mat-select  name="typeFilter" [(ngModel)]="typeFilter" (selectionChange)="OnChangeFilterValues()">
            <mat-option value="All">
              All
            </mat-option>
            <mat-option value="CheckOut">
              Check-out
            </mat-option>
            <mat-option value="CheckIn">
              Check-in
            </mat-option>
            <mat-option value="instock"> In Stock </mat-option>
            <mat-option value="loststolen"> Lost/Stolen </mat-option>
            <mat-option value="outforrepair"> Out for Repair </mat-option>
            <mat-option value="brokennotfixable"> Broken – Not Fixable </mat-option>

          </mat-select>
        </mat-form-field>
      </div>

      <div   class="grid-filter-dropdown">
        <mat-form-field appearance="standard">
          <mat-label>Type</mat-label>
          <mat-select  name="type" [(ngModel)]="type" (selectionChange)="OnChangeFilterValues()">
            <mat-option value="All">
              All
            </mat-option>
            <mat-option value="Active">
              Active
            </mat-option>
            <mat-option value="InActive">
             In-Active
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <mat-form-field appearance="standard">
        <mat-label>Enter a date range</mat-label>
        <button type="button" (click)="clearDateRange()" class="rangepickerclose"><mat-icon>close</mat-icon></button>

        <mat-date-range-input   [rangePicker]="rangePicker">
          <input [(ngModel)]="selectedStartDate" matStartDate placeholder="Start date" (dateChange)="OnChangeFilterValues()">
          <input [(ngModel)]="selectedEndDate" matEndDate placeholder="End date" (dateChange)="OnChangeFilterValues()">

        </mat-date-range-input>

        <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
        <mat-date-range-picker #rangePicker>
          <mat-date-range-picker-actions >
            <button mat-button matDateRangePickerCancel>Cancel</button>
            <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
          </mat-date-range-picker-actions>
        </mat-date-range-picker>
      </mat-form-field>


      <div class="pulladdata-block" >
        <button type="button" (click)="clearall()" style="margin-top: 1px;margin-bottom: 15px;"
      mat-stroked-button color="primary"  class="clear-filter-btn">Clear</button>

        <button type="submit" (click)="applyFilters()" style="margin-top: 0px; margin-bottom: 0px; margin-right: 0px;"
          mat-raised-button color="primary" mat-button class="pulldata-btn">Apply Filters</button>
      </div>
      </div>
  </div>
</div>
